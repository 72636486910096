import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, ModalBody, ModalFooter } from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useLoading, Oval } from '@agney/react-loading';
import Select from '../../../Global/SelectRelease';

// import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import { apllyToast } from '../../../Global/Toast2.0';
import { api } from '../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleSubmitPermission: (data: string) => void;
}

interface Options {
  label: string;
  value: string;
}

export function ModalEditReportBI({
  onClose,
  isOpen,
  handleSubmitPermission,
}: Props) {
  const formRef = useRef<FormHandles>(null);

  const [reasonItem, setReason] = useState('');
  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const [dataPermission, setDataPermission] = useState<Options[]>([]);

  useEffect(() => {
    try {
      api.get('/permissions').then(response => {
        const formatData: { value: string; label: string }[] = [];

        response.data.forEach((data: { id: any; identification: any }) => {
          const config = {
            value: data.id,
            label: data.identification,
          };

          formatData.push(config);
        });

        setDataPermission(formatData);
      });
    } catch (e) {
      console.log('error', e);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    try {
      setLoading(true);
      // formRef.current?.setErrors({});

      // const schema = Yup.object().shape({
      //   name: Yup.string().required('Nome é obrigatório'),
      //   description: Yup.string().required('Descrição é obrigatório'),
      //   identification: Yup.string().required('Identificação é obrigatório'),
      // });

      // await schema.validate(data, {
      //   abortEarly: false,
      // });

      handleSubmitPermission(reasonItem);

      onClose();
      setLoading(false);
    } catch (err) {
      apllyToast('error', 'Problemas ao adicionar nova permissão');
      setLoading(false);
      onClose();
      // if (err instanceof Yup.ValidationError) {
      //   const errors = getValidationErrors(err);

      //   formRef.current?.setErrors(errors);
      // }
    }
  }, [handleSubmitPermission, onClose, reasonItem]);

  const { containerProps, indicatorEl } = useLoading({
    loading,
    indicator: <Oval />,
  });

  const handleReason = useCallback(e => {
    setReason(e);
  }, []);

  return (
    <>
      <ModalComponent
        title="Adicionar permissão"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            {/* <p>Nome</p>
            <Input type="text" name="name" />
            <p>Identificação</p>
            <Input type="text" name="description" />
            <p>Descrição</p>
            <Input type="text" name="identification" /> */}

            <Select
              name="reason"
              label="Configurações de BI"
              value={reasonItem}
              onChange={e => {
                handleReason(e.target.value);
              }}
              options={dataPermission}
            />

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                {loading ? (
                  <div {...containerProps} ref={componentRef}>
                    {indicatorEl}
                  </div>
                ) : (
                  'Salvar'
                )}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

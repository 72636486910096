/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-duplicates */
import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  Button,
  Tooltip,
  Tag,
  Collapse,
  CheckboxGroup,
} from '@chakra-ui/react';

import { format } from 'date-fns';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { useState, useCallback } from 'react';
import {
  FiEdit2,
  FiTrash2,
  FiPlus,
  FiFilter,
  FiChevronsUp,
  FiCheck,
  FiPause,
  FiGitPullRequest,
} from 'react-icons/fi';
import { IoArrowDown } from 'react-icons/io5';
import { Pagination } from '../../../../../components/_components_0.2/Pagination';
import { FilterActions } from './Filter';
import { useActions } from '../../../../../hooks/useActions';
import ModalAddAction from '../../../../../components/_components_0.2/Modal/ModalAddAction';
import ModalEditAction from '../../../../../components/_components_0.2/Modal/ModalEditAction';
import { api } from '../../../../../services/api';
import { apllyToast } from '../../../../../components/Global/Toast2.0';

interface ParmsRouter {
  strategicPlansId: string;
}

interface Options {
  value: string;
  label: string;
}

interface updatingLane {
  status: boolean;
  lane: string;
}

export function MemberTable(props: TableProps) {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { params } = useRouteMatch<ParmsRouter>();

  const [responsableData, setResponsableData] = useState<Options[]>([]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [indicator_id, setIndicator_id] = useState('');
  const [responsible_id, setResponsible_id] = useState('');

  const [deletedsActions, setDeletedsActions] = useState(true);
  const [isLoadingDeleteting, setIsLoadingDeleteting] = useState(false);
  const [isLoadingRecover, setIsLoadingRecover] = useState(false);

  const [isLoadingUpdateLane, setIsLoadingUpdateLane] = useState<updatingLane>({
    status: false,
    lane: '',
  });
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [isOpenAlert, setIsOpenAlert] = useState(false);

  const [lane, setLane] = useState('');

  const [indicatorData, setIndicatorData] = useState<Options[]>([]);

  const [actionSelected, setActionSelected] = useState<string[]>([]);

  const { data, isLoading, error, isFetching, refetch } = useActions({
    page,
    setResponsableData,
    indicator_id,
    responsible_id,
    lane,
    deletedsActions,
  });

  const handleFormatDate = useCallback((date: string) => {
    const dataFormadet = format(new Date(date), 'dd/MM/yyyy');
    return dataFormadet;
  }, []);

  const openAction = useCallback(
    (id_action: string) => {
      api.get(`/indicators-actions/show?id=${id_action}`).then(response => {
        const action = {
          id: response.data.id,
          sub_indicator_id: response.data.sub_indicator_id,
          title: response.data.title,
          description: response.data.description,
          label: response.data.label,
          lane: response.data.lane,
          contents: response.data.contents,
          start_date: new Date(response.data.start_date)
            .toISOString()
            .slice(0, 10),
          end_date: new Date(response.data.end_date).toISOString().slice(0, 10),
          status: response.data.status,
          responsible: response.data.responsible,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        };

        history.push(`/indicators/${params.strategicPlansId}/${id_action}`, {
          action,
        });
        setOpenModalEdit(true);
      });
    },
    [history, params.strategicPlansId],
  );

  const handleDeleteAction = useCallback(
    async (id: string) => {
      try {
        setIsLoadingDeleteting(true);
        const formatedData = {
          id,
          status: false,
        };
        await api.put('/indicators-actions', formatedData);
        apllyToast('info', 'Ação exluida!');
        setIsLoadingDeleteting(false);
        setActionSelected([]);
        refetch();
      } catch (err) {
        console.log('erro', err);
        setIsLoadingDeleteting(false);
      }
    },
    [refetch],
  );

  const handleRecoverAction = useCallback(
    async (id: string) => {
      try {
        const formatedData = {
          id,
          status: true,
        };
        setIsLoadingRecover(true);
        await api.put('/indicators-actions', formatedData);
        apllyToast('info', 'Ação recuperada!');
        setIsLoadingRecover(false);
        refetch();
      } catch (err) {
        setIsLoadingRecover(false);
        console.log('erro', err);
      }
    },
    [refetch],
  );
  // const handleOpenAlert = useCallback((id: string) => {

  // }, [])

  const handleSelectedAction = useCallback(
    (id: string) => {
      const alreadySelected = actionSelected.findIndex(item => item === id);
      if (alreadySelected >= 0) {
        const filteredItems = actionSelected.filter(item => item !== id);

        setActionSelected(filteredItems);
      } else {
        setActionSelected([...actionSelected, id]);
      }
    },
    [actionSelected],
  );

  // const handleSelectedActionAll = useCallback(() => {
  //   if (actionSelected.length === 0 && data !== undefined) {
  //     const filteredItems = data.indicatorsActions.map(action => action.id);
  //     console.log('testesteste', filteredItems);
  //     setActionSelected(filteredItems);
  //   } else {
  //     setActionSelected([]);
  //   }
  // }, [actionSelected, data]);

  const handleUpdateLaneActions = useCallback(
    async (laneAction: string) => {
      try {
        if (actionSelected.length > 0) {
          setIsLoadingUpdateLane({ status: true, lane: laneAction });
          const formatedData = {
            ids: actionSelected,
            lane: laneAction,
          };
          await api.put('/indicators-actions/lane', formatedData);
          refetch();
          apllyToast('success', 'Ação atualizada com sucesso!');
          setIsLoadingUpdateLane({ status: false, lane: '' });
        }
      } catch (err) {
        apllyToast('error', 'Problemas ao atualizar ação!');
        setIsLoadingUpdateLane({ status: false, lane: '' });
      }
    },
    [actionSelected, refetch],
  );

  return (
    <>
      <ModalAddAction
        isOpen={openModal}
        onClose={setOpenModal}
        refetch={refetch}
      />
      <ModalEditAction
        isOpen={openModalEdit}
        onClose={setOpenModalEdit}
        refetch={refetch}
      />
      <Box p={['2', '4']} borderRadius={8} bg="gray.800" mt="6">
        {isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : error ? (
          <Flex justify="center">
            <Text>Falha ao obter dados das ações.</Text>
          </Flex>
        ) : (
          <>
            <Box px={{ base: '4', md: '6' }} pt="5">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
              >
                <Flex alignItems="center">
                  <Text fontSize="lg" fontWeight="medium">
                    Ações
                  </Text>
                  {!isLoading && isFetching && (
                    <Flex ml="2">
                      <Spinner color="gray.500" size="xs" />
                    </Flex>
                  )}
                </Flex>
                <Collapse
                  in={actionSelected.length > 0 && deletedsActions}
                  animateOpacity
                >
                  <Stack spacing={3} direction="row" align="center">
                    <Button
                      leftIcon={<FiCheck />}
                      colorScheme="green"
                      onClick={() => handleUpdateLaneActions('lane3')}
                      isLoading={
                        isLoadingUpdateLane &&
                        isLoadingUpdateLane.lane === 'lane3'
                      }
                    >
                      Finalizar
                    </Button>
                    <Button
                      leftIcon={<FiPause />}
                      colorScheme="blue"
                      onClick={() => handleUpdateLaneActions('lane4')}
                      isLoading={
                        isLoadingUpdateLane &&
                        isLoadingUpdateLane.lane === 'lane4'
                      }
                    >
                      Pausar
                    </Button>
                    <Button
                      leftIcon={<FiGitPullRequest />}
                      colorScheme="orange"
                      onClick={() => handleUpdateLaneActions('lane2')}
                      isLoading={
                        isLoadingUpdateLane &&
                        isLoadingUpdateLane.lane === 'lane2'
                      }
                    >
                      Andamento
                    </Button>
                  </Stack>
                </Collapse>
                <Box display="flex" alignItems="center" justifyContent="row">
                  {/* <InputGroup maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input placeholder="Search" />
                  </InputGroup> */}
                  <Tooltip label="Aplicar filtros a esta tabela">
                    <IconButton
                      ml="5"
                      icon={<FiFilter fontSize="1.25rem" />}
                      aria-label="Filter actions"
                      onClick={() => setIsOpenFilter(!isOpenFilter)}
                    />
                  </Tooltip>
                  <Tooltip label="Adicionar nova ação">
                    <Button
                      leftIcon={<FiPlus />}
                      onClick={() => setOpenModal(true)}
                      ml="2"
                    >
                      Ação
                    </Button>
                  </Tooltip>
                </Box>
              </Stack>
            </Box>

            <FilterActions
              isOpenFilter={isOpenFilter}
              setIndicator_id={setIndicator_id}
              indicator_id={indicator_id}
              setIndicatorData={setIndicatorData}
              indicatorData={indicatorData}
              responsible_id={responsible_id}
              responsableData={responsableData}
              setResponsible_id={setResponsible_id}
              setLane={setLane}
              lane={lane}
              setDeletedsActions={setDeletedsActions}
              deletedsActions={deletedsActions}
            />

            <Box overflowX="auto">
              <Table {...props}>
                <Thead>
                  <Tr>
                    <Th>
                      <HStack spacing="3">
                        {/* <Checkbox onChange={handleSelectedActionAll} /> */}

                        <HStack spacing="1">
                          <Text>Responsável</Text>
                          <Icon as={IoArrowDown} color="muted" boxSize="4" />
                        </HStack>
                      </HStack>
                    </Th>
                    <Th>Status</Th>
                    <Th>Ação</Th>
                    <Th>Indicador</Th>
                    <Th>Entrega</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.indicatorsActions.map(action => (
                    <>
                      {/* <Alert
                        id={action.id}
                        ButonClose="Não"
                        ButonSave="Sim"
                        title="Deletar ação"
                        textBody="Deseja realmente excluir esta ação"
                        handleFunction={() => handleDeleteAction(action.id)}
                        isOpen={isOpen}
                        onClose={onClose}
                      /> */}

                      <Tr key={action.id}>
                        <Td>
                          <HStack spacing="3">
                            <CheckboxGroup defaultValue={actionSelected}>
                              <Checkbox
                                defaultChecked={actionSelected.includes(
                                  action.id,
                                )}
                                value={action.id}
                                onChange={() => handleSelectedAction(action.id)}
                              />
                            </CheckboxGroup>
                            <Avatar
                              name={action.responsible.name}
                              // src={member.avatarUrl}
                              boxSize="10"
                            />
                            {action.status === false && (
                              <Tag colorScheme="purple" size="sm" mt="20">
                                deleted
                              </Tag>
                            )}
                            <Box maxW="6.5rem">
                              <Tooltip label={action.responsible.name}>
                                <Text
                                  fontWeight="medium"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                >
                                  {action.responsible.name}
                                </Text>
                              </Tooltip>
                            </Box>
                          </HStack>
                        </Td>
                        <Td>
                          <Badge
                            size="sm"
                            colorScheme={
                              action.titleStatus === 'Pendente'
                                ? 'red'
                                : action.titleStatus === 'Concluída'
                                ? 'green'
                                : action.titleStatus === 'Andamento'
                                ? 'yellow'
                                : 'blue'
                            }
                          >
                            {action.titleStatus}
                          </Badge>
                        </Td>
                        <Td>
                          <Text color="muted">{action.title}</Text>
                        </Td>
                        <Td>
                          <Box maxW="6.5rem">
                            <Tooltip label={action.indicator.name}>
                              <Text
                                color="muted"
                                fontWeight="medium"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {action.indicator.name}
                              </Text>
                            </Tooltip>
                          </Box>
                        </Td>
                        <Td>
                          <Text color="muted">
                            {handleFormatDate(action.end_date)}
                          </Text>
                        </Td>
                        <Td>
                          <HStack spacing="1">
                            {action.status ? (
                              <Tooltip label="Exluir ação">
                                <IconButton
                                  onClick={() => handleDeleteAction(action.id)}
                                  isDisabled={isLoadingDeleteting || isFetching}
                                  icon={<FiTrash2 fontSize="1.25rem" />}
                                  variant="ghost"
                                  aria-label="Delete member"
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip label="Recuperar ação">
                                <IconButton
                                  onClick={() => handleRecoverAction(action.id)}
                                  isDisabled={isLoadingRecover || isFetching}
                                  icon={<FiChevronsUp fontSize="1.25rem" />}
                                  variant="ghost"
                                  aria-label="Delete member"
                                />
                              </Tooltip>
                            )}
                            <Tooltip label="Editar ação">
                              <IconButton
                                onClick={() => openAction(action.id)}
                                icon={<FiEdit2 fontSize="1.25rem" />}
                                variant="ghost"
                                aria-label="Edit member"
                              />
                            </Tooltip>
                          </HStack>
                        </Td>
                      </Tr>
                    </>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Pagination
              totalCountOfRegisters={data?.pagination.total || 0}
              currentPage={page}
              onPageChange={setPage}
              registersPerPage={data?.pagination.take || 0}
            />
          </>
        )}
      </Box>
    </>
  );
}

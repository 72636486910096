import { Tag, Flex, Button, Text, Tooltip } from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';

interface IOptions {
  handleDeletePermission: (permission: string) => void;

  value: string;
  label: string;
}

export function ListPermissions({
  value,
  label,
  handleDeletePermission,
}: IOptions) {
  return (
    <Tag colorScheme="orange" size="md" mr="1" mb="3">
      <Flex p="1" alignItems="center">
        <Text>{label}</Text>
        <Tooltip label="Excluir função">
          <Button
            colorScheme="orange"
            size="xs"
            onClick={() => handleDeletePermission(value)}
            ml="2"
          >
            <FiX />
          </Button>
        </Tooltip>
      </Flex>
    </Tag>
  );
}

import { Button, Center, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';

import ModalCreateStrategicPlanning from '../../../components/_components_0.2/Modal/ModalCreateStrategicPlanning';

import { ListPlannings } from './ListPlannings';

export function StrategicPlanning() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [proccessAdded, setProcessAdded] = useState('');

  return (
    <>
      <ModalCreateStrategicPlanning
        isOpen={isOpen}
        onClose={onClose}
        setProcessAdded={setProcessAdded}
      />
      <Center flexDirection="column">
        <Flex
          w="90%"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          mt="24px"
        >
          <Text>Planejameneto estratégico</Text>
          <Button onClick={onOpen}>Adicionar novo planejamento</Button>
        </Flex>
        <Flex alignItems="center" flexDirection="row" mt="25px">
          <ListPlannings
            present={false}
            year={2022}
            proccessAdded={proccessAdded}
          />

          <ListPlannings present year={2023} proccessAdded={proccessAdded} />

          <ListPlannings
            present={false}
            year={2024}
            proccessAdded={proccessAdded}
          />
        </Flex>
      </Center>
    </>
  );
}

import { FiList, FiFileText } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { OptionList } from './styles';

interface Props {
  pathname: string;
}

export function Rules({ pathname }: Props) {
  return (
    <>
      <OptionList pathname={pathname} path="/menu" className="nav-item" visible>
        <Link to="/menu" className="nav-link">
          <FiList color="#3182ce" size={16} />

          <span className="link-text">Menu inicial</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/rules"
        className="nav-item"
        visible
      >
        <Link to="/rules" className="nav-link">
          <FiFileText color="#3182ce" size={16} />

          <span className="link-text">Normas interna</span>
        </Link>
      </OptionList>
    </>
  );
}

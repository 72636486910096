import { useState, useCallback, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  ScaleFade,
  Button,
  Avatar,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import {
  FiUser,
  FiLock,
  FiMail,
  FiPhone,
  FiImage,
  FiPlusSquare,
} from 'react-icons/fi';
import { SiMicrosoftoutlook } from 'react-icons/si';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import Input from '../../../components/Global/Input';
import { HeaderUp } from '../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../components/_components_0.2/Sidebar_0.2';
import { apllyToast } from '../../../components/Global/Toast2.0';
import getValidationErrors from '../../../utils/getValidationErrors';

import { ModalVisualizeEmailSignature } from '../../../components/_components_0.2/Modal/ModalVisualizeEmailSignature';

import { api } from '../../../services/api';
import { useCanFlags } from '../../../hooks/useCanFlags';

interface IUser {
  name: string;
  access_of_sector: {
    branch_of_sector: {
      short_address: string;
    };
  };
  job_position: string;
  filName: string;
  ramal: number;
  phone: string;
}

export default function Profile() {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { user } = useAuth();

  const [dataUser, setDataUser] = useState<IUser>();
  const [nameUser, setNameUser] = useState('');
  const [userDataUpdate, setUserDataUpdate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [address, setAddres] = useState('');

  const [data_generalInput, setData_generalInput] = useState('');

  const userEditName = useCanFlags({
    flag: 'flag_1',
    user_id: user.id,
  });

  useEffect(() => {
    if (!user.senior_id) {
      setUserDataUpdate(true);
    }
  }, [user]);

  useEffect(() => {
    try {
      api
        .get('accesses/show', {
          params: {
            access_id: user.id,
          },
        })
        .then(response => {
          api
            .get('/branches/show', {
              params: {
                id: 'ea75dc18-81dd-4ca1-9e79-b8a6c22bccd6',
              },
            })
            .then(res => {
              setAddres(res.data.short_address);
            });
          console.log('userEditName', response.data.access);
          setDataUser(response.data.access);
          setNameUser(response.data.access.name);
        });
    } catch (err) {
      console.log('error', err);
    }
  }, [user]);

  const handleSetPassword = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string()
            .required('Campo obrigatório')
            .min(6, 'Minimo 6 digitos'),

          password_confirmation: Yup.string()
            .when('password', {
              is: (val: string | any[]) => !!val.length,
              then: Yup.string().required('Campo obrigatório'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), undefined], 'Confirmação incorreta'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api
          .post('/password/reset', {
            password: data.password,
            password_confirmation: data.password_confirmation,
            username: user.nickname,
          })
          .then(() => {
            history.push('/menu');
            apllyToast(
              'success',
              'Operação realizada com sucesso',
              'top-right',
            );
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        apllyToast(
          'error',
          'Ocorreu um erro ao realizar operação, cheque as credenciais.',
          'top-right',
        );
      }
    },
    [history, user.nickname],
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório!'),
          // ramal: Yup.string().required('Campo obrigatório'),
          // image: Yup.string().required('Campo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setData_generalInput(data.data_general);
        setNameUser(data.name);

        api
          .put('/accesses', {
            senior_id: data.senior_id,
            ramal: data.ramal,
            phone: data.phone,
            image: data.image,
            nickname: user.nickname,
            // name: data.name,
          })
          .then(response => {
            setDataUser(response.data.access);
            setUserDataUpdate(false);
            // history.push('/menu');
            // window.location.reload();
            apllyToast(
              'success',
              'Operação realizada com sucesso',
              'top-right',
            );
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        apllyToast(
          'error',
          'Ocorreu um erro ao realizar operação, cheque as credenciais.',
          'top-right',
        );
      }
    },
    [user.nickname],
  );

  function handleCloseImageEmail() {
    setIsOpen(!isOpen);
  }
  console.log('userEditName', userEditName);
  return (
    <ScaleFade initialScale={0.9} in>
      <ModalVisualizeEmailSignature
        handleCloseImageEmail={() => handleCloseImageEmail()}
        filName={dataUser ? address : ''}
        phone={dataUser ? dataUser.phone : ''}
        name={nameUser || ''}
        ramal={dataUser ? dataUser.ramal : 0}
        isOpen={isOpen}
        dataComplementary={data_generalInput}
        permissionNameEdit={userEditName}
      />
      <Flex direction="column" h="100vh" mb="30px">
        <HeaderUp path="profile" />
        <Flex my="6" mx="auto" pb={4} px="6" textAlign="center">
          <Sidebar path="profile" />
          <Flex align="center" flexDirection="column">
            <Box margin="25">
              <Box
                borderRadius="100%"
                border="2px"
                borderColor="#04D361"
                p="2px"
              >
                <Avatar size="xl" name={user.name} src={user.image} />
              </Box>
            </Box>

            <Form ref={formRef} onSubmit={handleSubmit} initialData={dataUser}>
              <Flex textAlign="initial" flexDirection="column" w="50rem">
                <Text
                  ml="-10"
                  mb="1rem"
                  color="gray.650"
                  borderBottomColor="gray.650"
                  borderBottomWidth="0.5px"
                >
                  Informações do usuário
                </Text>
                <Text>Nome</Text>
                <Input icon={FiUser} isDisabled={!userEditName} name="name" />
                <Text>Email</Text>
                <Input icon={FiMail} isDisabled name="email" />

                <Text>URL da imagem</Text>
                <Input icon={FiImage} isDisabled name="image" />

                <Tooltip
                  label="Necessário informar código da senior!"
                  placement="left"
                  isOpen
                  bg="red"
                  hasArrow
                  display={userDataUpdate ? '' : 'none'}
                >
                  <Text color={userDataUpdate ? 'red' : ''}>
                    Código do Sênior
                  </Text>
                </Tooltip>
                <Input
                  isInvalid={userDataUpdate}
                  icon={FiUser}
                  name="senior_id"
                />

                <Text>Telefone</Text>
                <Input
                  icon={FiPhone}
                  name="phone"
                  type="string"
                  data-mask="(00) 0000-0000"
                  data-mask-selectonfocus="true"
                />
                <Text>Ramal</Text>
                <Input icon={FiPhone} name="ramal" />

                <Text>Informações complementares para assinatura de email</Text>
                <Input icon={FiPlusSquare} name="data_general" />
              </Flex>

              <Button type="submit" mt="5" colorScheme="blue">
                Aplicar mudanças
              </Button>
            </Form>

            <Flex textAlign="initial" flexDirection="column" w="50rem" mt="10">
              <Text
                ml="-10"
                mb="1rem"
                color="gray.650"
                borderBottomColor="gray.650"
                borderBottomWidth="0.5px"
              >
                Ferramentas
              </Text>
              <Box>
                {/* <Button
                  leftIcon={<AiOutlineWhatsApp />}
                  type="submit"
                  size="sm"
                  colorScheme="whatsapp"
                  ml="2"
                >
                  Gerar imagem do Whatssap
                </Button>
                <Button
                  leftIcon={<SiLinkedin />}
                  type="submit"
                  size="sm"
                  colorScheme="linkedin"
                  ml="2"
                >
                  Gerar Imagem do linkedin
                </Button> */}
                <Button
                  leftIcon={<SiMicrosoftoutlook />}
                  type="button"
                  size="sm"
                  colorScheme="blue"
                  ml="2"
                  onClick={() => handleCloseImageEmail()}
                >
                  Gerar assinatura de email
                </Button>
              </Box>
            </Flex>

            <Form ref={formRef} onSubmit={handleSetPassword}>
              <Flex
                textAlign="initial"
                flexDirection="column"
                w="50rem"
                mt="10"
              >
                <Text
                  ml="-10"
                  mb="1rem"
                  color="gray.650"
                  borderBottomColor="gray.650"
                  borderBottomWidth="0.5px"
                >
                  Senha
                </Text>
                <Text>Nova senha</Text>
                <Input
                  icon={FiLock}
                  name="password"
                  type="password"
                  // placeholder="Nova senha"
                />
                <Text>Confirmar nova senha</Text>
                <Input
                  icon={FiLock}
                  name="password_confirmation"
                  type="password"
                  // placeholder="Confirmar nova senha"
                />
              </Flex>
              <Button type="submit" mt="5" colorScheme="red">
                Alterar senha
              </Button>
            </Form>
          </Flex>
        </Flex>
      </Flex>
    </ScaleFade>
  );
}

/* eslint-disable react-hooks/rules-of-hooks */
import {
  Stack,
  ScaleFade,
  Spinner,
  Center,
  Menu,
  MenuList,
  MenuButton as MenuBChakra,
  MenuItem as MenuChakra,
  Button,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { RiMenu2Fill } from 'react-icons/ri';
import { BiChevronDown } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { api } from '../../../../../services/api';
import { Can } from '../../../../Admin/Authentication/Can';
import { useCan } from '../../../../../hooks/useCan';
import { useAuth } from '../../../../../hooks/auth';

import { NavLink } from '../../NavLink';
import { NavSection } from '../../NavSection';
import { MenuButton } from './MenuButton';
import { MenuItem } from './MenuItem';

// const opt = [
//   {
//     groupNameSamasc: 'Administrativo',

//     report: [
//       {
//         reportName: 'Energia',
//         permission: ['edit_energy'],
//         roles: [''],
//       },
//       {
//         reportName: 'Ordens de serviço',
//         permission: ['data_process_manager'],
//         roles: [''],
//       },
//       {
//         reportName: 'Controle de frota',
//         permission: [''],
//         roles: [''],
//       },
//     ],
//   },

// {
//   group: 'Comercial',
//   reports: [
//     'Controle de estoque',
//     'Ordens de compra',
//     'Gerenciamento de suprimentos',
//   ],
//   roles: ['adm_supply'],
//   permissions: ['master_user', 'manegament', 'buy'],

//   report: [
//     {
//       name: 'Controle de estoque',
//       permission: 'estoque',
//     },
//     {
//       name: 'Ordens de compra',
//       permission: 'buy',
//     },
//     {
//       name: 'Gerenciamento de suprimentos',
//       permission: 'manegament',
//     },
//   ],
// },
// {
//   group: 'Cereais',
//   reports: ['Genreciamento de grãos', 'Precificação'],
//   roles: ['adm_rh'],
//   permissions: ['master_user', 'manegament', 'buys'],

//   report: [
//     {
//       name: 'Genreciamento de grãos',
//       permission: 'master_user',
//     },
//     {
//       name: 'Precificação',
//       permission: 'master_user',
//     },
//   ],
// },
// ];

interface DataReportsFormated {
  groupNameSamasc: string;
  reports: {
    report: {
      id_power_bi_configs: string;
      reportName: string;
      embededId: string;
      embededUrl: string;
      groupId: string;
    };

    permissions: string[];
    roles?: string[];
  }[];
}

interface DataPermissions {
  reports: {
    permissions: string[];
    roles?: string[];
  }[];
}

export function SidebarNavBi() {
  const [dataOpt, setDataOpt] = useState<DataReportsFormated[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const userCanSeeEditEnergy = useCan({
    permissions: ['edit_energy'],
  });
  const userCanSeeClimateResearch = useCan({
    permissions: ['edit_climate_research'],
  });
  const userCanSeeDataTi = useCan({
    permissions: ['ti_data'],
  });

  useEffect(() => {
    try {
      api.get('/power-bi-reports').then(response => {
        setDataOpt(response.data);
        setLoading(false);
      });
      // api.get<DataReports[]>('/power-bi-reports').then(response => {
      //   const formated: {
      //     group: string;
      //     reports: string[];
      //     permissions: string[];
      //     roles: string[];
      //   }[] = [];

      //   response.data.forEach(el => {
      //     if (formated.filter(e => e.group === el.groupNameSamasc).length > 0) {
      //       formated[
      //         formated.map(elem => elem.group).indexOf(el.groupNameSamasc)
      //       ].reports.push(el.reportName);
      //     } else {
      //       const data = {
      //         group: el.groupNameSamasc,
      //         reports: [el.reportName],
      //         permissions: ['process_manager', 'services', 'miles', 'adm'],
      //         roles: ['process_manager'],
      //       };
      //       formated.push(data);
      //     }
      //   });
      //   console.log(formated);

      // });
    } catch (e) {
      console.log('errt', e);
    }
  }, []);

  const handleVerfyExitPermissions = useCallback(
    (data: DataPermissions) => {
      data.reports.map(e => {
        return e;
      });

      const hasAllPermissions = data.reports.map(e =>
        e.permissions?.every(permission => {
          return user.permissions.includes(permission);
        }),
      );

      if (hasAllPermissions.length > 0) {
        if (hasAllPermissions.includes(true)) {
          return true;
        }
      }
      return false;
    },
    [user],
  );

  return (
    <Stack spacing="8" align="flex-start">
      <NavSection title="GERAL">
        <NavLink to="/menu" icon={RiMenu2Fill}>
          Menu
        </NavLink>
      </NavSection>

      <NavSection title="GRUPOS DE ANÁLISES">
        {/* <NavLink to="/miles/schedule" icon={RiCalendarCheckLine}>
          Administração */}
        {/* <Menu> */}

        {!loading ? (
          <>
            {dataOpt.map(el => (
              <>
                {handleVerfyExitPermissions(el) && (
                  <ScaleFade key={el.groupNameSamasc} initialScale={0.9} in>
                    <MenuButton label={el.groupNameSamasc}>
                      {el.reports.map(report => (
                        <Can
                          key={report.report.reportName}
                          permissions={report?.permissions}
                        >
                          <MenuItem
                            label={report.report.reportName}
                            embededId={report.report.embededId}
                            embededUrl={report.report.embededUrl}
                            workspaceId={report.report.groupId}
                            configId={report.report.id_power_bi_configs}
                          />
                        </Can>
                      ))}
                    </MenuButton>
                  </ScaleFade>
                )}
              </>
            ))}
          </>
        ) : (
          <Center>
            <Spinner size="lg" />
          </Center>
        )}
        {/* </Menu> */}
      </NavSection>
      <NavSection title="GESTÃO DE DADOS">
        {!loading ? (
          <>
            <Menu>
              <MenuBChakra
                as={Button}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="1px"
                bg="gray.900"
                borderColor="gray.800"
                _hover={{ bg: 'gray.650' }}
                _expanded={{ bg: 'blue.400' }}
                _focus={{ boxShadow: 'outline' }}
                rightIcon={<BiChevronDown />}
                fontWeight="500"
                textAlign="initial"
              >
                Resultado financeiro
              </MenuBChakra>
              <MenuList bg="gray.800">
                <MenuChakra
                  _hover={{ bg: 'gray.650' }}
                  as={Link}
                  to="/management-ppr/report-income-statement"
                >
                  Demonstrativo de resultados
                </MenuChakra>
              </MenuList>
            </Menu>
            {userCanSeeDataTi && (
              <Menu>
                <MenuBChakra
                  as={Button}
                  transition="all 0.2s"
                  borderRadius="md"
                  borderWidth="1px"
                  bg="gray.900"
                  borderColor="gray.800"
                  _hover={{ bg: 'gray.650' }}
                  _expanded={{ bg: 'blue.400' }}
                  _focus={{ boxShadow: 'outline' }}
                  rightIcon={<BiChevronDown />}
                  fontWeight="500"
                  textAlign="initial"
                >
                  Map. de Processos
                </MenuBChakra>
                <MenuList bg="gray.800">
                  <MenuChakra
                    _hover={{ bg: 'gray.650' }}
                    as={Link}
                    to="/process-management"
                  >
                    Gerenciar mapeamento
                  </MenuChakra>
                </MenuList>
              </Menu>
            )}
            {userCanSeeEditEnergy ? (
              <Menu>
                <MenuBChakra
                  as={Button}
                  transition="all 0.2s"
                  borderRadius="md"
                  borderWidth="1px"
                  bg="gray.900"
                  borderColor="gray.800"
                  _hover={{ bg: 'gray.650' }}
                  _expanded={{ bg: 'blue.400' }}
                  _focus={{ boxShadow: 'outline' }}
                  rightIcon={<BiChevronDown />}
                  fontWeight="500"
                  textAlign="initial"
                >
                  Rep. Custos Energia
                </MenuBChakra>
                <MenuList bg="gray.800">
                  <MenuChakra
                    _hover={{ bg: 'gray.650' }}
                    as={Link}
                    to="/management-energy/energy-table"
                  >
                    Gerenciar custos de energia
                  </MenuChakra>
                </MenuList>
              </Menu>
            ) : (
              ''
            )}

            {userCanSeeClimateResearch ? (
              <Menu>
                <MenuBChakra
                  as={Button}
                  transition="all 0.2s"
                  borderRadius="md"
                  borderWidth="1px"
                  bg="gray.900"
                  borderColor="gray.800"
                  _hover={{ bg: 'gray.650' }}
                  _expanded={{ bg: 'blue.400' }}
                  _focus={{ boxShadow: 'outline' }}
                  rightIcon={<BiChevronDown />}
                  fontWeight="500"
                  textAlign="initial"
                >
                  Pesquisa de clima
                </MenuBChakra>
                <MenuList bg="gray.800">
                  <MenuChakra
                    _hover={{ bg: 'gray.650' }}
                    as={Link}
                    to="/climate-research-management"
                  >
                    Gerenciar pesquisas
                  </MenuChakra>
                </MenuList>
              </Menu>
            ) : (
              ''
            )}
          </>
        ) : (
          <Center>
            <Spinner size="lg" />
          </Center>
        )}
      </NavSection>
    </Stack>
  );
}

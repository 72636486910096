/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef, useCallback, useState } from 'react';
import { Button, Flex, ScaleFade } from '@chakra-ui/react';
// import { ReportBIManagement } from '../../../../components/Admin/Reports/ReportBIManagement';

import { Configuration, OpenAIApi } from 'openai';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import SunEditor from 'suneditor-react';
// import fs from 'fs-extra';

import TextArea from '../../../components/Global/TextArea';
import { HeaderUp } from '../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../components/_components_0.2/Sidebar_0.2';

const configuration = new Configuration({
  apiKey: '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',
});

const openai = new OpenAIApi(configuration);

export default function ArtificialInteligence() {
  const formRef = useRef<FormHandles>(null);
  const [data, setDatasets] = useState();
  const [file, setFile] = useState<any>();

  const handleSubmit = useCallback(
    // eslint-disable-next-line no-shadow
    async data => {
      const response = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: data.input }],
      });
      // @ts-ignore
      console.log(response.choices);
      console.log(response);
      // @ts-ignore
      setDatasets(response.data.choices[0].message?.content);
    },
    [setDatasets],
  );
  const handlesendImagen = useCallback(
    // eslint-disable-next-line no-shadow
    async () => {
      const response = await openai.createImage({
        prompt: 'World War',
        n: 2,
        size: '1024x1024',
      });
      console.log(response);
      // @ts-ignore
      console.log(response.data[0].url);
      // @ts-ignore
      const img = `<img src="${response.data[0].url}" alt="" /> <img src="${response.data[1].url}" alt="" />`;
      // @ts-ignore
      setDatasets(img);
    },
    [setDatasets],
  );
  async function uploadFile() {
    try {
      console.log('teste');

      // const fileBuffer = await fs.readFile(file.path);
      // const fileName = file.name;
      // const contentType = file.type;

      // Enviar o arquivo para a API de upload
      // @ts-ignore
      const response = await openai.createFile({
        purpose: 'fine-tuning',
        // file: {
        //   content: fileBuffer,
        //   contentType,
        //   name: fileName,
        // },
      });

      // Cria um novo arquivo usando a API da OpenAI
      // @ts-ignore

      console.log(response);
    } catch (err) {
      console.error('Error uploading file:', err);
    }
  }

  const handleFileUpload = (event: {
    target: { files: React.SetStateAction<null>[] };
  }) => {
    setFile(event.target.files[0]);
  };
  console.log('file', file);

  return (
    <ScaleFade initialScale={0.9} in>
      <Flex direction="column" h="100vh">
        <HeaderUp path="bi" />
        <Flex my="6" maxWidth={1480} mx="auto" pb={4} px="6" textAlign="center">
          <Sidebar path="bi" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <p>Entrada</p>
            <TextArea name="input" />

            <p>Saida</p>
            <TextArea value={data} name="observation" />

            <Button colorScheme="blue" type="submit">
              Salvar
            </Button>

            <Flex>
              <SunEditor
                setDefaultStyle="font-family:'Roboto', sans-serif; min-height: 220px"
                setContents={data}
              />
              <Button
                colorScheme="blue"
                type="button"
                onClick={handlesendImagen}
              >
                Gerar imagen
              </Button>
            </Flex>
            <Flex>
              <div>
                <input type="file" onChange={() => handleFileUpload} />
                <Button onClick={() => uploadFile()}>Upload</Button>
              </div>
            </Flex>
          </Form>
        </Flex>
      </Flex>
    </ScaleFade>
  );
}

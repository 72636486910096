/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBR from 'date-fns/locale/pt-BR';

import { FiEdit } from 'react-icons/fi';
import {
  Box,
  Button,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { apllyToast } from '../../../Global/Toast2.0';

import ModalEditVehicle from '../../../../pages/Dashboard/Administration/Vehicles/ModalEditVehicle';
import logoAtual from '../../../../assets/logoatual.png';

// import ModalBoxItemTable from './ModalBoxItemTable';

import { api } from '../../../../services/api';

interface IdataTable {
  newVehicle: string;
}

interface ITable2 {
  id: string;
  name: string;
  plate: string;
  year: string;
  fuel: string;
  km: string;
  image: string;
  document: string;
  observations: string;
  image_url: string;
  document_url: string;
  created_at: string;
}

interface IVehicles {
  id: string;
  name: string;
  plate: string;
  year: string;
  fuel: string;
  km: string;
  observations: string;
}

const TableVehicles: React.FC<IdataTable> = () => {
  const [dataTable, setDataTable] = useState<ITable2[]>([]);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [dataEditVehicle, setDataEditVehicle] = useState('');
  const [newVehicle, setNewVehicle] = useState('');

  useEffect(() => {
    api.get('/vehicles').then(response => {
      setDataTable(response.data);
    });
  }, [newVehicle]);

  const handleEditVehicle = useCallback(
    (vehiclesInfo: Omit<IVehicles, ''>, id: string) => {
      try {
        api.put(`/vehicles?id=${id}`, vehiclesInfo).then(response => {
          setNewVehicle(response.data);
        });
        apllyToast('success', 'Sucesso ao atualizar veículo!');
      } catch (err) {
        apllyToast('warning', 'Problemas ao atualizar veículo!');
      }
    },
    [],
  );

  const handleEdit = useCallback((idVehicle: string) => {
    setModalEditOpen(true);
    setDataEditVehicle(idVehicle);
  }, []);

  const toggleModalEdit = useCallback(() => {
    setModalEditOpen(!modalEditOpen);
  }, [modalEditOpen]);

  return (
    <>
      <Box>
        <Table colorScheme="whiteAlpha" width={100}>
          <Thead>
            <Tr>
              <Th>Veículo</Th>
              <Th>Nome</Th>
              <Th>Placa</Th>
              <Th>Combus.</Th>
              <Th>Ano</Th>
              <Th>Km</Th>
              <Th>Documento</Th>
              <Th>Data de cadastro</Th>
              <Th>Editar</Th>
            </Tr>
          </Thead>

          <Tbody>
            {dataTable?.map(item => (
              <Tr key={item.id}>
                <Td>
                  <Image
                    src={item.image === null ? logoAtual : item.image_url}
                    alt="Imagem do veiculo"
                  />
                </Td>
                <Td>
                  <p>{item.name}</p>
                </Td>
                <Td>
                  <p>{item.plate}</p>
                </Td>
                <Td>
                  <p>{item.fuel}</p>
                </Td>
                <Td>
                  <p>{item.year}</p>
                </Td>
                <Td>
                  <p>{item.km}</p>
                </Td>
                <Td>
                  <Button
                    fontWeight="400"
                    border="1px"
                    _hover={{
                      color: 'yellow.500',
                      borderColor: 'yellow.500',
                    }}
                    background="#fff"
                  >
                    <a href={item.document_url} target="_blank">
                      Arquivo
                    </a>
                  </Button>
                </Td>
                <Td>
                  {format(new Date(item.created_at), 'dd/MM/yyyy - HH:mm:ss', {
                    locale: ptBR,
                  })}
                </Td>
                <Td>
                  <Button
                    border="1px"
                    _hover={{ color: 'yellow.500', borderColor: 'yellow.500' }}
                    background="#fff"
                  >
                    <FiEdit
                      cursor="pointer"
                      size={20}
                      onClick={() => handleEdit(item.id)}
                    />
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <ModalEditVehicle
        isOpen={modalEditOpen}
        setIsOpen={toggleModalEdit}
        handleVehicle={handleEditVehicle}
        dataEditVehicle={dataEditVehicle}
      />
    </>
  );
};

export default TableVehicles;

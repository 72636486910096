import { Stack } from '@chakra-ui/react';
import { RiMenu2Fill } from 'react-icons/ri';

import { BiCar, BiBarChartAlt2 } from 'react-icons/bi';
import { FiUsers, FiLock, FiShuffle, FiCode } from 'react-icons/fi';

import { NavLink } from '../NavLink';
import { NavSection } from '../NavSection';

export function SidebarNavAdm() {
  return (
    <Stack spacing="12" align="flex-start">
      <NavSection title="GERAL">
        <NavLink to="/menu" icon={RiMenu2Fill}>
          Menu
        </NavLink>
      </NavSection>

      <NavSection title="GESTÃO DE DADOS">
        <NavLink to="/administrator/employers" icon={FiUsers}>
          Colaboradores
        </NavLink>

        <NavLink to="/administrator/vehicles" icon={BiCar}>
          Veículos
        </NavLink>
        <NavLink to="/administrator/bi" icon={BiBarChartAlt2}>
          Business Intelligence
        </NavLink>

        <NavLink to="/administrator/permissions-and-roles" icon={FiLock}>
          Permissions and Roles
        </NavLink>

        <NavLink to="/administrator/automations" icon={FiCode}>
          Automações
        </NavLink>
      </NavSection>
      <NavSection title="GESTÃO DE DADOS - 2021">
        <NavLink to="/management-ppr/dashboard" icon={FiShuffle}>
          Rotas PPR 2021
        </NavLink>
      </NavSection>
    </Stack>
  );
}

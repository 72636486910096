import { useCallback, useRef, useState, useEffect } from 'react';
import { Button, ModalBody, ModalFooter, Flex, Box } from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import Select from '../../../Global/SelectRelease';
import TextArea from '../../../Global/TextArea';
import { apllyToast } from '../../../Global/Toast2.0';
import { api } from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import SelectUsers from './SelectUsers';
import { useCan } from '../../../../hooks/useCan';

interface Props {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  strategicPlansId: string;
  setAddedNewData: (newData: any) => void;
  addedNewData: string;
}
interface strategicPropsFormated {
  value: string;
  label: string;
}
interface strategicProps {
  id: string;
  name: string;
}

interface Options {
  value: string;
  label: string;
}

export default function ModalAddNewIndicator({
  onClose,
  isOpen,
  strategicPlansId,
  setAddedNewData,
  addedNewData,
}: Props) {
  const formRef = useRef<FormHandles>(null);
  // const [dataPLacements, setDataPlacements] = useState<
  //   strategicPropsFormated[]
  // >([]);
  const [dataObjective, setDataObjective] = useState<strategicPropsFormated[]>(
    [],
  );
  const [dataSelectesUsers, setSelectedDataUsers] = useState<Options[]>([]);

  const userCanSeeUrlImageInput = useCan({
    permissions: ['add_url_image_indicator'],
  });

  // useEffect(() => {
  //   try {
  //     api.get<strategicProps[]>('/strategic-placements').then(response => {
  //       if (response.data) {
  //         const placementsFormated = response.data.map(placement => {
  //           return {
  //             label: placement.name,
  //             value: placement.id,
  //           };
  //         });
  //         setDataPlacements(placementsFormated);
  //       }
  //     });
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // }, []);

  useEffect(() => {
    try {
      api.get<strategicProps[]>('/strategic-objectives').then(response => {
        if (response.data) {
          const placementsFormated = response.data.map(objectve => {
            return {
              label: objectve.name,
              value: objectve.id,
            };
          });
          console.log('teste', placementsFormated);
          setDataObjective(placementsFormated);
        }
      });
    } catch (e) {
      console.log('error', e);
    }
  }, [addedNewData]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório!'),
          unit: Yup.string().required('Unidade é obrigatória!'),
          strategic_objective_id: Yup.string().required(
            'Objetivo estratégico é obrigatório!',
          ),

          data_source_and_formula: Yup.string().required(
            'Este campo é obrigatório!',
          ),
          operational_definition: Yup.string().required(
            'Este campo é obrigatório!',
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const {
          name,
          strategic_objective_id,
          unit,
          observation,
          data_source_and_formula,
          operational_definition,
        } = data;

        const formatedData = {
          strategic_objective_id,
          strategic_plan_id: strategicPlansId,
          name,
          unit,
          observation,
          data_source_and_formula,
          operational_definition,
        };

        api.post('/indicators', formatedData).then(response => {
          const formatData = {
            indicator_id: response.data.id,
            responsible_ids: dataSelectesUsers.map(responsable => {
              return responsable.value;
            }),
          };

          api
            .post('/responsible-of-indicators/create-all', formatData)
            .then(res => {
              setAddedNewData(res.data);
              apllyToast('success', 'Sucesso ao adicionar novo indicador!');
              onClose(!isOpen);
            });
        });
      } catch (err) {
        console.log(err);
        onClose(!isOpen);
        apllyToast('error', 'Problemas ao adicionar novo indicador!');
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }

      console.log(data);
    },
    [dataSelectesUsers, isOpen, onClose, setAddedNewData, strategicPlansId],
  );

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        onClose={() => onClose(!isOpen)}
        scrollBehavior="inside"
        // sizeSpecifyc="2xl"
        title="Adicionar novo indicador"
        sizeSpecifyc="2xl"
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Flex flexDirection="column" mt="4">
              <Flex alignItems="center" justifyContent="space-between">
                <Flex flexDirection="column" w="100%" maxWidth={450}>
                  <p>Nome</p>
                  <Input name="name" />
                </Flex>
                <Box mb="6px">
                  <Select
                    name="unit"
                    label="Unidade de medida"
                    options={[
                      { label: '%', value: '%' },
                      { label: 'Numerico', value: 'Numerico' },
                      { label: 'Texto', value: 'Texto' },
                    ]}
                  />
                </Box>
              </Flex>

              {/* <Select
                name="classification"
                label="Classificação"
                options={[{ label: 'label', value: 'value' }]}
              /> */}

              <Select
                name="strategic_objective_id"
                label="Objetivo estratégico"
                options={dataObjective}
              />

              <SelectUsers
                title="adicionar responsável"
                setSelectedDataUsers={setSelectedDataUsers}
                dataSelectesUsers={dataSelectesUsers}
              />

              {/* <Select
                label="Posicionamento estratégico"
                options={dataPLacements}
                name="strategic_placement_id"
              /> */}

              <p>Definição operacional</p>
              <TextArea name="operational_definition" />

              <p>Fontes de dados e fórmula</p>
              <TextArea name="data_source_and_formula" />

              <p>Observação</p>
              <TextArea name="observation" />
              {userCanSeeUrlImageInput && (
                <>
                  <p>Image_url</p>
                  <Input name="image_url" />
                </>
              )}
            </Flex>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => onClose(!isOpen)}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
  label: string;
}

export function Group({ children, label }: Props) {
  return (
    <Box bg="gray.800" p="10px" borderRadius="6px" mb="10px">
      <Flex flexDirection="row" justifyContent="space-between">
        <Text as="h1">{label}</Text>
      </Flex>

      {children}
    </Box>
  );
}

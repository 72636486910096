/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState } from 'react';
import { Form } from '@unform/web';

import { Link } from 'react-router-dom';
import { FiAlertCircle } from 'react-icons/fi';
import { Button as ButtonChakra, Flex, ScaleFade } from '@chakra-ui/react';
import { shade } from 'polished';
import OrderServiceTable from '../../../../components/Admin/OrderServiceTablePainel';
import Select from '../../../../components/Global/Select';
import Input from '../../../../components/Global/Input';
import Button from '../../../../components/Global/Button';
import { Sidebar } from '../../../../components/_components_0.2/Sidebar_0.2';
import { HeaderUp } from '../../../../components/_components_0.2/Header_0.2';

import ModallServicesOrders from '../../../../components/Admin/Modal/ModalServicesOrders';

import {
  handleSendEmailOpenOrderServiceAdm,
  handleSendEmailOpenOrderServiceUser,
} from '../../../../services/sendEmailOpenOrderService';

import { useAuth } from '../../../../hooks/auth';

import { CardeHeader, Container } from './styles';
import { api } from '../../../../services/api';

interface PropsItem {
  title?: string;
}

interface IdataTable {
  created_at: string;
  email: string;
  id: string;
  name: string;
  reason: string;
  status: string;
  updated_at: string;
  // urgency: string;
  identification: number;
  observations: string;
  file: string;
  reason_observation: string;
  finisher: {
    name: string;
  };
}

interface IFilter {
  finishedDateIn: string;
  finishedDateOut: string;
  startDateIn: string;
  startDateOut: string;
  status: string;
  // urgency: string;
}

const Reports: React.FC<PropsItem> = ({ title }) => {
  const { user, updateUser } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  const [toogleFilter, setToogleFilter] = useState(true);
  const [reloadTable, setReloadTable] = useState('');

  const [newServicesOrders, setNewServicesOrders] = useState<IdataTable>();

  const [dataFilter, setDataFilter] = useState<IFilter>({
    finishedDateIn: '',
    finishedDateOut: '',
    startDateIn: '',
    startDateOut: '',
    status: 'Pendente',
    // urgency: '',
  });

  const toggleModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);

  const handleAnalytic = useCallback(
    async (servicesOrders: Omit<IdataTable, 'e'>) => {
      try {
        const temp = servicesOrders;
        setNewServicesOrders(temp);
        handleSendEmailOpenOrderServiceAdm(temp);
        handleSendEmailOpenOrderServiceUser(temp, user);
      } catch (err) {
        console.log(err);
      }
    },
    [user],
  );

  const handleFilter = useCallback((data: IFilter) => {
    setDataFilter(data);
  }, []);

  const handleToogleFilter = useCallback(() => {
    setToogleFilter(!toogleFilter);
  }, [toogleFilter]);

  const handleServiceAbsence = useCallback(async () => {
    if (user.status === 'Ausente') {
      const response = api.put('/accesses', {
        nickname: user.nickname,
        status: 'Presente',
      });
      updateUser((await response).data);
    } else if (user.status === 'Presente') {
      const response = api.put('/accesses', {
        nickname: user.nickname,
        status: 'Ausente',
      });
      updateUser((await response).data);
    }
  }, [updateUser, user.nickname, user.status]);

  return (
    <>
      <ModallServicesOrders
        isOpen={modalOpen}
        setIsOpen={toggleModal}
        handleAnalytic={handleAnalytic}
        setReloadTable={setReloadTable}
      />
      <ScaleFade initialScale={0.9} in>
        <Flex direction="column" h="100vh">
          <HeaderUp path="miles" />
          <Flex w="100%" my="6" maxWidth={1480} mx="auto" pb={4} px="6">
            <Sidebar path="os" />
            <Container toogleFilter={toogleFilter}>
              <CardeHeader titleItem={title}>
                <div>
                  <h2>
                    Painel Ordens de serviços - TI
                    <Link
                      target="__black"
                      to="/rules/sector-resume-rules?solicitacao-de-servico-para-ti"
                    >
                      {' '}
                      <FiAlertCircle size={20} />{' '}
                    </Link>
                  </h2>
                  <strong>
                    Monitore a demanda de solicitações de serviços
                  </strong>
                </div>

                {/* <CardButton>
            <div>
              <Button isUsed onClick={toggleModal}>
                Nova ordem
              </Button>
            </div>
          </CardButton> */}
              </CardeHeader>

              <section className="section-filter">
                <header>
                  <span>
                    <Button
                      isUsed
                      type="button"
                      onClick={handleToogleFilter}
                      groud={false}
                      disabled={false}
                    >
                      <p>Filtro</p>
                    </Button>
                  </span>

                  {/* <span>
              <Button
                isUsed
                type="button"
                className={user.status}
                onClick={handleServiceAbsence}
                groud={false}
                disabled={false}
              >
                <p>{user.status}</p>
              </Button>
            </span> */}
                </header>

                <Form onSubmit={handleFilter}>
                  <section>
                    <div>
                      <fieldset className="fieldset-category">
                        <legend>Categoria</legend>

                        {/* <p>Nivel de urgencia</p>

                  <Select
                    name="urgency"
                    options={[
                      {
                        label: 'Vazio',
                        value: '',
                      },
                      {
                        label: 'Baixo',
                        value: 'baixo',
                      },
                      {
                        label: 'Médio',
                        value: 'medio',
                      },
                      {
                        label: 'Alto',
                        value: 'alto',
                      },
                    ]}
                  /> */}
                        {/* <select>
                  <option>Baixo</option>
                  <option>Médio</option>
                  <option>Alto</option>
                </select> */}

                        <p>Status</p>
                        <Select
                          name="status"
                          options={[
                            {
                              label: 'Vazio',
                              value: '',
                            },
                            {
                              label: 'Pendente',
                              value: 'Pendente',
                            },
                            {
                              label: 'Finalizado',
                              value: 'Finalizado',
                            },
                            {
                              label: 'Andamento',
                              value: 'Andamento',
                            },
                          ]}
                        />
                      </fieldset>
                    </div>

                    <fieldset>
                      <legend>Data da solicitação</legend>
                      <div>
                        <p>Data inicial</p>
                        <Input type="date" name="startDateIn" />

                        <p className="space-top">Data final</p>
                        <Input type="date" name="startDateOut" />
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>Data de atendimento</legend>
                      <div>
                        <p>Data inicial</p>
                        <Input type="date" name="finishedDateIn" />

                        <p className="space-top">Data final</p>
                        <Input type="date" name="finishedDateOut" />
                      </div>
                    </fieldset>
                    <span>
                      <ButtonChakra
                        type="submit"
                        width="6rem"
                        background="blue.500"
                        textColor="#fff"
                        fontWeight="500"
                        _hover={{
                          background: shade(0.2, '#f2c811'),
                          borderColor: shade(0.2, '#f2c811'),
                        }}
                        transition="0.7s"
                      >
                        Aplicar filtro
                      </ButtonChakra>
                    </span>
                  </section>
                </Form>
              </section>
              <div className="section-body">
                <OrderServiceTable
                  email={user.email}
                  filterData={dataFilter}
                  newServicesOrders={newServicesOrders}
                />
              </div>
            </Container>
          </Flex>
        </Flex>
      </ScaleFade>
    </>
  );
};

export default Reports;

import { useCallback, useRef, useState } from 'react';
import { Button, ModalBody, ModalFooter } from '@chakra-ui/react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useLoading, Oval } from '@agney/react-loading';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import { apllyToast } from '../../../Global/Toast2.0';

interface IPermissionsType {
  id?: string;
  name: string;
  description: string;
  identification: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleSubmitPermissions: (data: IPermissionsType) => void;
}

export function ModalAddNewPermissions({
  onClose,
  isOpen,
  handleSubmitPermissions,
}: Props) {
  const formRef = useRef<FormHandles>(null);

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          description: Yup.string().required('Descrição é obrigatório'),
          identification: Yup.string().required('Identificação é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        handleSubmitPermissions(data);

        onClose();
        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          apllyToast('error', 'Problemas ao adicionar nova permissão');
          setLoading(false);
          onClose();

          formRef.current?.setErrors(errors);
        }
      }
    },
    [handleSubmitPermissions, onClose],
  );

  const { containerProps, indicatorEl } = useLoading({
    loading,
    indicator: <Oval />,
  });

  return (
    <>
      <ModalComponent
        title="Adicionar nova Permissão"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <p>Nome</p>
            <Input type="text" name="name" />
            <p>Identificação</p>
            <Input type="text" name="identification" />
            <p>Descrição</p>
            <Input type="text" name="description" />

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                {loading ? (
                  <div {...containerProps} ref={componentRef}>
                    {indicatorEl}
                  </div>
                ) : (
                  'Salvar'
                )}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

import { DrawerBody, DrawerHeader, Button, Flex } from '@chakra-ui/react';
import { DrawerComponent } from '../index';

interface BookmarkData {
  bookmark: string;
  name: string;
  access_id: string;
  embedId: string;
  public: boolean;
}

interface PropsDrawer {
  bookmarkData: BookmarkData[];
  isOpen: boolean;
  onClose: () => void;
  handleSetBookmark: (state: string) => void;
}

export function DrawerNavigationBi({
  isOpen,
  onClose,
  bookmarkData,
  handleSetBookmark,
}: PropsDrawer) {
  return (
    <DrawerComponent isOpen={isOpen} onClose={onClose} placement="bottom">
      <DrawerHeader borderBottomWidth="1px">Minhas visualizações</DrawerHeader>

      <DrawerBody mb="5">
        <Flex>
          {bookmarkData?.map(book => (
            <Button
              colorScheme="blue"
              m="2"
              key={book.bookmark}
              onClick={() => handleSetBookmark(book.bookmark)}
            >
              {book.name}
            </Button>
          ))}
        </Flex>
      </DrawerBody>
    </DrawerComponent>
  );
}

export const steps = [
  {
    title: 'Etapa 1',
    description: 'Local',
  },
  {
    title: 'Etapa 2',
    description: 'Descrição e Orçamento',
  },
  {
    title: 'Etapa 3',
    description: 'Investimento e retorno',
  },
];

export const members = [
  {
    id: '1',
    name: 'Christian Nwamba',
    handle: '@christian',
    avatarUrl: 'https://bit.ly/code-beast',
    status: 'active',
    message: 'Some message',
    lastSeen: 'just now',
  },
];

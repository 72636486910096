/* eslint-disable react/require-default-props */
/* eslint-disable react/no-children-prop */
import React, { useEffect, useRef } from 'react';
// import { IconBaseProps } from 'react-icons';

import { useField } from '@unform/core';
import {
  Input as InputChakra,
  InputProps as InputPropsElement,
  Tooltip,
  InputGroup,
  InputLeftElement,
  Editable,
  EditablePreview,
  EditableInput as EditableInputChakra,
} from '@chakra-ui/react';

interface InputProps extends InputPropsElement {
  name: string;
  icon?: any;
  type?: any;
  placeHolder?: string;
}

const EditableInput: React.FC<InputProps> = ({
  name,
  icon: Icon,
  type,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Tooltip hasArrow label={error} bg="#c53030">
      <InputGroup>
        {Icon ? (
          <InputLeftElement
            pointerEvents="none"
            children={Icon && <Icon color="gray.300" />}
          />
        ) : (
          ''
        )}

        <Editable defaultValue={defaultValue} w="100%">
          <EditablePreview />
          <InputChakra
            isInvalid={!!error}
            // defaultValue={defaultValue}
            type={type}
            fontSize="xl"
            as={EditableInputChakra}
            ref={inputRef}
            {...rest}
          />
        </Editable>
      </InputGroup>
    </Tooltip>
  );
};

export default EditableInput;

/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useCallback, useRef } from 'react';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';

import { useLoading, Oval } from '@agney/react-loading';
import { CardeHeader } from './styles';

import ReportConectBI from '../../../../../components/Admin/Reports/ReportConectBI/index';

const SelectedSector: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [styleReport, setStyleReport] = useState<
    'window' | 'landscape' | 'portrait'
  >('window');

  const [loadPrint, setLoadPrint] = useState(false);

  const handleSetPrintReport = useCallback((modelPrint: any) => {
    setLoadPrint(true);
    setStyleReport(modelPrint);
    setTimeout(() => {
      window.print();
      setLoadPrint(false);
    }, 3000);
  }, []);

  window.onafterprint = function () {
    setStyleReport('window');
  };

  const { containerProps, indicatorEl } = useLoading({
    loading: loadPrint,
    indicator: <Oval />,
  });

  return (
    <>
      <CardeHeader className="iconPrint">
        <div />
        <span id="noPrint">
          <Menu
            menuButton={
              <MenuButton>
                {loadPrint ? (
                  <div {...containerProps} ref={componentRef}>
                    {indicatorEl}
                  </div>
                ) : (
                  ' Opções'
                )}
              </MenuButton>
            }
            className="my-menu"
          >
            <SubMenu label="Imprimir">
              <MenuItem onClick={() => handleSetPrintReport('landscape')}>
                Modo paisagem
              </MenuItem>
              <MenuItem onClick={() => handleSetPrintReport('portrait')}>
                Modo Retrato
              </MenuItem>
            </SubMenu>
            <MenuItem>Relatar erro</MenuItem>
          </Menu>
        </span>
      </CardeHeader>

      {console.log(styleReport)}
      <div className="print-container">
        <ReportConectBI />
      </div>
    </>
  );
};

export default SelectedSector;

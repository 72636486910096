/* eslint-disable import/no-duplicates */
import { Box, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { api } from '../../../../services/api';
import { RevisionHistoryAddPopover } from './RevisionHistoryAddPopover';
import { RevisionsHistoryItens } from './RevisionsHistoryItens';
import { useCan } from '../../../../hooks/useCan';

interface Props {
  process_mapping_id: string;
  // contents?: string;
  updateNewRevisionHistory: string;
  setUpdateNewRevisionHistory: (revision_id: string) => void;
}

interface RevisionFormated {
  id: string;
  review_number: number;
  description: string;
  dateFormated: string;
  access_id: string;
  name: string;
}

export function RevisionsHistory({
  process_mapping_id,
  // contents,
  setUpdateNewRevisionHistory,
  updateNewRevisionHistory,
}: Props) {
  const [revisions, setDataRevisions] = useState<RevisionFormated[]>([]);

  const userCanSeeDataProcess = useCan({
    permissions: ['data_process_manager'],
  });

  // const statusForSunEditor = useMemo(() => {
  //   if (contents === null || contents === undefined) {
  //     return false;
  //   }
  //   return true;
  // }, [contents]);

  useEffect(() => {
    try {
      api
        .get('/procedures-reviews/show', {
          params: {
            process_mapping_id,
          },
        })
        .then(response => {
          if (response.data.length > 0) {
            const formatData = response.data.map(
              (item: {
                id: any;
                description: any;
                review_number: any;
                date: number | Date;
                access_id: string;
                access: {
                  name: string;
                };
              }): RevisionFormated => {
                const data = {
                  id: item.id,
                  description: item.description,
                  review_number: item.review_number,
                  access_id: item.access_id,
                  name: item.access.name,
                  dateFormated: format(
                    new Date(item.date),
                    "'Dia' dd 'de' MMMM 'de' yyy",
                    {
                      locale: ptBR,
                    },
                  ),
                };
                return data;
              },
            );
            console.log('eee', response.data);
            setDataRevisions(formatData);
          }
        });
    } catch (e) {
      console.log('error', e);
    }
  }, [updateNewRevisionHistory, process_mapping_id]);

  return (
    <Box maxWidth="250px">
      <Box display="flex" flexDirection="row">
        <Text mr="3" fontWeight="semibold">
          Histórico de revisões
        </Text>

        {userCanSeeDataProcess && (
          <RevisionHistoryAddPopover
            process_mapping_id={process_mapping_id}
            setUpdateNewRevisionHistory={setUpdateNewRevisionHistory}
          />
        )}
      </Box>
      <Box>
        <Box position="relative">
          <Box
            borderRight="2px solid #797D9A"
            left="0.75rem"
            position="absolute"
            top="0px"
            h="100%"
          />

          <Box
            className="timeline__items"
            listStyleType="none"
            margin="0px"
            padding="0px"
          >
            {revisions.length > 0 ? (
              <>
                {revisions.map(revision => (
                  <RevisionsHistoryItens
                    userName={revision.name}
                    title={`Revisão ${revision.review_number} - ${revision.dateFormated}`}
                    body={revision.description}
                    key={revision.id}
                  />
                ))}
              </>
            ) : (
              <Text marginLeft="2rem">Nenhuma revisão registrada</Text>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import { createContext, ReactNode, SetStateAction, useState } from 'react';

interface SetTitleProvider {
  children: ReactNode;
}

type SetTitleProviderProps = {
  handleSetTitle: (title: string) => void;
  titleReport: string;
};

export const SetTitleContext = createContext({} as SetTitleProviderProps);

export function SetTitleBI({ children }: SetTitleProvider) {
  const [titleReport, setTitleReport] = useState('');

  const handleSetTitle = (title: SetStateAction<string>) => {
    setTitleReport(title);
  };

  const titleBiProvider = {
    handleSetTitle,
    titleReport,
  };

  return (
    <SetTitleContext.Provider value={titleBiProvider}>
      {children}
    </SetTitleContext.Provider>
  );
}

import React from 'react';

import {
  Flex,
  Input,
  Icon,
  Tooltip,
  useDisclosure,
  Button,
} from '@chakra-ui/react';

import { RiSearchLine } from 'react-icons/ri';
import { ModalFilterSchedule } from '../Modal/ModalFilterSchedule';

export function SearchBox() {
  const { onClose, isOpen, onOpen } = useDisclosure();

  return (
    <>
      <Tooltip
        hasArrow
        label="Pesquise atraves do nome do colaborador ou apenas o setor"
        bg="gray.650"
      >
        <Flex
          as="label"
          flex="1"
          py="4"
          px="8"
          ml="6"
          maxWidth={600}
          alignSelf="center"
          color="gray.200"
          position="relative"
          bg="gray.800"
          borderRadius="full"
          borderColor="red"
          cursor="pointer"
        >
          <Input
            as={Button}
            onClick={onOpen}
            cursor="pointer"
            color="gray"
            variant="unstyled"
            px="4"
            placeholder="Busca na plataforma"
            _placeholder={{ color: 'gray.400' }}
            _hover={{ backgroundColor: 'white' }}
            fontWeight="normal"
          >
            Agenda Cooasgo
          </Input>
          <Icon color="gray" as={RiSearchLine} fontSize="20" />
        </Flex>
      </Tooltip>
      <ModalFilterSchedule onClose={onClose} isOpen={isOpen} />
    </>
  );
}

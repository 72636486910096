/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import fileSize from 'filesize';
import {
  Button,
  ModalBody,
  ModalFooter,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Box,
  Container,
  Stack,
  VisuallyHidden,
  Text,
  Tooltip,
  IconButton,
  Avatar,
  Checkbox,
} from '@chakra-ui/react';
import { Form } from '@unform/web';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import { MultiSelect } from 'react-multi-select-component';

import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';
import { Internationalization } from '../../../../utils/InternationalizationMultSelect';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { useAuth } from '../../../../hooks/auth';
import { Commits } from './ListComments';
import Input from '../../../Global/Input';
import Select from '../../../Global/SelectRelease';
import TextArea from '../../../Global/TextArea';
import { ModalComponent } from '..';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';

import { useStep } from './useStep';
import { Step } from './Step';
import { steps } from './data';
import Upload from '../../../Global/Upload';
import FileListLink from '../../../Global/FileListLink';
import { initialDataSelect } from './initialDataSelects';
import { useCan } from '../../../../hooks/useCan';

interface Props {
  isOpen: boolean;
  onClose: (close: boolean) => void;
  refetch: () => void;
  investment_id: string;
}
interface Options {
  label: string;
  value: string;
}

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

interface FileList {
  id: string;
  file: string;
  file_url: string;
}

interface Commit {
  id: string;
  user: {
    name: string;
    nickname: string;
  };
  content: string;
  created_at: string;
}

interface IOptions {
  value: string;
  label: string;
  identification?: string;
  disabled?: boolean;
  existingData?: boolean;
}

const dataOpt = [
  {
    value: 'Maquinas e equipamentos',
    label: 'Maquinas e equipamentos',
  },
  {
    value: 'Maquinas e equipamentos industriais',
    label: 'Maquinas e equipamentos industriais',
  },
  {
    value: 'Equip. de informatica',
    label: 'Equip. de informatica',
  },
  {
    value: 'Equip. de laboratório',
    label: 'Equip. de laboratório',
  },
  {
    value: 'Equip. de Proteção e segurança',
    label: 'Equip. de Proteção e segurança',
  },
  {
    value: 'Edif. e construções',
    label: 'Edif. e construções',
  },
  {
    value: 'Maquinas e equipamentos de informatica',
    label: 'Maquinas e equipamentos de informatica',
  },
  {
    value: 'Moveis e utensilio',
    label: 'Moveis e utensilio',
  },
  {
    value: 'Instalações',
    label: 'Instalações',
  },
  {
    value: 'Veiculos',
    label: 'Veiculos',
  },
  {
    value: 'Software',
    label: 'Software',
  },
  {
    value: 'Aumento de mão de obra',
    label: 'Aumento de mão de obra',
  },
];
const dataType = [
  {
    value: 'Ordem Legal',
    label: 'Ordem Legal',
  },
  {
    value: 'Ordem de Melhoria de Custos',
    label: 'Ordem de Melhoria de Custos',
  },
  {
    value: 'Ordem de Melhoria de Receitas',
    label: 'Ordem de Melhoria de Receitas',
  },
];
const dataPrioryt = [
  {
    value: '1',
    label: 'Baixa',
  },
  {
    value: '2',
    label: 'Média',
  },
  {
    value: '3',
    label: 'Alta',
  },
];
export function ModalEditInvestment({
  onClose,
  isOpen,
  refetch,
  investment_id,
}: Props) {
  const formRef = useRef<FormHandles>(null);
  const editor = useRef<SunEditorCore>();
  const { user } = useAuth();
  const { state, pathname } = useLocation<any>();
  const numberOfSteps = 3;
  const [currentStep, { setStep, goToNextStep, goToPrevStep }] = useStep({
    maxStep: numberOfSteps,
    initialStep: 0,
  });
  const [loading, setLoading] = useState(false);
  const [loadingCommit, setLoadingCommit] = useState(false);
  const [activeTextAreaCommit, setActiveTextAreaCommit] = useState(false);
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);

  const [investment_name, setInvestment_name] = useState('');
  const [branch_id, setBranch_id] = useState('');
  const [sector_id, setSector_id] = useState('');
  const [category, setCategory] = useState('');
  const [responsible_id, setResponsible_id] = useState('');
  const [investment_type, setinvestment_type] = useState('');
  const [prioritydata, setPriority] = useState('1');

  const [labor, setlabor] = useState('');
  const [when, setWhen] = useState('');
  const [justification, setJustification] = useState('');
  const [description_project, setDescription_project] = useState('');
  const [investment_description, setInvestment_description] = useState('');

  const [pic_year] = useState('2024');

  const [value_total, setValue_total] = useState(0);
  const [investment_return, setInvestment_return] = useState('');
  const [situation, setSituation] = useState('');

  const [dataSectors, setDataSectors] = useState<Options[]>([]);
  const [dataBranch, setDataBranches] = useState<Options[]>([]);
  const [dataResponsable, setDataResponsable] = useState<Options[]>([]);

  const [initialCommits, setInitialsCommits] = useState<Commit[]>([]);

  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);
  const [fileList, setFileList] = useState<FileList[]>([]);

  const [initialData, setInitialData] = useState<any>();
  const [addedNewCommit, setAddedNewCommit] = useState('');

  const [investmentSelected, setInvestmentSelected] = useState<IOptions[]>([]);
  const [dataInvestments, setDataInvestments] = useState([
    {
      label: '',
      value: '',
      existingData: false,
    },
  ]);

  const [picRelated, setPicRelated] = useState(false);
  const [picRepeat, setPicRepeat] = useState(false);

  const [totalCost, setTotalCost] = useState(0);
  const [charges, setCharges] = useState(0);
  const [otheExpenses, setOtherExpenses] = useState(0);

  const userCanAnalystForPic = useCan({
    permissions: ['user_pic_analyst'],
  });

  useEffect(() => {
    const coastActual = Number(charges) + Number(otheExpenses);
    setTotalCost(coastActual);
  }, [charges, otheExpenses]);

  useEffect(() => {
    try {
      api
        .get(`investments-plans/show?sector_id=${user.sector_id}`)
        .then(response => {
          const arrayRolesFormated: {
            value: string;
            label: string;
            existingData: boolean;
          }[] = [];
          // Preciso ver como resolver o problema das permissões, estou pensando em colocar aq as pemissões formatadas corretamente
          response.data.forEach(
            (element: { id: string; investment_name: string }) => {
              const goal = {
                label: element.investment_name,
                value: element.id,
                existingData: true,
              };
              arrayRolesFormated.push(goal);
            },
          );

          setDataInvestments(arrayRolesFormated);
        });
    } catch (err) {
      console.log('error', err);
    }
  }, [user.sector_id]);

  const handleClearData = useCallback(() => {
    try {
      setInvestment_name('');
      setBranch_id('');
      setSector_id('');
      setCategory('');
      setResponsible_id('');
      setinvestment_type('');
      setPriority('');
      setJustification('');
      setDescription_project('');
      setInvestment_description('');
      setSituation('');
      setWhen('');
      setStep(0);
      setTabIndex(0);
      onClose(false);
      setActiveTextAreaCommit(false);
      setPicRelated(false);
      setPicRepeat(false);
      setTotalCost(0);
      setCharges(0);
      setOtherExpenses(0);
      setInvestmentSelected([]);
      history.push('/pic');
    } catch (err) {
      console.log('error', err);
    }
  }, [history, onClose, setStep]);

  const getSunEditorInstance = useCallback((sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  }, []);

  useEffect(() => {
    try {
      const id_invest = pathname.substring(5, 50);
      if (id_invest !== '') {
        if (state) {
          console.log('wewewewe', state);
          setInitialData(state.investment);
          initialDataSelect({
            initialData: state.investment,
            setInvestment_name,
            setBranch_id,
            setSector_id,
            setCategory,
            setPriority,
            setResponsible_id,
            setinvestment_type,
            setJustification,
            setDescription_project,
            setInvestment_description,
            setWhen,
            setlabor,
            setValue_total,
            setInvestment_return,
            setSituation,
            setFileList,
            setPicRelated,
            setPicRepeat,
            setOtherExpenses,
            setCharges,
          });
        } else {
          api
            .get('/investments-plans/show', {
              params: {
                id: id_invest,
              },
            })
            .then(response => {
              console.log('key', response.data);
              setInitialData(response.data);
              initialDataSelect({
                initialData: response.data,
                setInvestment_name,
                setBranch_id,
                setSector_id,
                setCategory,
                setPriority,
                setResponsible_id,
                setinvestment_type,
                setJustification,
                setDescription_project,
                setInvestment_description,
                setWhen,
                setlabor,
                setValue_total,
                setInvestment_return,
                setSituation,
                setFileList,
                setPicRelated,
                setPicRepeat,
                setOtherExpenses,
                setCharges,
              });
            });
        }

        onClose(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [investment_id, onClose, pathname, state]);

  useEffect(() => {
    try {
      if (picRelated && investment_id !== '') {
        api
          .get(
            `investments-plans-links/show?investment_plan_main_id=${investment_id}`,
          )
          .then(response => {
            const formatedData = response.data.map(
              (opts: {
                investment_plan_link_id: string;
                investment_name: string;
              }) => {
                const res = dataInvestments.filter(dat => {
                  return dat.value === opts.investment_plan_link_id;
                });
                console.log('reeessws', res);
                if (res.length > 0) {
                  return {
                    value: res[0].value,
                    label: res[0].label,
                  };
                }
                return [
                  {
                    label: '',
                    value: '',
                    existingData: false,
                  },
                ];
              },
            );
            setInvestmentSelected(formatedData);
          });
      }
    } catch (e) {
      console.log('error', e);
    }
  }, [dataInvestments, investment_id, picRelated]);

  useEffect(() => {
    api.get(`/sectors`).then(response => {
      const formatData: { value: string; label: string }[] = [];

      response.data.forEach((data: { id: string; name: string }) => {
        const config = {
          value: data.id,
          label: data.name,
        };

        formatData.push(config);
      });

      setDataSectors(formatData);
    });
  }, []);
  useEffect(() => {
    api.get(`/branches`).then(response => {
      const formatData: { value: string; label: string }[] = [];

      response.data.forEach((data: { id: string; short_name: string }) => {
        const config = {
          value: data.id,
          label: data.short_name,
        };

        formatData.push(config);
      });

      setDataBranches(formatData);
    });
  }, []);
  useEffect(() => {
    api.get(`/accesses`).then(response => {
      const formatData: { value: string; label: string }[] = [];

      response.data.forEach((data: { id: string; name: string }) => {
        const config = {
          value: data.id,
          label: data.name,
        };

        formatData.push(config);
      });

      setDataResponsable(formatData);
    });
  }, []);
  useEffect(() => {
    try {
      api
        .get(
          `investments-plans-of-commits/show?investment_plan_id=${investment_id}`,
        )
        .then(response => {
          setInitialsCommits(response.data);
        });
    } catch (e) {
      console.log('error', e);
    }
  }, [investment_id, addedNewCommit]);

  const editPic = useMemo(() => {
    if (situation === 'PIC inconsistente') {
      return true;
    }
    if (situation === 'PIC incompleto') {
      return true;
    }
    if (situation === 'Preenchimento concluído') {
      return true;
    }
    if (situation === 'PIC não finalizado') {
      return true;
    }
    return false;
  }, [situation]);

  const editPicPT2 = useMemo(() => {
    if (
      situation === 'Aguardando análise' ||
      situation === 'Atualizado pelo Analista'
    ) {
      return true;
    }
    return false;
  }, [situation]);

  const handleAddCommit = useCallback(async () => {
    try {
      setLoadingCommit(true);
      const formatData = {
        investment_plan_id: investment_id,
        user_id: user.id,
        content: editor.current?.getContents(true),
      };
      await api
        .post('investments-plans-of-commits', formatData)
        .then(response => {
          setAddedNewCommit(response.data.id);
          apllyToast('info', 'Comentário adicionado!');
          setActiveTextAreaCommit(false);
          setLoadingCommit(false);
        });
    } catch (e) {
      console.log('errr', e);
      apllyToast('error', 'Problemas ao adicionar cmentário!');
      setLoadingCommit(false);
    }
  }, [investment_id, user.id]);

  const handleSubmit = useCallback(
    async data => {
      console.log('state', state);

      try {
        setLoading(true);
        console.log('eeeee', data);
        const dataFile = new FormData();

        if (investment_id === '') {
          apllyToast('error', 'Selecione um PIC!');
          setLoading(false);
          return;
        }

        // if (
        //   uploadedFiles.length === 0 &&
        //   category !== 'Aumento de mão de obra'
        // ) {
        //   apllyToast('error', 'Adicione um orçamento!');
        //   setLoading(false);
        //   return;
        // }
        const file = uploadedFiles[0];
        // if (category !== 'Aumento de mão de obra') {
        //   dataFile.append('file', file.file, file.name);
        // }
        const { ...planinves } = data;
        const formatedData = {
          ...planinves,
          pic_year: 2024,
          investment: 'investment',
          quantity: 0,
          // when: 'when',
          situation:
            situation === 'Aguardando análise' && userCanAnalystForPic
              ? 'Atualizado pelo Analista'
              : 'Preenchimento concluído',
          observation: 'nada',
          priority: prioritydata,
          pic_related: picRelated,
          pic_repeat: picRepeat,
        };

        setLoading(true);
        await api
          .put(`/investments-plans?id=${investment_id}`, formatedData)
          .then(() => {
            handleClearData();
          });

        if (uploadedFiles.length > 0) {
          dataFile.append('file', file.file, file.name);
          await api.patch(
            `/investments-plans-of-files?investment_plan_id=${investment_id}`,
            dataFile,
          );
        }

        if (picRelated && investmentSelected.length > 0) {
          const formatedLinks = {
            investment_main_id: investment_id,
            investments_links_ids: investmentSelected.map(inves => {
              return inves.value;
            }),
          };

          await api.post('investments-plans-links/all', formatedLinks);
        }
        // await api
        //   .post('/investments-plans', formatedData)
        //   .then(async response => {
        //     if (category !== 'Aumento de mão de obra') {
        //       await api.patch(
        //         `/investments-plans/upload/file?id=${response.data.id}&filename=${file.name}`,
        //         dataFile,
        //       );
        //     }
        //     apllyToast('success', 'Sucesso ao finalizar orçamento!');
        //     handleClearData();
        //   });
        apllyToast('info', 'Investimento salvo!');
        refetch();
        setLoading(false);
      } catch (err) {
        apllyToast('error', 'Problemas ao adicionar novo investimento!');
        setLoading(false);
      }
    },
    [
      state,
      investment_id,
      uploadedFiles,
      situation,
      userCanAnalystForPic,
      prioritydata,
      picRelated,
      picRepeat,
      investmentSelected,
      refetch,
      handleClearData,
    ],
  );

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      const dataFile = new FormData();
      const data = {
        investment_name,
        branch_id,
        sector_id,
        category,
        pic_year,
        situation: 'PIC não finalizado',
        responsible_id: user.id,
        investment_type,
        priority: prioritydata,
        labor,
        when,
        justification,
        description_project,
        investment_description,
        value_total,
        investment_return,
        pic_related: picRelated,
        pic_repeat: picRepeat,
      };

      const schema = Yup.object().shape({
        investment_name: Yup.string().required(
          'Nome do investimento é obrigatório!',
        ),
        branch_id: Yup.string().required(
          'Filial do investimento é obrigatória!',
        ),
        sector_id: Yup.string().required(
          'Setor do investimento é obrigatório!',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
      // if (investment_id === '') {
      //   api.post('/investments-plans', data).then(() => {
      //     apllyToast('info', 'Investimento salvo!');
      //     handleClearData();
      //   });
      // } else {
      // }
      await api.put(`/investments-plans?id=${investment_id}`, data).then(() => {
        handleClearData();
        refetch();
      });

      if (uploadedFiles.length > 0) {
        const file = uploadedFiles[0];
        dataFile.append('file', file.file, file.name);
        await api.patch(
          `/investments-plans-of-files?investment_plan_id=${investment_id}`,
          dataFile,
        );
      }

      if (picRelated && investmentSelected.length > 0) {
        const formatedLinks = {
          investment_main_id: investment_id,
          investments_links_ids: investmentSelected.map(inves => {
            return inves.value;
          }),
        };

        await api.post('investments-plans-links/all', formatedLinks);
      }
      apllyToast('info', 'Investimento salvo!');
      refetch();
      setLoading(false);
    } catch (err) {
      apllyToast('error', 'Problemas ao salvar investimento!');
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        apllyToast('error', 'Problemas ao salvar investimento!');
        setLoading(false);

        formRef.current?.setErrors(errors);
      }
    }
  }, [
    investment_name,
    branch_id,
    sector_id,
    category,
    pic_year,
    user.id,
    investment_type,
    prioritydata,
    labor,
    when,
    justification,
    description_project,
    investment_description,
    value_total,
    investment_return,
    picRelated,
    picRepeat,
    investment_id,
    uploadedFiles,
    investmentSelected,
    refetch,
    handleClearData,
  ]);

  const handleReason = useCallback(e => {
    setinvestment_type(e);
  }, []);

  const handleNextEstep = useCallback(() => {
    goToNextStep();
    setTabIndex(currentStep + 1);
    console.log(currentStep);
  }, [currentStep, goToNextStep]);

  const handleReturnEstep = useCallback(() => {
    goToPrevStep();
    setTabIndex(currentStep - 1);
    console.log(currentStep);
  }, [currentStep, goToPrevStep]);

  const handleTabsChange = useCallback((index): void => {
    setTabIndex(index);
  }, []);

  const nextLevel2 = useMemo(() => {
    if (currentStep === 0) {
      if (
        investment_name !== '' &&
        branch_id !== '' &&
        sector_id !== '' &&
        category !== '' &&
        responsible_id !== '' &&
        investment_type !== '' &&
        prioritydata !== '' &&
        currentStep === 0
      ) {
        return true;
      }
    }
    if (currentStep === 1) {
      if (
        when !== '' &&
        justification !== '' &&
        description_project !== '' &&
        currentStep === 1
      ) {
        if (category === 'Aumento de mão de obra') {
          if (labor !== '') {
            return true;
          }
          return false;
        }
        if (investment_description !== '') {
          return true;
        }
      }
    }
    if (currentStep === 2) {
      if (category === 'Aumento de mão de obra' && currentStep === 2) {
        return true;
      }
      if (
        // value_total !== 0 &&
        investment_return !== '' &&
        currentStep === 2 &&
        editPic
      ) {
        return true;
      }
    }

    return false;
  }, [
    currentStep,
    investment_name,
    branch_id,
    sector_id,
    category,
    responsible_id,
    investment_type,
    prioritydata,
    when,
    justification,
    description_project,
    investment_description,
    labor,
    investment_return,
    editPic,
  ]);
  const nextSave = useMemo(() => {
    if (
      investment_name !== '' &&
      pic_year !== '' &&
      branch_id !== '' &&
      sector_id !== '' &&
      editPic
    ) {
      return true;
    }
    return false;
  }, [investment_name, branch_id, sector_id, pic_year, editPic]);

  const submitFile = useCallback(
    (files: File[]): void => {
      console.log('files', files);
      const uploadFiles = {
        file: files[0],
        name: files[0].name,
        readableSize: fileSize(files[0].size),
      };

      const fileSeteed = {
        id: uploadFiles.name,
        file: uploadFiles.name,
        file_url: uploadFiles.name,
      };

      setFileList([...fileList, fileSeteed]);
      setUploadedFiles([...uploadedFiles, uploadFiles]);
    },
    [fileList, uploadedFiles],
  );

  const handleDeleteFile = useCallback(() => {
    setUploadedFiles([]);
    setFileList([]);
  }, []);

  return (
    <>
      <ModalComponent
        title={editPic ? 'Editar Investimento' : 'Visualizar Investimento'}
        isOpen={isOpen}
        onClose={handleClearData}
        sizeSpecifyc="3xl"
        scrollBehavior="inside"
      >
        <Box bg="bg.surface">
          <Container py={{ base: '1', md: '2' }} mt="1">
            <Stack spacing="0" direction={{ base: 'column', md: 'row' }}>
              {steps.map((step, id) => (
                <Step
                  // eslint-disable-next-line react/no-array-index-key
                  key={id}
                  cursor="pointer"
                  onClick={() => setStep(id)}
                  title={step.title}
                  description={step.description}
                  isActive={currentStep === id}
                  isCompleted={currentStep > id}
                  isFirstStep={id === 0}
                  isLastStep={steps.length === id + 1}
                />
              ))}
            </Stack>
          </Container>
        </Box>

        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
              <VisuallyHidden>
                <TabList>
                  <Tab>Etapa 01</Tab>
                  <Tab>Etapa 02</Tab>
                  <Tab>Etapa 03</Tab>
                </TabList>
              </VisuallyHidden>
              <TabPanels>
                <TabPanel>
                  {/* <Box
                    // textAlign="center"
                    borderRadius="6px"
                    bg="gray.100"
                    mb="15px"
                  > */}
                  {/* <Center maxW="sm" mx="auto" py={{ base: '4', md: '8' }}> */}
                  {userCanAnalystForPic && !activeTextAreaCommit ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      mb="2.5"
                    >
                      <Avatar name={user.name} boxSize="10" />
                      <Box
                        as={Button}
                        borderRadius="6px"
                        bg="gray.100"
                        w="100%"
                        h="2.5rem"
                        p="0.5rem"
                        // alignItems="initial"
                        textAlign="start"
                        onClick={() => setActiveTextAreaCommit(true)}
                      >
                        <Text>Escreva um comentarios...</Text>
                      </Box>
                    </Box>
                  ) : (
                    ''
                  )}
                  {activeTextAreaCommit && (
                    <>
                      <SunEditor
                        setDefaultStyle="font-family:'Roboto', sans-serif; min-height: 220px"
                        // setContents={state ? state.action.contents : undefined}
                        getSunEditorInstance={getSunEditorInstance}
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        justifyContent="end"
                        mb="2.5"
                        w="100%"
                      >
                        <Button
                          m="2"
                          colorScheme="gray"
                          onClick={() => setActiveTextAreaCommit(false)}
                        >
                          Fechar
                        </Button>
                        <Button
                          m="2"
                          colorScheme="blue"
                          onClick={() => handleAddCommit()}
                          isLoading={loadingCommit}
                        >
                          Salvar
                        </Button>
                      </Box>
                    </>
                  )}
                  {initialCommits.length > 0 && (
                    <>
                      <Text>Comentários fixados</Text>
                      {initialCommits.map(commit => (
                        <Commits
                          key={commit.id}
                          name={commit.user.name}
                          created={commit.created_at}
                          content={commit.content}
                          nick={commit.user.nickname}
                        />
                      ))}
                    </>
                  )}
                  {/* </Center> */}
                  {/* </Box> */}
                  <Box
                    textAlign="center"
                    borderRadius="6px"
                    bg="gray.100"
                    mb="15px"
                  >
                    <Text fontWeight="bold">Etapa 01</Text>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    mb="2"
                  >
                    <Checkbox
                      defaultChecked={picRepeat}
                      value="01"
                      onChange={() => setPicRepeat(!picRepeat)}
                    />
                    <Text ml="2">PIC Reicidente?</Text>
                  </Box>

                  <p>Nome do investimento</p>
                  <Input
                    name="investment_name"
                    onChange={e => {
                      setInvestment_name(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />
                  <Select
                    name="branch_id"
                    label="Filial"
                    options={dataBranch}
                    value={branch_id}
                    onChange={e => {
                      setBranch_id(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />
                  <Select
                    name="sector_id"
                    label="Setor"
                    options={dataSectors}
                    value={sector_id}
                    onChange={e => {
                      setSector_id(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />
                  <Select
                    name="category"
                    label="Centro de custo"
                    options={dataOpt}
                    value={category}
                    onChange={e => {
                      setCategory(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />
                  <Select
                    name="responsible_id"
                    label="Responsável"
                    options={dataResponsable}
                    value={responsible_id}
                    onChange={e => {
                      setResponsible_id(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />
                  <Select
                    name="investment_type"
                    label="Tipo de investimento"
                    options={dataType}
                    value={investment_type}
                    onChange={e => {
                      handleReason(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />
                  <Select
                    name="priority"
                    label="Nivel de prioridade"
                    options={dataPrioryt}
                    value={prioritydata}
                    onChange={e => {
                      setPriority(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />
                </TabPanel>
                <TabPanel>
                  <Box
                    textAlign="center"
                    borderRadius="6px"
                    bg="gray.100"
                    mb="15px"
                  >
                    <Text fontWeight="bold">Etapa 02</Text>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    mb="2"
                  >
                    <Checkbox
                      defaultChecked={picRelated}
                      value="01"
                      onChange={() => setPicRelated(!picRelated)}
                    />
                    <Text ml="2">Possui PIC relacionado?</Text>
                  </Box>
                  {picRelated && (
                    <>
                      <p>Selecione PIC's para relacionamento</p>
                      <MultiSelect
                        options={dataInvestments}
                        value={investmentSelected}
                        valueRenderer={selected => {
                          return selected.length
                            ? selected.map(({ label }) => `${label}; `)
                            : 'Nenhum PIC selecionado';
                        }}
                        onChange={setInvestmentSelected}
                        labelledBy="Selecione"
                        overrideStrings={Internationalization}
                      />
                    </>
                  )}
                  {category === 'Aumento de mão de obra' ? (
                    <>
                      <p>Função</p>
                      <Input
                        name="labor"
                        onChange={e => {
                          setlabor(e.target.value);
                        }}
                        isDisabled={!editPic}
                      />
                    </>
                  ) : (
                    ''
                  )}
                  <p>Previsão de inicio/efetivação do projeto</p>
                  <Input
                    name="when"
                    type="date"
                    onChange={e => {
                      setWhen(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />
                  <p>Justificativa do investimento</p>
                  <TextArea
                    name="justification"
                    onChange={e => {
                      setJustification(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />
                  <p>Descrição do projeto</p>
                  <TextArea
                    name="description_project"
                    onChange={e => {
                      setDescription_project(e.target.value);
                    }}
                    isDisabled={!editPic}
                  />

                  {category === 'Aumento de mão de obra' ? (
                    ''
                  ) : (
                    <>
                      <p>Investimentos (Equipamentos e Área Física)</p>
                      <TextArea
                        name="investment_description"
                        onChange={e => {
                          setInvestment_description(e.target.value);
                        }}
                        isDisabled={!editPic}
                      />
                    </>
                  )}

                  {category === 'Aumento de mão de obra' ? (
                    ''
                  ) : (
                    <>
                      {uploadedFiles.length === 0 && (
                        <>
                          <p>Anexar orçamento</p>
                          <Upload onUpload={submitFile} />
                        </>
                      )}
                      {!!fileList.length && (
                        <>
                          <p>Orçamento anexado</p>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            flexDirection="row"
                          >
                            <Box>
                              <FileListLink files={fileList} />
                            </Box>

                            <Tooltip label="Exluir Orçamento" bg="gray.650">
                              <IconButton
                                onClick={() => handleDeleteFile()}
                                icon={<FiTrash2 fontSize="1.25rem" />}
                                variant="ghost"
                                aria-label="Edit member"
                              />
                            </Tooltip>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel>
                  <Box
                    textAlign="center"
                    borderRadius="6px"
                    bg="gray.100"
                    mb="15px"
                  >
                    <Text fontWeight="bold">Etapa 03</Text>
                  </Box>
                  {category === 'Aumento de mão de obra' ? (
                    <>
                      <p>Salario</p>
                      <Input
                        type="number"
                        name="charges"
                        onChange={e => {
                          setCharges(Number(e.target.value));
                        }}
                        isDisabled={editPic ? !editPic : !editPicPT2}
                      />
                      <p>Outras despesas</p>
                      <Input
                        type="number"
                        name="other_expenses"
                        value={otheExpenses}
                        onChange={e => {
                          setOtherExpenses(Number(e.target.value));
                        }}
                        isDisabled={editPic ? !editPic : !editPicPT2}
                      />
                      <p>Custo total Mês</p>
                      <Input
                        type="number"
                        name="total_cost_month"
                        value={totalCost}
                        onChange={e => {
                          setTotalCost(Number(e.target.value));
                        }}
                        isDisabled={editPic ? !editPic : !editPicPT2}
                      />
                    </>
                  ) : (
                    <>
                      <p>Valor total do investimento</p>
                      <Input
                        type="number"
                        name="value_total"
                        value={value_total}
                        onChange={e => {
                          setValue_total(Number(e.target.value));
                        }}
                        isDisabled={editPic ? !editPic : !editPicPT2}
                      />
                      <p>Retorno do investimento</p>
                      <TextArea
                        name="investment_return"
                        onChange={e => {
                          setInvestment_return(e.target.value);
                        }}
                        isDisabled={!editPic}
                      />
                    </>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>

            <ModalFooter>
              <Box>
                <Button
                  colorScheme="gray"
                  mr={3}
                  onClick={() => handleReturnEstep()}
                  disabled={currentStep === 0}
                >
                  Voltar
                </Button>
                <Button
                  colorScheme="orange"
                  mr={3}
                  onClick={handleSave}
                  disabled={!nextSave}
                >
                  Salvar
                </Button>
                {currentStep !== 2 ? (
                  <Button
                    disabled={!nextLevel2}
                    colorScheme="blue"
                    onClick={() => handleNextEstep()}
                  >
                    Proximo
                  </Button>
                ) : (
                  ''
                )}
                {currentStep === 2 ? (
                  <Button
                    colorScheme="green"
                    type="submit"
                    disabled={userCanAnalystForPic ? false : !nextLevel2}
                    isLoading={loading}
                  >
                    {situation === 'Aguardando análise' ||
                    situation === 'Atualizado pelo Analista'
                      ? 'Atualizar'
                      : situation === 'Preenchimento concluído'
                      ? 'Atualizar'
                      : 'Finalizar'}
                  </Button>
                ) : (
                  ''
                )}
              </Box>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

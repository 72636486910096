/* eslint-disable no-nested-ternary */
import {
  // Avatar,
  Badge,
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  Stack,
  Button,
  Tooltip,
  Collapse,
  CheckboxGroup,
} from '@chakra-ui/react';

import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { FiEdit2, FiTrash2, FiPlus, FiFilter, FiCheck } from 'react-icons/fi';
import { IoArrowDown } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Pagination } from '../../../../../components/_components_0.2/Pagination';
import { FilterActions } from './Filter';
import { usePlans } from '../../../../../hooks/usePlans';
import { useAuth } from '../../../../../hooks/auth';
import { formatPrice } from '../../../../../utils/format';
import { ModalAddInvestment } from '../../../../../components/_components_0.2/Modal/ModalAddInvestment';
import { ModalEditInvestment } from '../../../../../components/_components_0.2/Modal/ModalEditInvestment';
import { api } from '../../../../../services/api';
import { apllyToast } from '../../../../../components/Global/Toast2.0';
import Select from '../../../../../components/Global/SelectRelease';
import { useCan } from '../../../../../hooks/useCan';
import { handleFormatDate } from '../../../../../utils/formarDate';

interface Options {
  value: string;
  label: string;
}

const dataPrioryt = [
  {
    value: 'Aguardando análise',
    label: 'Enviar para análise (Setores de suporte)',
  },
  {
    value: 'PIC inconsistente',
    label: 'PIC inconsistente',
  },
  {
    value: 'PIC Rejeitado',
    label: 'Rejeitar PIC',
  },
  {
    value: 'PIC incompleto',
    label: 'PIC incompleto',
  },
  {
    value: 'PIC Finalizado',
    label: 'Finalizar PIC',
  },
  {
    value: 'PIC Arquivado',
    label: 'Arquivar PIC',
  },
];

export function PlansTable(props: TableProps) {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [sector_filter_id, set_sector_filter_id] = useState('');
  const [responsableData, setResponsableData] = useState<Options[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [investment_id, setInvestment_id] = useState('');
  const [responsible_id, setResponsible_id] = useState('');
  const [category_name, setCategory_name] = useState('');
  const [year, setYear] = useState('');

  const [deletedsInvestments, setDeletedsInvestiments] = useState(true);

  const [pic_repeat, setPic_repeat] = useState(false);

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState('');

  const [lane, setLane] = useState('');

  const [indicatorData, setIndicatorData] = useState<Options[]>([]);
  const [actions, setSctions] = useState('');

  const [investmentSelected, setInvestmentSelected] = useState<string[]>([]);

  const userCanSeeDestructionPic = useCan({
    permissions: ['user_pic_destruction'],
  });
  const userCanSeeRejectPic = useCan({
    permissions: ['user_pic_reject'],
  });
  const userCanSeeNotCompletedPic = useCan({
    permissions: ['user_pic_not_compted'],
  });
  const userCanSeeFinalizedPic = useCan({
    permissions: ['user_pic_finalized'],
  });
  const userCanAnalystForPic = useCan({
    permissions: ['user_pic_analyst'],
  });
  const userCanFilterForPic = useCan({
    permissions: ['user_pic_filters'],
  });

  const handleValidOptActions = useMemo(() => {
    const arrayFormated: any[] = [];
    dataPrioryt.forEach(act => {
      if (act.value === 'PIC inconsistente' && userCanSeeDestructionPic) {
        const data = {
          value: 'PIC inconsistente',
          label: 'PIC inconsistente',
        };
        arrayFormated.push(data);
      }
      if (act.value === 'PIC Rejeitado' && userCanSeeRejectPic) {
        const data = {
          value: 'PIC Rejeitado',
          label: 'Rejeitar PIC',
        };
        arrayFormated.push(data);
      }
      if (act.value === 'PIC Finalizado' && userCanSeeFinalizedPic) {
        const data = {
          value: 'PIC Finalizado',
          label: 'Finalizar PIC',
        };
        arrayFormated.push(data);
      }
      if (act.value === 'PIC incompleto' && userCanSeeNotCompletedPic) {
        const data = {
          value: 'PIC incompleto',
          label: 'PIC incompleto',
        };
        arrayFormated.push(data);
      }
    });
    arrayFormated.push({
      value: 'Aguardando análise',
      label: 'Enviar para análise (Setores de suporte)',
    });
    arrayFormated.push({
      value: 'PIC Arquivado',
      label: 'Arquivar PIC',
    });
    return arrayFormated;
  }, [
    userCanSeeDestructionPic,
    userCanSeeFinalizedPic,
    userCanSeeNotCompletedPic,
    userCanSeeRejectPic,
  ]);

  // useEffect(() => {
  //   set_sector_filter_id(user.sector_id);
  // }, [user]);

  const { data, isLoading, error, isFetching, refetch } = usePlans({
    page,
    setResponsableData,
    investment_id,
    responsible_id,
    lane,
    deletedsInvestments,
    sector_filter_id,
    category_name,
    year,
    pic_repeat,
  });

  // useEffect(() => {
  //   if (userCanAnalystForPic) {
  //     setLane('Aguardando análise');
  //   }
  // }, [userCanAnalystForPic]);

  useEffect(() => {
    // if (!userCanAnalystForPic) {
    set_sector_filter_id(user.sector_id);
    // }
  }, [user.sector_id]);

  // const handleFormatDate = useCallback((date: string) => {
  //   const dataFormadet = format(new Date(date), "dd/MM/yyyy 'as' p");
  //   return dataFormadet;
  // }, []);

  const handleSelectedInvestiment = useCallback(
    (id: string) => {
      const alreadySelected = investmentSelected.findIndex(item => item === id);
      if (alreadySelected >= 0) {
        const filteredItems = investmentSelected.filter(item => item !== id);

        setInvestmentSelected(filteredItems);
      } else {
        setInvestmentSelected([...investmentSelected, id]);
      }
    },
    [investmentSelected],
  );

  const openAction = useCallback(
    (id_plans: string) => {
      setSelectedEdit(id_plans);
      api
        .get('/investments-plans/show', {
          params: {
            id: id_plans,
          },
        })
        .then(response => {
          history.push(`/pic/${id_plans}`, {
            investment: response.data[0],
          });
        });
    },
    [history],
  );

  const handleSendAnalise = useCallback(async () => {
    if (actions === 'PIC Arquivado') {
      await api.put('/investments-plans/all', {
        ids: investmentSelected,
        status: false,
        situation: actions,
      });
      setInvestmentSelected([]);
      refetch();
      apllyToast('info', 'Ação executada!');
      return;
    }
    if (actions !== '') {
      await api.put('/investments-plans/all', {
        ids: investmentSelected,
        situation: actions,
      });
      setInvestmentSelected([]);
      refetch();
      apllyToast('info', 'Ação executada!');
      return;
    }

    apllyToast('error', 'Selecione uma ação!');
  }, [actions, investmentSelected, refetch]);

  function handleVerifyStatusForActions(status: string) {
    if (
      status === 'Aguardando análise' ||
      (status === 'Atualizado pelo Analista' && userCanAnalystForPic)
    ) {
      return false;
    }
    if (status === 'Preenchimento concluído') {
      return false;
    }
    return true;
  }

  const handleTrashInvestment = useCallback(
    (id: string) => {
      try {
        api
          .put(`/investments-plans?id=${id}`, {
            status: false,
          })
          .then(() => {
            apllyToast('info', 'Investimento excluido!');
            refetch();
          });
      } catch (e) {
        console.log('error', e);
      }
    },
    [refetch],
  );

  return (
    <>
      <ModalAddInvestment
        isOpen={openModal}
        onClose={setOpenModal}
        refetch={refetch}
      />
      <ModalEditInvestment
        isOpen={openModalEdit}
        onClose={setOpenModalEdit}
        refetch={refetch}
        investment_id={selectedEdit}
      />
      <Box p={['2', '4']} borderRadius={8} bg="gray.800" mt="6">
        {isLoading ? (
          <Flex justify="center">
            <Spinner />
          </Flex>
        ) : error ? (
          <Flex justify="center">
            <Text>Falha ao obter dados.</Text>
          </Flex>
        ) : (
          <>
            <Box px={{ base: '4', md: '6' }} pt="5">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
              >
                <Flex alignItems="center">
                  <Text fontSize="lg" fontWeight="medium">
                    Plano de investimento
                  </Text>
                  {!isLoading && isFetching && (
                    <Flex ml="2">
                      <Spinner color="gray.500" size="xs" />
                    </Flex>
                  )}
                </Flex>

                <Box display="flex" alignItems="center" justifyContent="row">
                  {/* <InputGroup maxW="xs">
                    <InputLeftElement pointerEvents="none">
                      <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input placeholder="Search" />
                  </InputGroup> */}

                  {userCanFilterForPic && (
                    <Tooltip
                      label="Aplicar filtros a esta tabela"
                      bg="gray.650"
                    >
                      <IconButton
                        ml="5"
                        icon={<FiFilter fontSize="1.25rem" />}
                        aria-label="Filter actions"
                        onClick={() => setIsOpenFilter(!isOpenFilter)}
                      />
                    </Tooltip>
                  )}
                  <Tooltip label="Adicionar nova ação" bg="gray.650">
                    <Button
                      leftIcon={<FiPlus />}
                      onClick={() => setOpenModal(true)}
                      ml="2"
                    >
                      Investimento
                    </Button>
                  </Tooltip>
                </Box>
              </Stack>

              <Collapse
                in={investmentSelected.length > 0 && deletedsInvestments}
                animateOpacity
              >
                <Box display="flex" alignItems="center" mt="1.5rem" mb="1rem">
                  <Form
                    ref={formRef}
                    onSubmit={() => {
                      console.log('e');
                    }}
                  >
                    <Select
                      name="ee"
                      label="Ações"
                      options={handleValidOptActions}
                      // value={actions}
                      onChange={e => {
                        setSctions(e.target.value);
                      }}
                    />
                  </Form>

                  <Button
                    leftIcon={<FiCheck />}
                    colorScheme="blue"
                    onClick={() => handleSendAnalise()}
                    mt="1.5rem"
                    ml="1rem"
                  >
                    Executar ação
                  </Button>
                </Box>
              </Collapse>
            </Box>

            <FilterActions
              isOpenFilter={isOpenFilter}
              setInvestment_id={setInvestment_id}
              investment_id={investment_id}
              setIndicatorData={setIndicatorData}
              indicatorData={indicatorData}
              responsible_id={responsible_id}
              responsableData={responsableData}
              setResponsible_id={setResponsible_id}
              set_sector_filter_id={set_sector_filter_id}
              sector_filter_id={sector_filter_id}
              setYear={setYear}
              year={year}
              setLane={setLane}
              lane={lane}
              setDeletedsInvestiments={setDeletedsInvestiments}
              deletedsInvestments={deletedsInvestments}
              setCategory_name={setCategory_name}
              category_name={category_name}
              setPic_repeat={setPic_repeat}
              pic_repeat={pic_repeat}
            />

            <Box overflowX="auto">
              <Table {...props}>
                <Thead>
                  <Tr>
                    <Th>
                      <HStack spacing="3">
                        {/* <Checkbox onChange={handleSelectedActionAll} /> */}

                        <HStack spacing="1">
                          <Text>Investimento</Text>
                          <Icon as={IoArrowDown} color="muted" boxSize="4" />
                        </HStack>
                      </HStack>
                    </Th>
                    <Th>Prioridade</Th>
                    <Th>Status</Th>
                    <Th>Conta Horizontal</Th>
                    <Th>Valor</Th>
                    <Th>Data do cadastro</Th>
                    <Th>Data de atualização</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.investmentsPlans.map(planInvest => (
                    <Tr key={planInvest.id}>
                      <Td>
                        <HStack spacing="3">
                          <CheckboxGroup defaultValue={investmentSelected}>
                            <Checkbox
                              disabled={handleVerifyStatusForActions(
                                planInvest.situation,
                              )}
                              defaultChecked={investmentSelected.includes(
                                planInvest.id,
                              )}
                              value={planInvest.id}
                              onChange={
                                () => handleSelectedInvestiment(planInvest.id)
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                            />
                          </CheckboxGroup>
                          {/* <Avatar
                            name={planInvest.investment_name}
                            // src={member.avatarUrl}
                            boxSize="10"
                          /> */}
                          {/* {planInvest.status === false && (
                              <Tag colorScheme="purple" size="sm" mt="20">
                                deleted
                              </Tag>
                            )} */}
                          <Box maxW="11.5rem">
                            <Tooltip
                              label={planInvest.investment_name}
                              bg="gray.650"
                            >
                              <Text
                                fontWeight="medium"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {planInvest.investment_name}
                              </Text>
                            </Tooltip>
                          </Box>
                        </HStack>
                      </Td>
                      <Td>
                        <Badge
                          fontSize="0.7em"
                          variant="outline"
                          colorScheme={
                            Number(planInvest.priority) === 3
                              ? 'red'
                              : Number(planInvest.priority) === 2
                              ? 'yellow'
                              : Number(planInvest.priority) === 1
                              ? 'green'
                              : 'blue'
                          }
                        >
                          {Number(planInvest.priority) === 3
                            ? 'Alto'
                            : Number(planInvest.priority) === 2
                            ? 'Médio'
                            : Number(planInvest.priority) === 1
                            ? 'Baixo'
                            : ''}
                        </Badge>
                      </Td>
                      <Td>
                        <Badge
                          colorScheme={
                            planInvest.situation === 'Preenchimento concluído'
                              ? 'blue'
                              : planInvest.situation === 'PIC não finalizado'
                              ? 'red'
                              : planInvest.situation === 'Aguardando análise'
                              ? 'yellow'
                              : planInvest.situation === 'PIC incompleto'
                              ? 'red'
                              : planInvest.situation === 'PIC rejeitado'
                              ? 'red'
                              : planInvest.situation === 'PIC Finalizado'
                              ? 'green'
                              : planInvest.situation ===
                                'Atualizado pelo Analista'
                              ? 'cyan'
                              : 'red'
                          }
                        >
                          <Text color="muted">{planInvest.situation}</Text>
                        </Badge>
                      </Td>
                      <Td>
                        <Text color="muted">{planInvest.category}</Text>
                      </Td>
                      <Td>
                        <Box maxW="6.5rem">
                          <Tooltip
                            label={planInvest.investment_name}
                            bg="gray.650"
                          >
                            <Text
                              color="muted"
                              fontWeight="medium"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {planInvest.category === 'Aumento de mão de obra'
                                ? formatPrice(planInvest.total_cost_month)
                                : formatPrice(planInvest.value_total)}
                            </Text>
                          </Tooltip>
                        </Box>
                      </Td>
                      <Td>
                        <Text color="muted">
                          {handleFormatDate(planInvest.created_at)}
                        </Text>
                      </Td>
                      <Td>
                        <Text color="muted">
                          {handleFormatDate(planInvest.updated_at)}
                        </Text>
                      </Td>
                      <Td>
                        <HStack spacing="1">
                          {/* {!planInvest.status ? (
                            <Tooltip label="Exluir investimento">
                              <IconButton
                                onClick={
                                  () => handleTrashInvestment(planInvest.id)
                                  // eslint-disable-next-line react/jsx-curly-newline
                                }
                                isDisabled={false}
                                icon={<FiTrash2 fontSize="1.25rem" />}
                                variant="ghost"
                                aria-label="Delete member"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip label="Recuperar investimento">
                              <IconButton
                                onClick={() => openAction(planInvest.id)}
                                isDisabled={false}
                                icon={<FiChevronsUp fontSize="1.25rem" />}
                                variant="ghost"
                                aria-label="Delete member"
                              />
                            </Tooltip>
                          )} */}
                          <Tooltip label="Exluir investimento" bg="gray.650">
                            <IconButton
                              onClick={
                                () => handleTrashInvestment(planInvest.id)
                                // eslint-disable-next-line react/jsx-curly-newline
                              }
                              isDisabled={false}
                              icon={<FiTrash2 fontSize="1.25rem" />}
                              variant="ghost"
                              aria-label="Delete member"
                            />
                          </Tooltip>
                          <Tooltip label="Editar investimento" bg="gray.650">
                            <IconButton
                              onClick={() => openAction(planInvest.id)}
                              icon={<FiEdit2 fontSize="1.25rem" />}
                              variant="ghost"
                              aria-label="Edit member"
                              // disabled={
                              //   planInvest.situation ===
                              //   'Aguardando análise'
                              // }
                            />
                          </Tooltip>
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Pagination
              totalCountOfRegisters={data?.pagination.total || 0}
              currentPage={page}
              onPageChange={setPage}
              registersPerPage={data?.pagination.take || 0}
            />
          </>
        )}
      </Box>
    </>
  );
}

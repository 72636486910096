import { useEffect, useState } from 'react';
import { api } from '../services/api';
import { UserInterface } from './auth';

interface UseCanParams {
  flag: string;
  user_id: string;
}

export function useCanFlags({ flag, user_id }: UseCanParams) {
  const [user, setUser] = useState<UserInterface>();
  useEffect(() => {
    try {
      api.get(`/accesses/show?access_id=${user_id}`).then(response => {
        setUser(response.data.access);
        console.log('pegou os dados', response.data.access);
      });
    } catch (err) {
      console.log('error', err);
    }
  }, [user_id]);

  try {
    switch (flag) {
      case 'flag_1': {
        console.log('entrou', user?.flag_1);
        return user?.flag_1;
      }
      case 'flag_2': {
        return user?.flag_2;
      }
      case 'flag_3': {
        return user?.flag_3;
      }
      case 'flag_4': {
        return user?.flag_4;
      }
      case 'flag_5': {
        return user?.flag_5;
      }
      case 'flag_6': {
        return user?.flag_6;
      }
      case 'flag_7': {
        return user?.flag_7;
      }
      case 'flag_8': {
        return user?.flag_8;
      }
      case 'flag_9': {
        return user?.flag_9;
      }
      default: {
        console.log('default');
        return false;
      }
    }
  } catch (err) {
    console.log('error', err);
    console.log('return');
    return false;
  }
}

import { Container, Nav, Ul } from './styles';
import { ListOptions } from './ListOptions';

interface Props {
  handleCapturebookmark: () => void;
  onOpen: () => void;
  handleFullScreen: () => void;
  handlePrint: () => void;
}

export function OptionsViewsBI({
  handleCapturebookmark,
  onOpen,
  handleFullScreen,
  handlePrint,
}: Props) {
  return (
    <Container>
      <Nav>
        <Ul>
          <ListOptions
            onOpen={onOpen}
            handleCapturebookmark={handleCapturebookmark}
            handleFullScreen={handleFullScreen}
            handlePrint={handlePrint}
          />
        </Ul>
      </Nav>
    </Container>
  );
}

// eslint-disable-next-line import/extensions
// import mergeTag from './merge_tag_plugin';

export const optGlobal = {
  rtl: false,
  width: '100%',
  maxWidth: '1000px',
  minWidth: '800px',
  height: 'auto',
  borderRadius: '6px',
  minHeight: '400px',
  katex: 'window.katex',
  videoWidth: '80%',
  videoFileInput: false,
  tabDisable: false,
  // plugins: [mergeTag],
  buttonList: [
    ['undo', 'redo', 'font', 'fontSize', 'formatBlock'],
    [
      'bold',
      'underline',
      'italic',
      'strike',
      'subscript',
      'superscript',
      'removeFormat',
    ],

    [
      'fontColor',
      'hiliteColor',
      'outdent',
      'indent',
      'align',
      'horizontalRule',
      'list',
      'table',
      'lineHeight',
    ],
    // '/',
    [
      'link',
      'image',
      'video',
      'fullScreen',
      'showBlocks',
      'codeView',
      'preview',
      'print',
      'save',
    ],
    // [
    //   {
    //     name: 'merge_tag',
    //     dataCommand: 'merge_tag',
    //     buttonClass: '',
    //     title: 'Merge Tag',
    //     dataDisplay: 'submenu',
    //     innerHTML: '<span>Merge Tag</span>',
    //   },
    // ],
  ],
  // buttonList: [
  //   [
  //     'undo',
  //     'redo',
  //     'font',
  //     'fontSize',
  //     'formatBlock',
  //     'paragraphStyle',
  //     'blockquote',
  //     'bold',
  //     'underline',
  //     'italic',
  //     'strike',
  //     'subscript',
  //     'superscript',
  //     'fontColor',
  //     'hiliteColor',
  //     'textStyle',
  //     'removeFormat',
  //     'outdent',
  //     'indent',
  //     'align',
  //     'horizontalRule',
  //     'list',
  //     'lineHeight',
  //     'table',
  //     'link',
  //     'image',
  //     'video',
  //     'audio',
  //     'math',
  //     'imageGallery',
  //     'fullScreen',
  //     'showBlocks',
  //     'codeView',
  //     'preview',
  //     'print',
  //     'save',
  //     'template',
  //   ],
  // ],
  'lang(In nodejs)': 'pt-BR',
};
export const optRestrict = {
  rtl: false,
  width: '100%',
  // maxWidth: '1000px',
  // minWidth: '1000px',
  height: 'auto',
  borderRadius: '6px',
  minHeight: '400px',
  backgroundColor: 'transparent',
  katex: 'window.katex',
  videoWidth: '80%',
  imageGalleryUrl:
    'https://etyswjpn79.execute-api.ap-northeast-1.amazonaws.com/suneditor-demo',
  videoFileInput: false,
  tabDisable: false,
  imageHeightShow: false,
  imageAlignShow: false,
  imageFileInput: false,
  imageUrlInput: false,
  buttonList: ['image'],

  'lang(In nodejs)': 'pt-BR',
};

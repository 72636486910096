import { useRef, useCallback, useEffect } from 'react';
import { Collapse, Box, Text, Checkbox } from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Select from '../../../../../components/Global/SelectRelease';
import { dataLane } from './data';
import { api } from '../../../../../services/api';

interface Options {
  value: string;
  label: string;
}

interface Indicators {
  id: string;
  name: string;
}

interface Props {
  isOpenFilter: boolean;
  setIndicator_id: (id: string) => void;
  setResponsible_id: (id: string) => void;
  indicator_id: string;
  responsableData: Options[];
  setIndicatorData: (data: Options[]) => void;
  setDeletedsActions: (check: boolean) => void;
  deletedsActions: boolean;
  indicatorData: Options[];
  responsible_id: string;
  setLane: (lane: string) => void;
  lane: string;
}

export function FilterActions({
  isOpenFilter,
  indicatorData,
  setIndicatorData,
  indicator_id,
  setIndicator_id,
  responsible_id,
  responsableData,
  setResponsible_id,
  setLane,
  lane,
  setDeletedsActions,
  deletedsActions,
}: Props) {
  const formRef = useRef<FormHandles>(null);
  const handleReason = useCallback(
    (e: string) => {
      setIndicator_id(e);
    },
    [setIndicator_id],
  );
  const handleReasonResponsable = useCallback(
    (e: string) => {
      setResponsible_id(e);
    },
    [setResponsible_id],
  );
  const handleReasonLane = useCallback(
    (e: string) => {
      setLane(e);
    },
    [setLane],
  );

  useEffect(() => {
    try {
      api.get<Indicators[]>('indicators').then(response => {
        const indicatorsFormated = response.data.map(idct => {
          return {
            label: idct.name,
            value: idct.id,
          };
        });

        setIndicatorData(indicatorsFormated);
      });
    } catch (err) {
      console.log(err);
    }
  }, [setIndicatorData]);

  return (
    <Collapse in={isOpenFilter} animateOpacity>
      <Box p="30px" mt="4" mb="4" bg="gray.900" rounded="md" shadow="md">
        <Text color="gray.500" mb="5">
          Filtro
        </Text>
        <Form ref={formRef} onSubmit={() => console.log('onSunmite')}>
          <Select
            name="lane"
            label="Status"
            value={lane}
            onChange={e => {
              handleReasonLane(e.target.value);
            }}
            options={dataLane}
          />
          <Select
            name="indicator"
            label="Indicador"
            value={indicator_id}
            onChange={e => {
              handleReason(e.target.value);
            }}
            options={indicatorData}
          />
          <Select
            name="responsable"
            label="Responsável"
            value={responsible_id}
            onChange={e => {
              handleReasonResponsable(e.target.value);
            }}
            options={responsableData}
          />

          <Checkbox
            isChecked={!deletedsActions}
            onChange={() => setDeletedsActions(!deletedsActions)}
          >
            Listar as ações excluidas.
          </Checkbox>
        </Form>
      </Box>
    </Collapse>
  );
}

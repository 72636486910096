import { ScaleFade, Flex, Text } from '@chakra-ui/react';
import { HeaderUp } from '../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../components/_components_0.2/Sidebar_0.2';

export function fileManager() {
  return (
    <ScaleFade initialScale={0.9} in>
      <HeaderUp path="bi" />
      <Flex direction="column" h="100vh">
        <Sidebar path="bi" />
        <Flex my="6" maxWidth={1480} mx="auto" pb={4} px="6" textAlign="center">
          <Text>Gerenciador de files</Text>
        </Flex>
      </Flex>
    </ScaleFade>
  );
}

/* eslint-disable no-unused-vars */

import { useCallback, useRef } from 'react';

import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';
import { Form } from '@unform/web';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import { apllyToast } from '../../../Global/Toast2.0';
import { api } from '../../../../services/api';

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  setProcessAdded: (processAdded: string) => void;
}

export default function ModalCreateStrategicPlanning({
  isOpen,
  onClose,
  setProcessAdded,
}: IModalProps) {
  const formRef = useRef<FormHandles>(null);
  // useEffect(() => {}, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          planning: Yup.string().required('Planejamento é obrigatório'),
          year: Yup.number().required('Ano é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { planning, year } = data;

        const formData = {
          planning,
          year,
          status: 'success',
        };
        api.post('/strategic-plans', formData).then(response => {
          setProcessAdded(response.data);
          onClose();
        });
        apllyToast('success', 'Planejamento adicionado com sucesso!');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          apllyToast('error', 'Problemas ao adicionar planejamento');

          formRef.current?.setErrors(errors);
        }
      }
      console.log(data);
    },
    [onClose, setProcessAdded],
  );

  return (
    <ModalComponent
      title="Adicionar novo planejamento"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    >
      {/* {console.log(dataInitialSector)} */}
      <ModalBody>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Text color="gray.50">Planejamento</Text>
          <Input name="planning" />

          <Text color="gray.50">Ano</Text>
          <Input name="year" type="number" />

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Fechar
            </Button>
            <Button colorScheme="blue" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </ModalComponent>
  );
}

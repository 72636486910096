/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useState } from 'react';
import {
  Text,
  Flex,
  Tag,
  SimpleGrid,
  Box,
  ScaleFade,
  Button,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/react';
import { FiShare2 } from 'react-icons/fi';
import { apllyToast } from '../../../../components/Global/Toast2.0';
import { Card } from '../../../../components/_components_0.2/Card';

import { ModalAddNewProcess } from '../../../../components/_components_0.2/Modal/ModalAddNewProcess';
import { ModalEditProcess } from '../../../../components/_components_0.2/Modal/ModalEditProcess';

import { useAuth } from '../../../../hooks/auth';
import { api } from '../../../../services/api';
import { useCan } from '../../../../hooks/useCan';

interface IProcess {
  name: string;
  description: string;
  category: string;
  sector_id: string;
  access_id: string;
}
interface IProcessData {
  id?: string;
  name: string;
  description: string;
  category: string;
  sector_id?: string;
  access_id?: string;
  publish?: boolean;
  contents?: string;
  visibility?: string;
  sector?: {
    name: string;
  };
  user?: {
    name: string;
  };
}

interface ResponseProcess {
  id: string;
}

interface IProps {
  sectorSelected: string;
}

export default function Process({ sectorSelected }: IProps) {
  const [processData, setProcessData] = useState<IProcessData[]>();
  const [update, setUpdate] = useState('');
  // const [updateEdit, setUpdateEdit] = useState('');
  const [process_id, setProcess_id] = useState('');
  const { onClose, isOpen, onOpen } = useDisclosure();

  const [optVisibility, setOptVisibility] = useState('visibleToMe');

  const { user } = useAuth();

  const userCanSeeDataProcess = useCan({
    permissions: ['data_process_manager'],
  });

  async function handleAddNewProcess(data: IProcess) {
    try {
      await api
        .post<ResponseProcess>('/processes-mappings', data)
        .then(reponse => {
          setUpdate(reponse.data.id);
        });
      apllyToast('success', 'Sucesso ao adicionar processo');
    } catch (err) {
      console.log(err);
      apllyToast('error', 'Problemas ao adicionar processo');
    }
  }

  const handleEditProcess = useCallback(async (data: IProcessData) => {
    try {
      await api
        .put<ResponseProcess>(`/processes-mappings?id=${data.id}`, data)
        .then(response => {
          const dat = {
            res: response.data,
            publish: data.publish,
          };
          setUpdate(JSON.stringify(dat));
          apllyToast('success', 'Sucesso ao atualizar processo');
        });
    } catch (err) {
      console.log(err);
      apllyToast('error', 'Problemas ao atualizar processo');
    }
  }, []);

  useEffect(() => {
    try {
      if (user.sector_id !== null) {
        if (userCanSeeDataProcess) {
          if (sectorSelected !== '') {
            api
              .get(`/processes-mappings/show?no_content=${sectorSelected}`)
              .then(response => {
                setProcessData(response.data);
              });
          }
          // else {
          //   api.get(`/processes-mappings`).then(response => {
          //     setProcessData(response.data);
          //   });
          // }
        } else {
          api
            .get(`/processes-mappings/show?sector_id=${user.sector_id}`)
            .then(response => {
              setProcessData(response.data);
            });
        }
      }
    } catch (err) {
      console.log(err);
      apllyToast('error', 'Problemas ao carregar dados');
    }
  }, [sectorSelected, update, user.sector_id, userCanSeeDataProcess]);

  const handleViewerProcess = useCallback(
    (id: any) => {
      setProcess_id(id);
      onOpen();
    },
    [onOpen],
  );

  return (
    <>
      <Flex>
        <ModalAddNewProcess
          handleAddNewProcess={handleAddNewProcess}
          sectorSelected={sectorSelected}
          userCanSeeDataProcess={userCanSeeDataProcess}
          optVisibility={optVisibility}
        />

        <ModalEditProcess
          isOpen={isOpen}
          onClose={onClose}
          handleEditProcess={handleEditProcess}
          process_id={process_id}
          setOptVisibility={setOptVisibility}
          optVisibility={optVisibility}
        />

        <SimpleGrid
          columns={{ base: 2, md: 3, lg: 4 }}
          gap={{ base: '4', md: '6', lg: '8' }}
        >
          {processData?.map(data => (
            <ScaleFade key={data.id} initialScale={0.9} in>
              <Card>
                <Box
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  <Tooltip hasArrow bg="gray.650" label={data.name}>
                    <Button
                      onClick={() => handleViewerProcess(data.id)}
                      mb="10px"
                      _hover={{ color: 'blue.500' }}
                      bg="white"
                    >
                      <Text fontSize="20" fontWeight="semibold">
                        {data.name}
                      </Text>
                    </Button>
                  </Tooltip>
                </Box>
                <Tooltip bg="gray.650" label="Categoria do procedimento">
                  <Tag mb="10px" colorScheme={data.category}>
                    {data.category === 'green'
                      ? 'Verde'
                      : data.category === 'yellow'
                      ? 'Amarelo'
                      : data.category === 'red'
                      ? 'Vermelho'
                      : ''}
                  </Tag>
                </Tooltip>
                <Tooltip
                  bg="gray.650"
                  label="Setor referência a este procedimento"
                >
                  <Tag ml="5px" mb="10px" colorScheme="blue">
                    {data.sector?.name}
                  </Tag>
                </Tooltip>
                {data.publish ? (
                  <Tooltip bg="gray.650" label="Procedimento compartilhado">
                    <Tag ml="5px" mb="10px" colorScheme="purple">
                      <FiShare2 size={15} />
                    </Tag>
                  </Tooltip>
                ) : (
                  ''
                )}

                <Box
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {data.description}
                </Box>
              </Card>
            </ScaleFade>
          ))}
        </SimpleGrid>
      </Flex>
    </>
  );
}

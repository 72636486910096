/* eslint-disable import/extensions */
import React, { useState, useRef } from 'react';

import { Box, Flex, ScaleFade } from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { EvaluationTable } from '../../../../../components/_components_0.2/PerformanceEvaluation/EvaluationTable';
import Select from '../../../../../components/Global/SelectRelease';
import { useCan } from '../../../../../hooks/useCan';

const opt = [
  {
    value: '2023',
    label: '2023',
  },
  {
    value: '2022',
    label: '2022',
  },
  {
    value: '2021',
    label: '2021',
  },
];

const ListOfEvaluation: React.FC = () => {
  const [year, setYear] = useState('2023');
  const formRef = useRef<FormHandles>(null);

  const userCanViewFilter = useCan({
    permissions: ['filter_view'],
  });

  return (
    <>
      {/* <Flex my="6" maxWidth={1480} mx="auto" pb={4} px="6" textAlign="center"> */}
      <ScaleFade initialScale={0.9} in>
        <Box ml="50">
          <Flex
            // pr="145"
            w="100%"
            my="6"
            maxWidth={1180}
            mx="auto"
            pb={4}
            // px="6"
            mt="50px"
            justify="space-between"
          >
            <header>
              <h1>Avaliação de desempenho</h1>
              <Box />
              {/* <Button onClick={save}>Salvar</Button> */}

              <Form
                ref={formRef}
                onSubmit={() => console.log('selecionou um ano')}
              >
                {userCanViewFilter && (
                  <Select
                    name="year"
                    label="Ano da Avaliação"
                    value={year}
                    onChange={e => {
                      setYear(e.target.value);
                    }}
                    options={opt}
                  />
                )}
              </Form>
              {/* <strong>Inicie </strong> */}
            </header>
          </Flex>
          <Flex width="100%" maxWidth={1180} mx="auto" align="center">
            <EvaluationTable year={year} />
          </Flex>
        </Box>
      </ScaleFade>
    </>
  );
};

export default ListOfEvaluation;

import {
  Tooltip,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverCloseButton,
  ButtonGroup,
  PopoverFooter,
  PopoverBody,
  useDisclosure,
  Text,
  PopoverArrow,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { api } from '../../../../services/api';
import Input from '../../../Global/Input';
import TextArea from '../../../Global/TextArea';
import { apllyToast } from '../../../Global/Toast2.0';
import { useAuth } from '../../../../hooks/auth';

interface Props {
  process_mapping_id: string;
  setUpdateNewRevisionHistory: (revision_id: string) => void;
}

export function RevisionHistoryAddPopover({
  process_mapping_id,
  setUpdateNewRevisionHistory,
}: Props) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [dateValue, setDateValue] = useState('');
  const [descriptionValue, setDateDescription] = useState('');
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleSetDate = useCallback(date => {
    setDateValue(date.target.value);
  }, []);
  const handleSetDescription = useCallback(description => {
    setDateDescription(description.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    try {
      const formData = {
        date: dateValue,
        description: descriptionValue,
        process_mapping_id,
        access_id: user.id,
      };
      if (dateValue !== '' && descriptionValue !== '') {
        api.post('/procedures-reviews', formData).then(response => {
          setUpdateNewRevisionHistory(response.data.id);
          apllyToast('success', 'Revisão adicionada com sucesso!');
          setLoading(false);
          onClose();
        });
      } else {
        apllyToast('error', 'Todos os campos são obrigatórios!');
        setLoading(false);
      }
    } catch (e) {
      console.log('error', e);
      apllyToast('error', 'Problemas ao adicionar revisão!');
    }
  }, [
    dateValue,
    descriptionValue,
    onClose,
    process_mapping_id,
    setUpdateNewRevisionHistory,
    user,
  ]);

  return (
    <Tooltip bg="gray.650" label="Adicionar nova tag">
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button size="xs" colorScheme="blue" leftIcon={<FiEdit3 />}>
            Nova revisão
          </Button>
        </PopoverTrigger>
        <PopoverContent p={5} bg="gray.900">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Text>Nova revisão</Text>
            <Input name="data" type="datetime-local" onChange={handleSetDate} />
            <TextArea name="descriptionItem" onChange={handleSetDescription} />

            <PopoverFooter display="flex" justifyContent="flex-end">
              <ButtonGroup size="sm">
                <Button onClick={onClose}>Cancelar</Button>
                <Button
                  colorScheme="blue"
                  onClick={handleSubmit}
                  isLoading={loading}
                  isDisabled={loading}
                >
                  Adicionar
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Tooltip>
  );
}

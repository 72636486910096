/* eslint-disable react/no-unescaped-entities */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import fileSize from 'filesize';
import {
  Button,
  ModalBody,
  ModalFooter,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Box,
  Container,
  Stack,
  VisuallyHidden,
  Text,
  IconButton,
  Checkbox,
} from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiTrash2 } from 'react-icons/fi';
import { MultiSelect } from 'react-multi-select-component';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { Internationalization } from '../../../../utils/InternationalizationMultSelect';
import { useAuth } from '../../../../hooks/auth';
// import { apllyToast } from '../../../Global/Toast2.0';

import Input from '../../../Global/Input';
import Select from '../../../Global/SelectRelease';
import TextArea from '../../../Global/TextArea';
import { ModalComponent } from '..';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';

import { useStep } from './useStep';
import { Step } from './Step';
import { steps } from './data';
import Upload from '../../../Global/Upload';
import FileList from '../../../Global/FileList';
import { Tooltip } from '../../OptionsViewsBI/Tooltip';

interface Props {
  isOpen: boolean;
  onClose: (close: boolean) => void;
  refetch: () => void;
}
interface Options {
  label: string;
  value: string;
}

interface FileProps {
  file: File;
  name: string;
  readableSize: string;
}

interface IOptions {
  value: string;
  label: string;
  identification?: string;
  disabled?: boolean;
  existingData?: boolean;
}

const dataOpt = [
  {
    value: 'Maquinas e equipamentos',
    label: 'Maquinas e equipamentos',
  },
  {
    value: 'Maquinas e equipamentos industriais',
    label: 'Maquinas e equipamentos industriais',
  },
  {
    value: 'Equip. de informatica',
    label: 'Equip. de informatica',
  },
  {
    value: 'Equip. de laboratório',
    label: 'Equip. de laboratório',
  },
  {
    value: 'Equip. de Proteção e segurança',
    label: 'Equip. de Proteção e segurança',
  },
  {
    value: 'Edif. e construções',
    label: 'Edif. e construções',
  },
  {
    value: 'Maquinas e equipamentos de informatica',
    label: 'Maquinas e equipamentos de informatica',
  },
  {
    value: 'Moveis e utensilio',
    label: 'Moveis e utensilio',
  },
  {
    value: 'Instalações',
    label: 'Instalações',
  },
  {
    value: 'Veiculos',
    label: 'Veiculos',
  },
  {
    value: 'Software',
    label: 'Software',
  },
  {
    value: 'Aumento de mão de obra',
    label: 'Aumento de mão de obra',
  },
];
const dataType = [
  {
    value: 'Ordem Legal',
    label: 'Ordem Legal',
  },
  {
    value: 'Ordem de Melhoria de Custos',
    label: 'Ordem de Melhoria de Custos',
  },
  {
    value: 'Ordem de Melhoria de Receitas',
    label: 'Ordem de Melhoria de Receitas',
  },
];
const dataPrioryt = [
  {
    value: '1',
    label: 'Baixa',
  },
  {
    value: '2',
    label: 'Média',
  },
  {
    value: '3',
    label: 'Alta',
  },
];

export function ModalAddInvestment({ onClose, isOpen, refetch }: Props) {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();

  const numberOfSteps = 3;
  const [currentStep, { setStep, goToNextStep, goToPrevStep }] = useStep({
    maxStep: numberOfSteps,
    initialStep: 0,
  });

  const [loading, setLoading] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  const [investment_name, setInvestment_name] = useState('');
  const [branch_id, setBranch_id] = useState('');
  const [sector_id, setSector_id] = useState('');
  const [category, setCategory] = useState('');
  const [responsible_id, setResponsible_id] = useState('');
  const [investment_type, setinvestment_type] = useState('');
  const [prioritydata, setPriority] = useState('1');

  const [labor, setlabor] = useState('');
  const [when, setWhen] = useState('2024-01-01');
  const [justification, setJustification] = useState('');
  const [description_project, setDescription_project] = useState('');
  const [investment_description, setInvestment_description] = useState('');

  const [pic_year] = useState('2024');

  const [value_total, setValue_total] = useState(0);
  const [investment_return, setInvestment_return] = useState('');

  const [dataSectors, setDataSectors] = useState<Options[]>([]);
  const [dataBranch, setDataBranches] = useState<Options[]>([]);
  const [dataResponsable, setDataResponsable] = useState<Options[]>([]);

  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);

  const [picRelated, setPicRelated] = useState(false);
  const [picRepeat, setPicRepeat] = useState(false);
  const [dataInvestments, setDataInvestments] = useState([
    {
      label: '',
      value: '',
      existingData: false,
    },
  ]);
  const [investmentSelected, setInvestmentSelected] = useState<IOptions[]>([]);
  const [totalCost, setTotalCost] = useState(0);
  const [charges, setCharges] = useState(0);
  const [otheExpenses, setOtherExpenses] = useState(0);

  const handleClearData = useCallback(() => {
    try {
      setInvestment_name('');
      setBranch_id('');
      setSector_id('');
      setCategory('');
      setResponsible_id('');
      setinvestment_type('');
      setPriority('');
      setJustification('');
      setDescription_project('');
      setInvestment_description('');
      // setSituation('');
      setWhen('');
      setStep(0);
      setTabIndex(0);
      onClose(false);
      setPicRelated(false);
      setPicRepeat(false);
      setInvestmentSelected([]);
      setTotalCost(0);
      setCharges(0);
      setOtherExpenses(0);
      setDataInvestments([
        {
          label: '',
          value: '',
          existingData: false,
        },
      ]);
      setUploadedFiles([]);
      // setActiveTextAreaCommit(false);
    } catch (err) {
      console.log('error', err);
    }
  }, [onClose, setStep]);

  useEffect(() => {
    const coastActual = charges + otheExpenses;
    setTotalCost(coastActual);
  }, [charges, otheExpenses]);

  useEffect(() => {
    try {
      api
        .get(`investments-plans/show?sector_id=${user.sector_id}`)
        .then(response => {
          const arrayRolesFormated: {
            value: string;
            label: string;
            existingData: boolean;
          }[] = [];
          // Preciso ver como resolver o problema das permissões, estou pensando em colocar aq as pemissões formatadas corretamente
          response.data.forEach(
            (element: { id: string; investment_name: string }) => {
              const goal = {
                label: element.investment_name,
                value: element.id,
                existingData: true,
              };
              arrayRolesFormated.push(goal);
            },
          );

          setDataInvestments(arrayRolesFormated);
        });
    } catch (err) {
      console.log('error', err);
    }
  }, [user.sector_id]);

  useEffect(() => {
    api.get(`/sectors`).then(response => {
      const formatData: { value: string; label: string }[] = [];

      response.data.forEach((data: { id: string; name: string }) => {
        const config = {
          value: data.id,
          label: data.name,
        };

        formatData.push(config);
      });

      setDataSectors(formatData);
    });
  }, []);
  useEffect(() => {
    api.get(`/branches`).then(response => {
      const formatData: { value: string; label: string }[] = [];

      response.data.forEach((data: { id: string; short_name: string }) => {
        const config = {
          value: data.id,
          label: data.short_name,
        };

        formatData.push(config);
      });

      setDataBranches(formatData);
    });
  }, []);
  useEffect(() => {
    api.get(`/accesses`).then(response => {
      const formatData: { value: string; label: string }[] = [];

      response.data.forEach((data: { id: string; name: string }) => {
        const config = {
          value: data.id,
          label: data.name,
        };

        formatData.push(config);
      });

      setDataResponsable(formatData);
    });
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        const dataFile = new FormData();

        if (
          uploadedFiles.length === 0 &&
          category !== 'Aumento de mão de obra'
        ) {
          apllyToast('error', 'Adicione um orçamento!');
          setLoading(false);
          return;
        }

        const file = uploadedFiles[0];
        if (uploadedFiles.length !== 0) {
          dataFile.append('file', file.file, file.name);
        }

        const { ...planinves } = data;

        const formatedData = {
          ...planinves,
          pic_year: 2024,
          investment: 'investment',
          quantity: 0,
          // when,
          situation: 'Preenchimento concluído',
          observation: 'nada',
          priority: prioritydata,
          pic_related: picRelated,
          pic_repeat: picRepeat,
        };

        setLoading(true);
        await api
          .post('/investments-plans', formatedData)
          .then(async response => {
            if (uploadedFiles.length !== 0) {
              await api.patch(
                `/investments-plans-of-files?investment_plan_id=${response.data.id}`,
                dataFile,
              );
            }

            if (picRelated && investmentSelected.length > 0) {
              const formatedLinks = {
                investment_main_id: response.data.id,
                investments_links_ids: investmentSelected.map(inves => {
                  return inves.value;
                }),
              };

              await api.post('investments-plans-links/all', formatedLinks);
            }

            handleClearData();
          });

        apllyToast('success', 'Sucesso ao adicionar novo investimento!');
        refetch();
        setLoading(false);
      } catch (err) {
        apllyToast('error', 'Problemas ao adicionar novo investimento!');
        setLoading(false);
      }
    },
    [
      category,
      handleClearData,
      investmentSelected,
      picRelated,
      picRepeat,
      prioritydata,
      refetch,
      uploadedFiles,
    ],
  );

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      const dataFile = new FormData();
      const data = {
        investment_name,
        branch_id,
        sector_id,
        category,
        pic_year,
        situation: 'PIC não finalizado',
        responsible_id: user.id,
        investment_type,
        priority: prioritydata,
        labor,
        when,
        justification,
        description_project,
        investment_description,
        value_total,
        investment_return,
        pic_related: picRelated,
        pic_repeat: picRepeat,
      };

      const schema = Yup.object().shape({
        investment_name: Yup.string().required(
          'Nome do investimento é obrigatório!',
        ),
        branch_id: Yup.string().required(
          'Filial do investimento é obrigatória!',
        ),
        sector_id: Yup.string().required(
          'Setor do investimento é obrigatório!',
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/investments-plans', data).then(async response => {
        if (uploadedFiles.length > 0) {
          const file = uploadedFiles[0];
          dataFile.append('file', file.file, file.name);
          await api.post(
            `/investments-plans-of-files?investment_plan_id=${response.data.id}`,
            dataFile,
          );

          if (picRelated && investmentSelected.length > 0) {
            const formatedLinks = {
              investment_main_id: response.data.id,
              investments_links_ids: investmentSelected.map(inves => {
                return inves.value;
              }),
            };

            await api.post('investments-plans-links/all', formatedLinks);
          }
        }
        apllyToast('info', 'Investimento salvo!');
        handleClearData();
      });
      refetch();
      setLoading(false);
    } catch (err) {
      apllyToast('error', 'Problemas ao salvar investimento!');
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        apllyToast('error', 'Problemas ao salvar investimento!');
        setLoading(false);

        formRef.current?.setErrors(errors);
      }
    }
  }, [
    investment_name,
    branch_id,
    sector_id,
    category,
    pic_year,
    user.id,
    investment_type,
    prioritydata,
    labor,
    when,
    justification,
    description_project,
    investment_description,
    value_total,
    investment_return,
    picRelated,
    picRepeat,
    refetch,
    uploadedFiles,
    handleClearData,
    investmentSelected,
  ]);

  const handleReason = useCallback(e => {
    setinvestment_type(e);
  }, []);

  const handleNextEstep = useCallback(() => {
    goToNextStep();
    setTabIndex(currentStep + 1);
    console.log(currentStep);
  }, [currentStep, goToNextStep]);
  const handleReturnEstep = useCallback(() => {
    goToPrevStep();
    setTabIndex(currentStep - 1);
    console.log(currentStep);
  }, [currentStep, goToPrevStep]);

  const handleTabsChange = useCallback((index): void => {
    setTabIndex(index);
  }, []);

  const nextLevel2 = useMemo(() => {
    if (currentStep === 0) {
      if (
        investment_name !== '' &&
        branch_id !== '' &&
        sector_id !== '' &&
        category !== '' &&
        responsible_id !== '' &&
        investment_type !== '' &&
        prioritydata !== '' &&
        currentStep === 0
      ) {
        return true;
      }
    }
    if (currentStep === 1) {
      if (
        justification !== '' &&
        description_project !== '' &&
        when !== '' &&
        currentStep === 1
      ) {
        if (category === 'Aumento de mão de obra') {
          if (labor !== '') {
            return true;
          }
          return false;
        }
        if (investment_description !== '') {
          return true;
        }
      }
    }
    if (currentStep === 2) {
      if (category === 'Aumento de mão de obra' && currentStep === 2) {
        return true;
      }
      if (investment_return !== '' && currentStep === 2) {
        return true;
      }
    }

    return false;
  }, [
    investment_name,
    branch_id,
    sector_id,
    category,
    responsible_id,
    investment_type,
    prioritydata,
    currentStep,
    labor,
    when,
    justification,
    description_project,
    investment_description,
    investment_return,
  ]);
  const nextSave = useMemo(() => {
    if (
      investment_name !== '' &&
      pic_year !== '' &&
      branch_id !== '' &&
      sector_id !== ''
    ) {
      return true;
    }
    return false;
  }, [investment_name, branch_id, sector_id, pic_year]);

  const submitFile = useCallback((files: File[]): void => {
    const uploadFiles = files.map(file => ({
      file,
      name: file.name,
      readableSize: fileSize(file.size),
    }));

    setUploadedFiles(uploadFiles);
  }, []);

  return (
    <>
      <ModalComponent
        title="Novo Investimento"
        isOpen={isOpen}
        onClose={handleClearData}
        sizeSpecifyc="3xl"
        scrollBehavior="inside"
      >
        <Box bg="bg.surface">
          <Container py={{ base: '1', md: '2' }} mt="8">
            <Stack spacing="0" direction={{ base: 'column', md: 'row' }}>
              {steps.map((step, id) => (
                <Step
                  // eslint-disable-next-line react/no-array-index-key
                  key={id}
                  cursor="pointer"
                  onClick={() => setStep(id)}
                  title={step.title}
                  description={step.description}
                  isActive={currentStep === id}
                  isCompleted={currentStep > id}
                  isFirstStep={id === 0}
                  isLastStep={steps.length === id + 1}
                />
              ))}
            </Stack>
          </Container>
        </Box>

        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
              <VisuallyHidden>
                <TabList>
                  <Tab>Etapa 01</Tab>
                  <Tab>Etapa 02</Tab>
                  <Tab>Etapa 03</Tab>
                </TabList>
              </VisuallyHidden>
              <TabPanels>
                <TabPanel>
                  <Box
                    textAlign="center"
                    borderRadius="6px"
                    bg="gray.100"
                    mb="15px"
                  >
                    <Text fontWeight="bold">Etapa 01</Text>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    mb="2"
                  >
                    <Checkbox
                      defaultChecked={picRepeat}
                      value="01"
                      onChange={() => setPicRepeat(!picRepeat)}
                    />
                    <Text ml="2">PIC Reicidente?</Text>
                  </Box>
                  <p>Nome do investimento</p>
                  <Input
                    name="investment_name"
                    onChange={e => {
                      setInvestment_name(e.target.value);
                    }}
                  />
                  <Select
                    name="branch_id"
                    label="Filial"
                    options={dataBranch}
                    value={branch_id}
                    onChange={e => {
                      setBranch_id(e.target.value);
                    }}
                  />
                  <Select
                    name="sector_id"
                    label="Setor"
                    options={dataSectors}
                    value={sector_id}
                    onChange={e => {
                      setSector_id(e.target.value);
                    }}
                  />
                  <Select
                    name="category"
                    label="Centro de custo"
                    options={dataOpt}
                    value={category}
                    onChange={e => {
                      setCategory(e.target.value);
                    }}
                  />
                  <Select
                    name="responsible_id"
                    label="Responsável"
                    options={dataResponsable}
                    value={responsible_id}
                    onChange={e => {
                      setResponsible_id(e.target.value);
                    }}
                  />
                  <Select
                    name="investment_type"
                    label="Tipo de investimento"
                    options={dataType}
                    value={investment_type}
                    onChange={e => {
                      handleReason(e.target.value);
                    }}
                  />
                  <Select
                    name="priority"
                    label="Nivel de prioridade"
                    options={dataPrioryt}
                    value={prioritydata}
                    onChange={e => {
                      setPriority(e.target.value);
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <Box
                    textAlign="center"
                    borderRadius="6px"
                    bg="gray.100"
                    mb="15px"
                  >
                    <Text fontWeight="bold">Etapa 02</Text>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    mb="2"
                  >
                    <Checkbox
                      value="01"
                      onChange={() => setPicRelated(!picRelated)}
                    />
                    <Text ml="2">Possui PIC relacionado?</Text>
                  </Box>
                  {picRelated && (
                    <>
                      <p>Selecione PIC's para relacionamento</p>
                      <MultiSelect
                        options={dataInvestments}
                        value={investmentSelected}
                        valueRenderer={selected => {
                          return selected.length
                            ? selected.map(({ label }) => `${label}; `)
                            : 'Nenhum PIC selecionado';
                        }}
                        onChange={setInvestmentSelected}
                        labelledBy="Selecione"
                        overrideStrings={Internationalization}
                      />
                    </>
                  )}
                  {category === 'Aumento de mão de obra' ? (
                    <>
                      <p>Função</p>
                      <Input
                        name="labor"
                        onChange={e => {
                          setlabor(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    ''
                  )}

                  <p>Previsão de inicio/efetivação do projeto</p>
                  <Input
                    name="when"
                    type="date"
                    onChange={e => {
                      setWhen(e.target.value);
                    }}
                  />
                  <p>Justificativa do investimento</p>
                  <TextArea
                    name="justification"
                    onChange={e => {
                      setJustification(e.target.value);
                    }}
                  />
                  <p>Descrição do projeto</p>
                  <TextArea
                    name="description_project"
                    onChange={e => {
                      setDescription_project(e.target.value);
                    }}
                  />
                  <p>Investimentos (Equipamentos e Área Física)</p>
                  <TextArea
                    name="investment_description"
                    onChange={e => {
                      setInvestment_description(e.target.value);
                    }}
                  />
                  {category === 'Aumento de mão de obra' ? (
                    ''
                  ) : (
                    <>
                      {uploadedFiles.length === 0 && (
                        <>
                          <p>Anexar orçamento</p>
                          <Upload onUpload={submitFile} />
                        </>
                      )}
                      {!!uploadedFiles.length && (
                        <>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            flexDirection="row"
                          >
                            <Box>
                              <p>Orçamento anexado</p>
                              <FileList files={uploadedFiles} />
                            </Box>

                            <Tooltip label="Exluir Orçamento">
                              <IconButton
                                onClick={() => setUploadedFiles([])}
                                icon={<FiTrash2 fontSize="1.25rem" />}
                                variant="ghost"
                                aria-label="Edit member"
                              />
                            </Tooltip>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel>
                  <Box
                    textAlign="center"
                    borderRadius="6px"
                    bg="gray.100"
                    mb="15px"
                  >
                    <Text fontWeight="bold">Etapa 03</Text>
                  </Box>
                  {category === 'Aumento de mão de obra' ? (
                    <>
                      <p>Encargos</p>
                      <Input
                        type="number"
                        name="charges"
                        value={charges}
                        onChange={e => {
                          setCharges(Number(e.target.value));
                        }}
                      />
                      <p>Outras despesas</p>
                      <Input
                        type="number"
                        name="other_expenses"
                        value={otheExpenses}
                        onChange={e => {
                          setOtherExpenses(Number(e.target.value));
                        }}
                      />
                      <p>Custo total Mês</p>
                      <Input
                        type="number"
                        name="total_cost_month"
                        value={totalCost}
                        onChange={e => {
                          setTotalCost(Number(e.target.value));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <p>Valor total do investimento</p>
                      <Input
                        type="number"
                        name="value_total"
                        value={value_total}
                        onChange={e => {
                          setValue_total(Number(e.target.value));
                        }}
                      />
                      <p>Retorno do investimento</p>
                      <TextArea
                        name="investment_return"
                        onChange={e => {
                          setInvestment_return(e.target.value);
                        }}
                      />
                    </>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>

            <ModalFooter>
              <Box>
                <Button
                  colorScheme="gray"
                  mr={3}
                  onClick={() => handleReturnEstep()}
                  disabled={currentStep === 0}
                >
                  Voltar
                </Button>
                <Button
                  colorScheme="orange"
                  mr={3}
                  onClick={handleSave}
                  disabled={!nextSave}
                  isLoading={loading}
                >
                  Salvar
                </Button>
                {currentStep !== 2 ? (
                  <Button
                    disabled={!nextLevel2}
                    colorScheme="blue"
                    onClick={() => handleNextEstep()}
                  >
                    Proximo
                  </Button>
                ) : (
                  ''
                )}
                {currentStep === 2 ? (
                  <Button
                    colorScheme="green"
                    type="submit"
                    disabled={!nextLevel2}
                    isLoading={loading}
                  >
                    Finalizar
                  </Button>
                ) : (
                  ''
                )}
              </Box>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

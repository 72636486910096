import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  PopoverFooter,
} from '@chakra-ui/react';

import { useCallback, useEffect, useState } from 'react';
import { FiRepeat } from 'react-icons/fi';
import { api } from '../../../../services/api';
import Select from '../../../Global/SelectRelease';

interface Props {
  handleUpdateResponsable: (user_id: string) => void;
  setOpenPopover: (openPopover: boolean) => void;
  openPopover: boolean;
}

interface UserLsit {
  id: string;
  name: string;
}

interface Options {
  value: string;
  label: string;
}

export function PopoverAddReplace({
  handleUpdateResponsable,
  setOpenPopover,
  openPopover,
}: Props) {
  const [listResponsable, setListResponsable] = useState<Options[]>([]);
  const [responsableSelected, setResponsableSelected] = useState('');

  useEffect(() => {
    try {
      api.get<UserLsit[]>('/accesses').then(response => {
        const dataFormated = response.data.map(responsable => {
          return {
            value: responsable.id,
            label: responsable.name,
          };
        });

        setListResponsable(dataFormated);
      });
    } catch (e) {
      console.log('error', e);
    }
  }, []);

  const handleReason = useCallback((e: string) => {
    setResponsableSelected(e);
  }, []);

  return (
    <Popover onClose={() => setOpenPopover(!openPopover)} isOpen={openPopover}>
      <PopoverTrigger>
        <Button
          onClick={() => setOpenPopover(!openPopover)}
          colorScheme="blue"
          size="sm"
          leftIcon={<FiRepeat />}
        >
          Substituir
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="gray.700">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Subistitur responsável</PopoverHeader>

        <PopoverBody>
          <Select
            label=""
            name="responsable"
            options={listResponsable}
            value={responsableSelected}
            onChange={e => {
              handleReason(e.target.value);
            }}
          />

          <Button
            colorScheme="blue"
            size="lg"
            onClick={() => handleUpdateResponsable(responsableSelected)}
          >
            Salvar
          </Button>
          <PopoverFooter />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

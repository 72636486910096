import { useQuery } from 'react-query';
import { formatedDataActionsAddLaneTitle } from '../pages/Dashboard/StrategicPlanning/trlo/formatedData';
import { api } from '../services/api';

// interface Actions {
//   indicatorsActions: [
//     {
//       id: string;
//       title: string;
//       indicator: {
//         name: string;
//       };
//       responsible: {
//         name: string;
//       };
//       status: boolean;
//       end_date: string;
//     },
//   ];
//   pagination: {
//     page: number;
//     take: number;
//     total: number;
//     totalPages: number;
//   };
// }

interface Options {
  value: string;
  label: string;
}

interface Props {
  page: number;
  setResponsableData: (data: Options[]) => void;
  indicator_id: string;
  responsible_id: string;
  lane: string;
  deletedsActions: boolean;
}

export function useActions({
  page,
  setResponsableData,
  indicator_id,
  responsible_id,
  lane,
  deletedsActions,
}: Props) {
  return useQuery(
    ['actions', page, indicator_id, responsible_id, lane, deletedsActions],
    async () => {
      const response = await api('indicators-actions/filter', {
        params: {
          take: 20,
          page,
          lane,
          indicator_id,
          responsible_id,
          status: deletedsActions,
        },
      });
      const resp = await api('/accesses');

      console.log('response', response.data);

      const responsableFormated: Options[] = resp.data.map(
        (act: { id: string; name: string }) => {
          return {
            value: act.id,
            label: act.name,
          };
        },
      );

      // const parsed_array = responsableFormated.map(val => {
      //   return JSON.stringify(val);
      // });
      // const filtered_array = parsed_array
      //   .filter((value, ind) => parsed_array.indexOf(value) === ind)
      //   .map(val => {
      //     return JSON.parse(val);
      //   });

      setResponsableData(responsableFormated);

      const res = formatedDataActionsAddLaneTitle(
        response.data.indicatorsActions,
      );
      const formated = {
        indicatorsActions: res,
        pagination: response.data.pagination,
      };
      // const res: Actions = response.data;
      return formated;
    },
  );
}

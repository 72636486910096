import { Tag, Flex, Button, Text, Tooltip } from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';

interface IOptions {
  handleDeleteRole: (role: string) => void;

  value: string;
  label: string;
}

export function ListRoles({ value, label, handleDeleteRole }: IOptions) {
  return (
    <Tag key={value} colorScheme="blue" size="md" mr="1" mb="3">
      <Flex p="1" alignItems="center">
        <Text>{label}</Text>
        <Tooltip label="Excluir função">
          <Button
            colorScheme="blue"
            size="xs"
            onClick={() => handleDeleteRole(value)}
            ml="2"
          >
            <FiX />
          </Button>
        </Tooltip>
      </Flex>
    </Tag>
  );
}

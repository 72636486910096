import { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
} from '@chakra-ui/react';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  handleFunction: () => void;
  title: string;
  textBody: string;
  ButonSave: string;
  ButonClose: string;
}

export function Alert({
  isOpen,
  onClose,
  handleFunction,
  title,
  textBody,
  ButonSave,
  ButonClose,
}: Props) {
  const cancelRef = useRef(null);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>

          <AlertDialogCloseButton />
          <AlertDialogBody>{textBody}</AlertDialogBody>
          <AlertDialogFooter>
            {ButonClose !== '' ? (
              <Button ref={cancelRef} onClick={onClose}>
                {ButonClose}
              </Button>
            ) : (
              ''
            )}
            {ButonSave !== '' ? (
              <Button colorScheme="red" ml={3} onClick={handleFunction}>
                {/* Sim */}
                {ButonSave}
              </Button>
            ) : (
              ''
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

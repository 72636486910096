/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  Flex,
  Box,

  Text,

} from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';


import { v4 as uuid } from 'uuid';
import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';
import Select from '../../../Global/SelectRelease';
import { useAuth } from '../../../../hooks/auth';

interface Props {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  refetch: () => void;
}
interface Options {
  value: string;
  label: string;
}
interface Indicators {
  id: string;
  name: string;
}

export default function ModalAddAction({
  onClose,
  isOpen,
  refetch
}: Props) {
  const formRef = useRef<FormHandles>(null);
  const editor = useRef<SunEditorCore>();
  const { user } = useAuth()
  const [loading, setLoading] = useState(false);
  const [indicatorData, setIndicatorData] = useState<Options[]>([])
  const [indicatorSelected, setIndicatorSelected] = useState('')

  const getSunEditorInstance = useCallback((sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  }, []);

  useEffect(() => {
    try {
      api.get<Indicators[]>('/indicators').then((response) => {
        const indicatorsFormated = response.data.map(indicator => {
          return {
            value: indicator.id,
            label: indicator.name
          }
        })

        setIndicatorData(indicatorsFormated !== [] ? indicatorsFormated : [
        {
          label: '',
          value: ''
        },
        ]
        )

      })
    } catch (err) {
       console.log('error', err)
    }
  }, [])

  const handleSubmit = useCallback((data) => {
    try {
      setLoading(true)
      const { title, indicator, end_date, start_date } = data;
      const formatedData = {
        id: uuid(),
        indicator_id: indicator,
        title,
        label: 1,
        lane: 'lane1',
        start_date: end_date,
        end_date: start_date,
        responsible_id: user.id,
        description: editor.current?.getText(),
        contents: editor.current?.getContents(true),
      };
      api.post('/indicators-actions', formatedData).then(() => {
        refetch()
        apllyToast('success', 'Sucesso ao adicionar ação!');
        onClose(false)
        setLoading(false)
      });

  } catch (err) {
    console.log(err);
    setLoading(false)
    apllyToast('error', 'Problemas ao adicionar ação!');
  }
    },
    [onClose, user.id, refetch],
  );

  const handleReason = useCallback((e: string) => {
    setIndicatorSelected(e);
  }, []);

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        onClose={() => onClose(!isOpen)}
        scrollBehavior="inside"
        sizeSpecifyc="3xl"
        title="Adicionar nova ação"
      >
        <ModalBody>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <Box>
              <Text>Nome da ação</Text>
              <Input
                    // value={dataActionsOpened.start_date}
                variant="outline"
                size="md"
                name="title"
              />
            </Box>
            <Flex
              flexDirection="column"
              bg="gray.800"
              p="2"
              borderRadius="6px"
              mt="2"
            >

              <Flex flexDirection="row">
                <Box>
                  <Text>Inicio</Text>
                  <Input
                    // value={dataActionsOpened.start_date}
                    variant="unstyled"
                    size="md"
                    type="date"
                    name="start_date"
                  />
                </Box>
                <Box>
                  <Text>Fim</Text>
                  <Input
                    // value={dataActionsOpened.end_date}
                    variant="unstyled"
                    size="md"
                    type="date"
                    name="end_date"
                  />
                </Box>
              </Flex>
            </Flex>

            <Select
              name="indicator"
              label="Indicador"
              value={indicatorSelected}
              onChange={e => {
                handleReason(e.target.value);
              }}
              options={indicatorData}
            />
            <Flex flexDirection="column" mt="4">
              <p>Descrição</p>
              <SunEditor
                setDefaultStyle="font-family:'Roboto', sans-serif; min-height: 220px"
                getSunEditorInstance={getSunEditorInstance}
                // setOptions={optRestrict}
                // // setContents={dataProcess?.contents}
                // hideToolbar={false}
              />
            </Flex>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => onClose(!isOpen)}>
                Fechar
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={loading}
                loadingText="Salvando"
              >
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

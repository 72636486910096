/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable import/extensions */
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { BodyFile } from './bodyFile';

export function File() {
  return (
    <PDFViewer>
      <BodyFile />
    </PDFViewer>
  );
}

export function Save({ user }) {
  return (
    <div>
      <PDFDownloadLink
        document={<BodyFile user={user} />}
        fileName="somename.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? 'Carregando documento...' : 'Download!'
        }
      </PDFDownloadLink>
    </div>
  );
}

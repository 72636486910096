/* eslint-disable no-self-compare */
/* eslint-disable no-constant-condition */
import {
  Box,
  Button,
  ScaleFade,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { FiBarChart, FiTool } from 'react-icons/fi';
// import { SearchBox } from '../../../../components/_components_0.2/Search/SearchBox';
import { ModalAddNewAnalyisBI } from '../../../../components/_components_0.2/Modal/ModalAddNewAnalysisBI';
import { ModalAddNewConfigBI } from '../../../../components/_components_0.2/Modal/ModalAddNewConfigBi';
import { Reports } from './Reports';
import { ConfigPBI } from './ConfigPBI';
import { CardButton, CardeHeader, Container } from './styles';
import { Group } from './Group';
import { apllyToast } from '../../../../components/Global/Toast2.0';
import { api } from '../../../../services/api';
import { HeaderUp } from '../../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../../components/_components_0.2/Sidebar_0.2';

interface DataReportsFormated {
  groupNameSamasc: string;
  reports: {
    report: {
      id: string;
      reportName: string;
    };

    permissions: string[];
    roles?: string[];
  }[];
}

interface DataConfig {
  id: string;
  authenticationMode: string;
  authorityUri: string;
  scope: string;
  apiUrl: string;
  clientId: string;
  workspaceId: string;
  reportId: string;
  pbiUsername: string;
  pbiPassword: string;
  clientSecret: string;
  tenantId: string;
}

interface DataPermission {
  power_bi_report_id: string;
  permission_identification: string;
}

export default function BusinessIntelligenceManagement() {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: OpenConfig,
    isOpen: isOpenConfig,
    onClose: onCloseConfig,
  } = useDisclosure();
  const [dataReports, setDataReports] = useState<DataReportsFormated[]>();
  const [dataConfig, setDataConfig] = useState<DataConfig[]>();
  const [deletedPermission, setDeletedPermission] = useState<DataConfig[]>();

  useEffect(() => {
    try {
      api.get('/power-bi-reports').then(response => {
        setDataReports(response.data);
      });
    } catch (e) {
      apllyToast('error', 'Problemas ao consultar banco de dados');
    }
  }, [deletedPermission]);

  useEffect(() => {
    api.get(`/power-bi-configs`).then(response => {
      setDataConfig(response.data);
    });
  }, []);

  const toggleModal = useCallback(
    (modalRef: string) => {
      if (modalRef === 'config') {
        OpenConfig();
      } else {
        onOpen();
      }
    },
    [onOpen, OpenConfig],
  );

  const handleDeletePermission = useCallback(
    (dataPermission: DataPermission) => {
      try {
        api
          .delete(
            `/power-bi-reports-of-permissions?power_bi_report_id=${dataPermission.power_bi_report_id}&permission_identification=${dataPermission.permission_identification}`,
          )
          .then(response => {
            setDeletedPermission(response.data);
            apllyToast('success', 'Permissão exluida com sucesso');
          });
      } catch (err) {
        console.log('error', err);
        apllyToast('error', 'Problemas ao consultar banco de dados');
      }
    },
    [],
  );
  return (
    <>
      <HeaderUp path="adm" />

      <Flex w="100%" maxWidth={1480} mx="auto" pb={4} px="6">
        <Sidebar path="adm" />
        <ScaleFade initialScale={0.9} in>
          <ModalAddNewAnalyisBI isOpen={isOpen} onClose={onClose} />
          <ModalAddNewConfigBI isOpen={isOpenConfig} onClose={onCloseConfig} />

          <Container>
            <CardeHeader>
              <div>
                <h2>Acessos</h2>
                <strong>Gererencie seus grupos de análise</strong>
              </div>

              <CardButton>
                <Box alignItems="center" display="flex">
                  {/* <SearchBox /> */}
                </Box>
                <Button
                  leftIcon={<FiBarChart />}
                  onClick={() => toggleModal('analise')}
                  type="button"
                  colorScheme="blue"
                  color="white"
                  mr="2"
                >
                  Adicionar nova análise
                </Button>
                <Button
                  leftIcon={<FiTool />}
                  onClick={() => toggleModal('config')}
                  type="button"
                  colorScheme="blue"
                  color="white"
                >
                  Adicionar nova config. de BI
                </Button>
              </CardButton>
            </CardeHeader>

            <Tabs variant="enclosed">
              <TabList>
                <Tab>Painés de análises</Tab>
                <Tab>Configurações de PBI</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {dataReports?.map(group => (
                    <Group
                      key={group.groupNameSamasc}
                      label={group.groupNameSamasc}
                    >
                      {group.reports.map(report => (
                        <Reports
                          key={report.report.id}
                          status
                          label={report.report.reportName}
                          permission={report.permissions}
                          handleDeletePermission={handleDeletePermission}
                          power_bi_report_id={report.report.id}
                        />
                      ))}
                    </Group>
                  ))}
                </TabPanel>
                <TabPanel>
                  {dataConfig?.map(data => (
                    <ConfigPBI
                      authenticationMode={data.authenticationMode}
                      authorityUri={data.authorityUri}
                      scope={data.scope}
                      apiUrl={data.apiUrl}
                      clientId={data.clientId}
                      workspaceId={data.workspaceId}
                      reportId={data.reportId}
                      pbiUsername={data.pbiUsername}
                      pbiPassword={data.pbiPassword}
                      clientSecret={data.clientSecret}
                      tenantId={data.tenantId}
                      key={data.id}
                    />
                  ))}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Container>
        </ScaleFade>
      </Flex>
    </>
  );
}

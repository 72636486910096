import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';

// Routes user ADM
import Dashboard from '../pages/Dashboard';
import DashboardPPR from '../pages/Dashboard/ppr_management/Dashboard';

// ADMINISTRATOR
import BusinessIntelligenceManagement from '../pages/Dashboard/Administration/Business_intelligence';
import Vehicles from '../pages/Dashboard/Administration/Vehicles';
import Employers from '../pages/Dashboard/Administration/Employers';
import EmployersEdit from '../pages/Dashboard/Administration/Employers/EditProfile';
import { PermissionsAndRolesManager } from '../pages/Dashboard/Administration/PermissionsAndRoles';
// Unidades de medidas
import { Automations } from '../pages/Dashboard/Administration/Automations';

// PPR
import SelectorFolders from '../pages/Dashboard/ppr_management/GoalsSubgoals';
import Email from '../pages/Dashboard/ppr_management/Email';
import Report from '../pages/Dashboard/ppr_management/Report';
import IcomeStatement from '../pages/Dashboard/BIManagement/IncomeStatement';

import PainelSatisfactionSurveyResult from '../pages/Dashboard/ppr_management/Report/PainelSatisfactionSurveyResult';

import Sector from '../pages/Dashboard/ppr_management/Sector';
import SectorSelected from '../pages/Dashboard/ppr_management/Sector/SelectedSector';
import SectorResume from '../pages/Dashboard/ppr_management/Sector/SectorResume';
import AnalyticModuloAdm from '../pages/Dashboard/ppr_management/AnalyticModule';
import PainelAnalyticModulo from '../pages/Dashboard/ppr_management/PainelAnalyticModule';

import PerformanceEvaluation from '../pages/Dashboard/ppr_management/PerformanceEvaluation';
import ListOfEvaluation from '../pages/Dashboard/ppr_management/PerformanceEvaluation/Evaluations/ListOfEvaluation';
import EvaluationResume from '../pages/Dashboard/ppr_management/PerformanceEvaluation/Evaluations/EvaluationResume';

import PainelSatisfactionSurvey from '../pages/AcessGlobal/PainelSatisfactionSurvey';

import SatisfactionSurvey from '../pages/AcessGlobal/SatisfactionSurvey';

import Import from '../pages/Dashboard/ppr_management/Imports';
import Schedule from '../pages/Dashboard/ppr_management/Schedule';
import ScheduleOnly from '../pages/Dashboard/ppr_management/Schedule/ScheduleUnic';

// Miles
import DashboardMiles from '../pages/Dashboard/vehicles_management/Dashboard';
import VehiclesSchedule from '../pages/Dashboard/vehicles_management/Schedule';
import Kilometers from '../pages/Dashboard/vehicles_management/Kilometers';
import KilometersVelho from '../pages/Dashboard/Miles-velho/YouMiles';
import VehiclesSupply from '../pages/Dashboard/vehicles_management/Supply';
import VehiclesMaintenance from '../pages/Dashboard/vehicles_management/Maintenance';
import FastQuilometer from '../pages/Dashboard/vehicles_management/FastQuilometer';
import FormFastQuilometer from '../pages/Dashboard/vehicles_management/FastQuilometer/FormFastQuilometer';

// Service order
import FormsOrderService from '../components/Admin/FormsOrderService';
import ServicesOrders from '../pages/Dashboard/service_orders/UserServiceOrders';
import PainelServicesOrders from '../pages/Dashboard/service_orders/PainelServiceOrders';
import ServiceIntegrity from '../pages/Dashboard/service_orders/ServiceIntegrity';
import Monitoring from '../pages/Dashboard/service_orders/Monitoring';
import DashboardOS from '../pages/Dashboard/service_orders/Dashboard';

// Service order Fiscal
import ServicesOrdersFiscal from '../pages/Dashboard/service_orders_fiscal/UserServiceOrdersFiscal';
import PainelServicesOrdersFiscal from '../pages/Dashboard/service_orders_fiscal/PainelServiceOrdersFiscal';

// import SelectedNic from '../pages/Dashboard/Rules/SelectedNic';

import { PanelOfStandardsAndManuals } from '../pages/Dashboard/PanelOfStandardsAndManuals';
import { SelectedProces } from '../pages/Dashboard/PanelOfStandardsAndManuals/SelectedProcess/Selected';
import Visio from '../pages/Dashboard/Rules/Visio';

// BI
import BIManagementTest from '../components/Admin/Reports/ReportConectBI';
import BIManagement from '../pages/Dashboard/BIManagement';
import BIFleet from '../pages/Dashboard/BIManagement/fleetBi';
import BIOrders from '../pages/Dashboard/BIManagement/ordersBi';
import PBIRenderization from '../pages/Dashboard/BIManagement/PBIRenderization';
// import BPPRS from '../pages/Dashboard/BIManagement/pprBi';

// Profile
import Profile from '../pages/Dashboard/_Profile';

// Energy
import EnergyManagement from '../pages/Dashboard/EnergyManagement/Management';
import EnergyTable from '../pages/Dashboard/EnergyManagement/Management/EnergyTable';
import BIEnergY from '../pages/Dashboard/BIManagement/energiBi';

// ClimateResearchManagement
import ClimateResearchManagement from '../pages/Dashboard/ClimateResearchManagement';

// Process
import ProcessManagement from '../pages/Dashboard/ProcessManagement';

// Gerenciador de arquivos
import { fileManager } from '../pages/Dashboard/FileManagement';

// Agenda cooasgo
import { ScheduleList } from '../pages/Dashboard/Schedule';

// Planejamento estratégico
import { StrategicPlanning } from '../pages/Dashboard/StrategicPlanning';
import { ListPrimatyIndicator } from '../pages/Dashboard/StrategicPlanning/ListPrimaryIndicator';

// ia
import ia from '../pages/Dashboard/ArtificialInteligence';

// PLano de investimento
import pic from '../pages/Dashboard/Pic';

import Error404 from '../pages/error/404';

import { Test } from '../pages/Dashboard/Test';

const Routes: React.FC = () => (
  <Switch>
    <Route requirePermission="nada" path="/" exact component={SignIn} />

    <Route path="/profile" component={Profile} />
    <Route
      path="/painel-module-satisfaction/:analyticId?"
      component={PainelSatisfactionSurvey}
      isGlobal
    />
    <Route path="/satisfactionSurvey" component={SatisfactionSurvey} isGlobal />

    <Route path="/forms-order-service" component={FormsOrderService} isGlobal />

    <Route path="/management-ppr/Dashboard" component={DashboardPPR} />

    <Route path="/menu" component={Dashboard} />

    <Route path="/management-ppr/goals-subgoals" component={SelectorFolders} />
    <Route path="/e-mail" component={Email} requirePermission="file_manager" />
    <Route path="/management-ppr/report" component={Report} isUser />

    <Route
      path="/management-ppr/report-income-statement"
      component={IcomeStatement}
      isUser
    />
    <Route
      path="/management-ppr/report-satisfaction-result"
      component={PainelSatisfactionSurveyResult}
      isUser
    />
    <Route path="/management-ppr/sector" component={Sector} />

    <Route
      path="/management-ppr/sector-resume/:sectorId?"
      component={SectorResume}
    />
    <Route
      path="/management-ppr/sector-selected/:sectorId?"
      component={SectorSelected}
    />

    {/* <Route
      path="/rules/sector-resume-rules/:nicId?"
      component={SelectedNic}
      requirePermission="file_manager"

    /> */}
    <Route path="/rules/resume-rules/:nicId?" component={SelectedProces} />
    <Route path="/management-ppr/import" component={Import} />
    <Route path="/management-ppr/schedule" component={Schedule} />
    <Route
      path="/management-ppr/schedule-only:name_schedule?"
      component={ScheduleOnly}
    />
    <Route
      path="/management-ppr/analyticModule"
      component={AnalyticModuloAdm}
    />
    {/* <Route
      path="/management-ppr/performance-evaluation/:nick_user"
      component={PerformanceEvaluation}
    /> */}
    {/* <Route
      path="/management-ppr/listOf-evaluation"
      component={ListOfEvaluation}
    /> */}
    {/* <Route
      path="/management-ppr/evaluation-resume/:name_subordinate/:id_hierarchies"
      component={EvaluationResume}
      exact
    /> */}

    <Route
      path="/administrator/employers"
      component={Employers}
      requirePermission="user_adm"
    />
    <Route
      path="/administrator/set-data-user"
      requirePermission="user_adm"
      component={EmployersEdit}
    />

    <Route
      path="/administrator/vehicles"
      requirePermission="user_adm"
      component={Vehicles}
    />
    <Route
      path="/administrator/permissions-and-roles"
      component={PermissionsAndRolesManager}
      requirePermission="user_adm"
    />
    <Route
      path="/administrator/automations"
      component={Automations}
      requirePermission="user_adm"
    />

    <Route path="/service-orders-ti/user" component={ServicesOrders} />

    <Route path="/service-orders-ti/painel" component={PainelServicesOrders} />

    <Route
      path="/service-orders-fiscal/user"
      component={ServicesOrdersFiscal}
    />

    <Route
      path="/service-orders-fiscal/painel"
      component={PainelServicesOrdersFiscal}
    />

    <Route
      path="/service-orders/service-integrity"
      component={ServiceIntegrity}
    />

    <Route path="/service-orders/monitoring" component={Monitoring} />

    <Route path="/service-orders/menu-os" component={DashboardOS} />

    <Route path="/miles/dashboard" component={DashboardMiles} />

    <Route path="/miles/schedule" component={VehiclesSchedule} />
    <Route path="/miles/kilometers" component={Kilometers} />
    <Route path="/miles/kilometers-velho" component={KilometersVelho} />
    <Route path="/miles/supply" component={VehiclesSupply} />
    <Route path="/miles/maintenance" component={VehiclesMaintenance} />

    <Route path="/miles/fast-quilometer" component={FastQuilometer} />
    <Route
      path="/miles/formFastQuilometer"
      component={FormFastQuilometer}
      exact
    />

    <Route
      path="/management-ppr/painel-module-analytical/:analyticId?"
      component={PainelAnalyticModulo}
      isUser
    />

    <Route path="/rules" component={PanelOfStandardsAndManuals} isUser />

    <Route path="/visio" component={Visio} isUser />

    {/* BI */}
    <Route path="/bi-management/test" component={BIManagementTest} />

    <Route path="/bi-management/dashboard" component={BIManagement} />

    <Route path="/bi-management/energy" component={BIEnergY} />
    <Route path="/bi-management/orders" component={BIOrders} />

    <Route path="/bi-management/fleet" component={BIFleet} />

    <Route
      path="/bi-management/renderization/:idReport"
      // path="/bi-management/renderization/:workspaceId/?:embededUrl/:embededId"
      component={PBIRenderization}
    />

    {/* Energy */}
    <Route path="/management-energy" component={EnergyManagement} exact />
    <Route path="/management-energy/energy-table" component={EnergyTable} />

    <Route path="/bi-management/energy" component={BIEnergY} />
    <Route
      path="/process-management"
      component={ProcessManagement}
      requirePermission="ti_data"
    />
    <Route
      path="/administrator/bi"
      requirePermission="user_adm"
      component={BusinessIntelligenceManagement}
    />

    {/* ClimateResearchManagement */}
    <Route
      path="/climate-research-management"
      component={ClimateResearchManagement}
    />

    {/* Gerenciador de arquivos */}
    <Route path="/file-management" component={fileManager} />

    {/* Gerenciador de arquivos */}
    <Route path="/schedule" component={ScheduleList} />

    {/* Planejamento estratégico */}
    <Route path="/strategic-planning" component={StrategicPlanning} />
    <Route
      path="/indicators/:strategicPlansId"
      component={ListPrimatyIndicator}
    />

    {/* ia */}
    <Route path="/ia" component={ia} />

    {/* Plano de investimento COOASGO */}
    <Route path="/pic" component={pic} />

    {/* Avaliação de desempenho */}
    <Route path="/performance-evaluation/home" component={ListOfEvaluation} />
    <Route
      path="/performance-evaluation/evaluation-resume/:name_subordinate/:id_hierarchies"
      component={EvaluationResume}
    />
    <Route
      path="/performance-evaluation/view-evaluation/:nick_user"
      component={PerformanceEvaluation}
    />

    <Route path="/error404-1" component={Error404} isUser />
    <Route path="/error404-2" component={Error404} isUser />
    <Route path="/error404-3" component={Error404} isUser />
    <Route path="/error404-4" component={Error404} isUser />
    <Route path="/error404-5" component={Error404} isUser />
    <Route path="/error404-6" component={Error404} isUser />

    <Route path="/test" component={Test} />
  </Switch>
);

export default Routes;

import {
  Flex,
  Icon,
  IconButton,
  useBreakpointValue,
  Text,
} from '@chakra-ui/react';
import { RiMenuLine } from 'react-icons/ri';
import { useContext } from 'react';
import { Profile } from './Profile';
import { Logo } from './Logo';
import { useSidebarDrawer } from '../../../contexts/SidebarDrawerContext';
import { SetTitleContext } from '../../../contexts/SetTitleBIContext';
import { Options } from './Options';
import { SearchBox } from './SearchBox';

interface Props {
  path: 'miles' | 'bi' | 'profile' | 'adm' | 'home' | 'order_services';
}

export function HeaderUp({ path }: Props) {
  const { onOpen } = useSidebarDrawer();
  const { titleReport } = useContext(SetTitleContext);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex
      as="header"
      w="100%"
      maxWidth={1480}
      h="20"
      mx="auto"
      mt="4"
      px="6"
      alignItems="center"
      bg="theme"
    >
      <Flex alignItems="center">
        {path === 'bi' && (
          <IconButton
            aria-label="Open navigation"
            icon={<Icon as={RiMenuLine} />}
            fontSize="24"
            variant="unstyled"
            onClick={onOpen}
            mr="2"
          />
        )}

        {!isWideVersion && (
          <IconButton
            aria-label="Open navigation"
            icon={<Icon as={RiMenuLine} />}
            fontSize="24"
            variant="unstyled"
            onClick={onOpen}
            display={path !== 'miles' ? 'none' : ''}
            mr="2"
          />
        )}
        <Logo />
        {isWideVersion && <SearchBox />}
      </Flex>

      {path === 'bi' && titleReport && (
        <Text
          fontSize="2xl"
          fontWeight="medium"
          // display={path === 'miles' ? 'none' : ''}
        >
          {' '}
          {titleReport}
        </Text>
      )}

      <Flex align="center" ml="auto">
        <Options />
        <Profile showProfileData={isWideVersion} path={path} />
      </Flex>
    </Flex>
  );
}

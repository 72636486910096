/* eslint-disable react/jsx-curly-newline */
import { MenuItem as MenuItemChakra, MenuDivider } from '@chakra-ui/react';
import { useCallback, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SetTitleContext } from '../../../../../contexts/SetTitleBIContext';

interface Props {
  label: string;
  embededId: string;
  embededUrl: string;
  workspaceId: string;
  configId: string;
}
interface ParamsRouter {
  idReport: string;
}

export function MenuItem({
  label,
  embededId,
  embededUrl,
  workspaceId,
  configId,
}: Props) {
  const { idReport } = useParams<ParamsRouter>();
  const history = useHistory();
  const { handleSetTitle } = useContext(SetTitleContext);

  const handlePushUrlReport = useCallback(() => {
    if (embededId !== idReport) {
      history.push(`/bi-management/renderization/${embededId}`, {
        embededId,
        embededUrl,
        workspaceId,
        configId,
      });
      handleSetTitle(label);
    }
  }, [
    configId,
    embededId,
    embededUrl,
    handleSetTitle,
    history,
    idReport,
    label,
    workspaceId,
  ]);

  return (
    <>
      <>
        <MenuItemChakra
          _hover={{ bg: 'gray.650' }}
          // as={Button}
          // to={`/bi-management/renderization/${workspaceId}/?${embededUrl}/${embededId}`}
          onClick={handlePushUrlReport}
        >
          {label}
        </MenuItemChakra>

        <MenuDivider />
      </>
    </>
  );
}

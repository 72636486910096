import { Flex, Box, ScaleFade, Grid, GridItem, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../../../services/api';

interface Props {
  present: boolean;
  year: number;
  proccessAdded: string;
}

interface Plannings {
  id: string;
  planning: string;
  year: number;
  status: string;
}

export function ListPlannings({ present, year, proccessAdded }: Props) {
  const [plannings, setPlannings] = useState<Plannings[]>([]);

  useEffect(() => {
    try {
      api.get('/strategic-plans').then(response => {
        setPlannings(response.data);
      });
    } catch (e) {
      console.log('error', e);
    }
  }, [proccessAdded]);

  return (
    <>
      <ScaleFade initialScale={0.9} in>
        <Flex direction="column" h="100vh" mt="24px">
          <Flex pb={4} textAlign="center" m="0">
            <Flex
              alignItems="center"
              flexDirection="column"
              borderLeft="1px"
              borderRight="1px"
            >
              <Box mb="8px" bg="gray.700" p="4">
                <Box
                  fontSize="22"
                  mb="4px"
                  color={present ? 'gray.50' : 'gray.200'}
                >
                  {year}
                </Box>
                <Grid
                  templateColumns="repeat(12, 1fr)"
                  gap={1}
                  color={present ? 'gray.50' : 'gray.200'}
                >
                  <GridItem>
                    <Box>Jan.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Fev.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Mar.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Abr.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Mai.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Jun.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Jul.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Ago.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Set.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Out.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Nov.</Box>
                  </GridItem>
                  <GridItem>
                    <Box>Dez.</Box>
                  </GridItem>
                </Grid>
              </Box>

              <Flex alignItems="center" flexDirection="column" w="100%" p="4px">
                {plannings.map(planning => (
                  <Flex
                    key={planning.id}
                    to={`/indicators/${planning.id}`}
                    as={Link}
                    w="100%"
                    // h="4rem"
                    bg={present ? 'green.500' : 'green.700'}
                    borderRadius="6px"
                    m="2"
                    p="3"
                    flexDirection="column"
                    alignItems="initial"
                    textAlign="start"
                  >
                    <Text
                      fontSize="20"
                      color={present ? 'gray.50' : 'gray.200'}
                    >
                      {planning.planning}
                    </Text>
                    <Text
                      textOverflow="ellipsis"
                      maxW="400px"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      color={present ? 'gray.50' : 'gray.200'}
                    >
                      Planejamento official referente Exercicio {planning.year}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ScaleFade>
    </>
  );
}

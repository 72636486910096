/* eslint-disable @typescript-eslint/ban-ts-comment */
interface Actions {
  id: string;
  sub_indicator_id: string;
  title: string;
  description: string;
  label: string;
  lane: string;
  start_date: string;
  end_date: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
  responsible: {
    name: string;
  };
  indicator: {
    name: string;
  };
}
[];

export function formatedDataActions(dataAction: Actions[]) {
  const data = {
    lanes: [
      {
        id: 'lane1',
        title: 'Ações pendentes',
        label: '2/2',
        editable: true,
        cards: [],
      },
      {
        id: 'lane2',
        title: 'Ações em andamento',
        label: '0/0',
        cards: [],
      },
      {
        id: 'lane3',
        title: 'Ações Concluídas',
        label: '0/0',
        cards: [],
      },
      {
        id: 'lane4',
        title: 'Ações pausadas',
        label: '0/0',
        cards: [],
      },
    ],
  };

  data.lanes.forEach(lane => {
    dataAction.forEach(action => {
      if (lane.id === action.lane) {
        const card = {
          id: action.id,
          title: action.title,
          label: action.label,
          description: action.description,
        };
        // @ts-ignore
        lane.cards.push(card);
      }
    });
  });

  return data;
}

export function formatedDataActionsAddLaneTitle(dataAction: Actions[]) {
  const data = {
    lanes: [
      {
        id: 'lane1',
        title: 'Pendente',
        label: '2/2',
        editable: true,
        cards: [],
      },
      {
        id: 'lane2',
        title: 'Andamento',
        label: '0/0',
        cards: [],
      },
      {
        id: 'lane3',
        title: 'Concluída',
        label: '0/0',
        cards: [],
      },
      {
        id: 'lane4',
        title: 'Pausada',
        label: '0/0',
        cards: [],
      },
    ],
  };

  const a = dataAction.map(action => {
    const formatedData = {
      ...action,
      titleStatus: data.lanes.filter(lane => lane.id === action.lane)[0].title,
    };
    return formatedData;
  });

  return a;
}

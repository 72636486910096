/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';

// import MultiSelect from 'react-multi-select-component';
import { ModalBody, ModalFooter, Button } from '@chakra-ui/react';
import { Form } from './styles';

import Input from '../../../Global/Input';
import Select from '../../../Global/Select';

import { ModalComponent } from '..';

import { api } from '../../../../services/api';

interface IUser {
  name: string;
  nickname: string;
  password: string;
  tag: string;
  email: string;
  status: string;
  ramal: number;
  fone: number;
  image: string;
  sector_id: string;
  senior_id: string;
}

interface IOptions {
  value: string;
  label: string;
}

interface IHandleUser {
  userData: IUser;
  infoSectors: IOptions[];
  infoModules: IOptions[];
}

interface IModalProps {
  isOpen: boolean;
  // dataEditSector: string;
  setIsOpen: () => void;
  handleUser: (user: Omit<IHandleUser, ''>) => void;
  // setDataEditSector: () => void;
}

const ModalAddSector: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleUser,
  // dataEditSector,
  // setDataEditSector,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [sectorSelected] = useState([]);
  const [moduleSelected] = useState([
    { value: 'dashboard', label: 'Dashboard' },
  ]);
  const [sectorFormated, setSectorFormated] = useState<IOptions[]>([]);

  const options = [
    { value: 'admin', label: 'Acesso para um adiminstrador' },
    { value: 'admin-os', label: 'Acesso para um adm de ordens de serviços' },
    { value: 'user', label: 'Acesso comum' },
  ];

  useEffect(() => {
    api.get('/sectors').then(response => {
      const sectorFormateded: React.SetStateAction<any[]> = [];

      response.data.forEach(function (sector: { id: string; name: string }) {
        const option = {
          value: sector.id,
          label: sector.name,
        };
        sectorFormateded.push(option);
      });

      setSectorFormated(sectorFormateded);
    });
  }, []);

  const handleSubmit = useCallback(
    async (data: IUser) => {
      const dataCreateUser = {
        userData: data,
        infoSectors: sectorSelected,
        infoModules: moduleSelected,
      };
      handleUser(dataCreateUser);
      setIsOpen();
    },
    [handleUser, moduleSelected, sectorSelected, setIsOpen],
  );

  return (
    <ModalComponent
      title="Adicionar novo usuário"
      isOpen={isOpen}
      onClose={setIsOpen}
      scrollBehavior="inside"
    >
      {/* {console.log(dataInitialSector)} */}
      <ModalBody>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <header>
            <p>Nome do usuário</p>
            <Input name="name" placeholder="Ex: Cristiano Mattei" />

            <p>Imagem</p>
            <Input name="image" placeholder="https://nome_do_user" />

            <p>Nome de acesso</p>
            <Input
              name="nickname"
              type="text"
              placeholder="Ex: cristiano.mattei"
            />
          </header>

          <p>E-mail do usuário</p>
          <Input
            type="email"
            name="email"
            placeholder="Ex: antonio.fagundes@.empresa.com.br"
          />

          <p>Senha</p>
          <Input type="text" name="password" placeholder="Ex: senha123" />

          <p>Telefone</p>
          <Input type="text" name="phone" placeholder="Exemplo: 6791905052" />
          <p>Ramal</p>
          <Input
            type="text"
            name="ramal"
            placeholder="Exemplo: 8008"
            mb="1rem"
          />
          <p>Cod. cadastro sênior</p>
          <Input type="text" name="senior_id" placeholder="1258" mb="1rem" />

          <p>Modelo de acesso</p>
          <Select name="tag" options={options} />

          <p>Setor</p>
          <Select name="sector_id" options={sectorFormated} />
          {/* <MultiSelect
          options={sectorFormated}
          value={sectorSelected}
          onChange={setSectorSelected}
          labelledBy="Selecione"
          overrideStrings={Internationalization}
        />
  */}

          {/* <MultiSelect
          options={modules}
          value={moduleSelected}
          onChange={setModuleSelected}
          labelledBy="Selecione"
          overrideStrings={Internationalization}
        />
*/}

          <ModalFooter>
            <Button colorScheme="blue" type="submit">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </ModalComponent>
  );
};

export default ModalAddSector;

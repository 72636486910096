/* eslint-disable react/jsx-no-bind */
import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Text,
  Button,
  ModalFooter,
  Center,
} from '@chakra-ui/react';
import { AiOutlineInstagram } from 'react-icons/ai';
import { FiGlobe } from 'react-icons/fi';

import html2canvas from 'html2canvas';

import { useAuth } from '../../../../hooks/auth';

import aqvcvalemais from '../../../../assets/aqvcvalemais.png';
import logo30anos from '../../../../assets/logoatual2.jpg';
import divider from '../../../../assets/divider.png';
import phoneImg from '../../../../assets/phone.png';
import zap from '../../../../assets/zap.png';
import point from '../../../../assets/point.png';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';

interface Props {
  name: string;
  filName: string;
  ramal: number;
  phone: string;
  dataComplementary: string;
  handleCloseImageEmail: () => void;
  permissionNameEdit: boolean | undefined;
  // handleDownloadImage: () => void;
}

interface Users {
  setorName: string;
  jobPosition: string;
  userName: string;
}

export function Signature({
  name,
  filName,
  phone,
  ramal,
  dataComplementary,
  handleCloseImageEmail,
  permissionNameEdit,
}: // handleDownloadImage,
Props) {
  const printRef = useRef(null);
  const [userData, setUserData] = useState<Users>();

  const { user } = useAuth();

  // async function hadleSendImageForDownload() {
  //   handleDownloadImage();
  // }
  const handleDownloadImage = async () => {
    const element = printRef.current;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const canvas = await html2canvas(element, {
      scale: 0.95,
      // width: 200,
      // height: 100,
    });

    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = `${user.name}.jpg`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  useEffect(() => {
    try {
      if (user.senior_id) {
        api(
          `https://samasc.cooasgo.com.br:3333/senior?numcad=${user.senior_id}`,
        ).then(response => {
          const formatData = {
            setorName: response.data[0][0].nomccu,
            jobPosition: response.data[0][0].titcar,
            userName: permissionNameEdit ? name : response.data[0][0].nomfun,
          };
          setUserData(formatData);
          console.log(response.data);
        });
      } else {
        console.log('sem senior id');
        apllyToast('error', 'Adicione seu código da Senior!');
      }
    } catch (e) {
      console.log('error', e);
    }
  }, [name, user.senior_id, permissionNameEdit]);

  return (
    <>
      <Box maxH="320px" minH="300px">
        <Box>
          <Center ref={printRef}>
            <Box minWidth="26rem" bg="white" borderRadius="6px">
              <Box>
                <Text fontSize="1.2rem" fontWeight="bold">
                  {userData?.userName}
                </Text>
                <p>{userData?.setorName}</p>
                <p>{userData?.jobPosition}</p>
                <p>{dataComplementary || ''}</p>
              </Box>

              <Flex
                flexDirection="row"
                justifyContent="space-between"
                mt="1rem"
                alignItems="center"
              >
                <Box minW="15rem" textAlign="center">
                  {ramal ? (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                      textAlign="center"
                    >
                      <img
                        src={phoneImg}
                        className="imgSignature"
                        alt="phone"
                      />

                      <Text ml="2" fontSize="14px">
                        {' '}
                        {`(67) 3295-${ramal}`}
                      </Text>
                    </Flex>
                  ) : (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                      textAlign="center"
                    >
                      <img
                        src={phoneImg}
                        className="imgSignature"
                        alt="phone"
                      />

                      <Text ml="2" fontSize="14px">
                        (67) 3295-8000
                      </Text>
                    </Flex>
                  )}

                  {phone ? (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                      textAlign="center"
                    >
                      <img src={zap} className="imgSignature" alt="zap" />

                      <Text ml="2" fontSize="14px">
                        {' '}
                        {phone}
                      </Text>
                    </Flex>
                  ) : (
                    ''
                  )}

                  <Flex flexDirection="row" alignItems="center" w="100%">
                    <Box mr="2">
                      <img src={point} className="imgSignature" alt="point" />
                    </Box>
                    <Text fontSize="14px">{filName}</Text>
                  </Flex>
                </Box>

                <Box mr="2rem">
                  <Flex flexDirection="row" alignItems="center">
                    <Box mr="2">
                      <FiGlobe className="imgSignature" />
                    </Box>
                    <Text fontSize="14px">cooasgo.com.br</Text>
                  </Flex>
                  <Flex flexDirection="row" alignItems="center">
                    <Box mr="2">
                      <AiOutlineInstagram className="imgSignature" />
                    </Box>
                    <Text fontSize="14px">cooasgocooperativa</Text>
                  </Flex>
                </Box>
              </Flex>

              <Flex
                alignItems="center"
                justifyContent="space-between"
                mt="1rem"
              >
                <Box>
                  <Image
                    src={logo30anos}
                    w="150px"
                    h="140px"
                    alt="Logo dos 30 anos cooasgo"
                  />
                </Box>
                <Box>
                  <Image src={divider} w="1px" alt="Logo dos 30 anos cooasgo" />
                </Box>
                <Box mr="2rem">
                  <Image
                    src={aqvcvalemais}
                    w="120px"
                    h="60px"
                    alt="Logo dos 30 anos cooasgo"
                  />
                </Box>
              </Flex>

              {/* <Button type="button" onClick={hadleSendImageForDownload}>
          Download Imagem
        </Button> */}
            </Box>
          </Center>
        </Box>

        <Box position="absolute" top="50px" mb="0">
          <Center>
            <Box minWidth="28rem" bg="white" borderRadius="6px" p="20px">
              <Box>
                <Text fontSize="1.2rem" fontWeight="bold">
                  {userData?.userName}
                </Text>
                <p>{userData?.setorName}</p>
                <p>{userData?.jobPosition}</p>
                <p>{dataComplementary || ''}</p>
              </Box>

              <Flex
                flexDirection="row"
                justifyContent="space-between"
                mt="1rem"
                alignItems="center"
              >
                <Box minW="15rem" textAlign="center">
                  {ramal ? (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                      textAlign="center"
                    >
                      <img
                        src={phoneImg}
                        className="imgSignature2"
                        alt="phone"
                      />

                      <Text ml="2" fontSize="14px">
                        {' '}
                        {`(67) 3295-${ramal}`}
                      </Text>
                    </Flex>
                  ) : (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                      textAlign="center"
                    >
                      <img
                        src={phoneImg}
                        className="imgSignature2"
                        alt="phone"
                      />

                      <Text ml="2" fontSize="14px">
                        (67) 3295-8000
                      </Text>
                    </Flex>
                  )}

                  {phone ? (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      w="100%"
                      textAlign="center"
                    >
                      <img src={zap} className="imgSignature2" alt="zap" />

                      <Text ml="2" fontSize="14px">
                        {' '}
                        {phone}
                      </Text>
                    </Flex>
                  ) : (
                    ''
                  )}

                  <Flex flexDirection="row" alignItems="center" w="100%">
                    <Box mr="2">
                      <img src={point} className="imgSignature2" alt="point" />
                    </Box>
                    <Text fontSize="14px">{filName}</Text>
                  </Flex>
                </Box>

                <Box>
                  <Flex flexDirection="row" alignItems="center">
                    <Box mr="2">
                      <FiGlobe className="imgSignature2" />
                    </Box>
                    <Text fontSize="14px">cooasgo.com.br</Text>
                  </Flex>
                  <Flex flexDirection="row" alignItems="center">
                    <Box mr="2">
                      <AiOutlineInstagram className="imgSignature2" />
                    </Box>
                    <Text fontSize="14px">cooasgocooperativa</Text>
                  </Flex>
                </Box>
              </Flex>

              <Flex
                alignItems="center"
                justifyContent="space-between"
                mt="1rem"
              >
                <Box>
                  <Image
                    src={logo30anos}
                    w="130px"
                    h="120px"
                    alt="Logo dos 30 anos cooasgo"
                  />
                </Box>
                <Box>
                  <Image src={divider} w="1px" alt="Logo dos 30 anos cooasgo" />
                </Box>
                <Box>
                  <Image
                    src={aqvcvalemais}
                    w="120px"
                    h="60px"
                    alt="Logo dos 30 anos cooasgo"
                  />
                </Box>
              </Flex>

              {/* <Button type="button" onClick={hadleSendImageForDownload}>
          Download Imagem
        </Button> */}
            </Box>
          </Center>
        </Box>
      </Box>
      <ModalFooter>
        <Button
          colorScheme="gray"
          onClick={() => handleCloseImageEmail()}
          mr="2"
        >
          Fechar
        </Button>
        <Button colorScheme="blue" onClick={() => handleDownloadImage()}>
          Salvar
        </Button>
      </ModalFooter>
    </>
  );
}

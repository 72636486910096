import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, ModalBody, ModalFooter } from '@chakra-ui/react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useLoading, Oval } from '@agney/react-loading';
// import { apllyToast } from '../../../Global/Toast2.0';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../Global/Input';
import Select from '../../../Global/SelectRelease';
import { ModalComponent } from '..';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface DataConfig {
  id: string;
  pbiUsername: string;
}

interface Options {
  label: string;
  value: string;
}

export function ModalAddNewAnalyisBI({ onClose, isOpen }: Props) {
  const formRef = useRef<FormHandles>(null);

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const [reasonItem, setReason] = useState('');
  const [dataConfig, setDataConfig] = useState<Options[]>([]);

  useEffect(() => {
    api.get<DataConfig[]>(`/power-bi-configs`).then(response => {
      const formatData: { value: string; label: string }[] = [];

      response.data.forEach(data => {
        const config = {
          value: data.id,
          label: data.pbiUsername,
        };

        formatData.push(config);
      });

      setDataConfig(formatData);
    });
  }, []);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          groupNameSamasc: Yup.string().required(
            'groupNameSamasc é obrigatório',
          ),
          groupName: Yup.string().required('groupName é obrigatório'),
          groupId: Yup.string().required('groupId é obrigatório'),
          embededUrl: Yup.string().required('embedeUrl é obrigatório'),
          embededId: Yup.string().required('embedeId é obrigatório'),
          reportName: Yup.string().required('reportName é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const {
          groupNameSamasc,
          groupName,
          groupId,
          embededUrl,
          embededId,
          reportName,
        } = data;

        const dataFormated = {
          groupNameSamasc,
          groupName,
          groupId,
          embededUrl,
          embededId,
          reportName,
          id_power_bi_configs: reasonItem,
        };

        api.post('/power-bi-reports', dataFormated).then(() => {
          apllyToast('success', 'Sucesso ao adicionar nova analise');
        });

        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          apllyToast('error', 'Problemas ao adicionar nova analise');
          setLoading(false);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [reasonItem],
  );

  const { containerProps, indicatorEl } = useLoading({
    loading,
    indicator: <Oval />,
  });

  const handleReason = useCallback(e => {
    setReason(e);
  }, []);

  return (
    <>
      <ModalComponent title="Nova análise" isOpen={isOpen} onClose={onClose}>
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <p>Nome do grupo de análise</p>
            <Input type="text" name="groupNameSamasc" />
            <p>Nome do grupo BI</p>
            <Input type="text" name="groupName" />
            <p>ID do grupo</p>
            <Input type="text" name="groupId" />
            <p>URL embedded</p>
            <Input type="text" name="embededUrl" />
            <p>ID URL embedded</p>
            <Input type="text" name="embededId" />
            <p>Nome do relátorio</p>
            <Input type="text" name="reportName" />

            <Select
              name="reason"
              label="Configurações de BI"
              value={reasonItem}
              onChange={e => {
                handleReason(e.target.value);
              }}
              options={dataConfig}
            />

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                {loading ? (
                  <div {...containerProps} ref={componentRef}>
                    {indicatorEl}
                  </div>
                ) : (
                  'Salvar'
                )}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

/* eslint-disable prettier/prettier */
import {

  Button,
  Flex,
  Text,
  ScaleFade,

  Center,
  Spinner,
  Stack,
  // CircularProgress,
  // CircularProgressLabel,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  FiPlus,
} from 'react-icons/fi';
import { useRouteMatch } from 'react-router-dom';
import ModalAddNewIndicator from '../../../../components/_components_0.2/Modal/ModalAddNewIndicator';
import ModalAddNewStrategicObjective from '../../../../components/_components_0.2/Modal/ModalAddNewStrategicObjective';
import ModalAddNewStrategicPlacements from '../../../../components/_components_0.2/Modal/ModalAddNewStrategicPlacements';
import ModalAddNewSubIndicator from '../../../../components/_components_0.2/Modal/ModalAddNewSubIndicator';
import { api } from '../../../../services/api';

// import { Indicators } from './Indicators';
// import { IndicatorsSub } from './IndicatorsSub';
// import { StrategicObjective } from './StrategicObjective';
// import { StrategicPlacement } from './StrategicPlacements';

import { TableForAction } from './TableForActions';


interface ParmsRouter {
  strategicPlansId: string
}
// interface Placements {
//   id: string;
//   name: string;
//   strategic_objectives: [
//     {
//       id: string;
//       name: string;
//       indicators: [
//         {
//           id: string;
//           name: string;
//           image_url?: string;
//           sub_indicators: [
//             {
//               id: string;
//               name: string;
//             }
//           ]
//         }
//       ]
//     }
//   ]
// }

export function ListPrimatyIndicator() {
  const [loadingData, setLoadingData] = useState(false)
  const [addedNewData, setAddedNewData] = useState('');
  const [openModalAddSubindicator, setOpenModalAddSubindicator] = useState(false);
  const [openModalAddIndicator, setOpenModalAddIndicator] = useState(false);
  const [openModalAddStrategy, setOpenModalAddStrategy] = useState(false);
  const [openModalAddStrategyPlacements, setOpenModalAddStrategyPlacements] =
    useState(false);
    const { params } = useRouteMatch<ParmsRouter>()
    // const [ strategicPlacementsData , setStrategicPlacementsData] = useState<Placements[]>([]);


    useEffect(() => {
     try {
      setLoadingData(true)
      api.get(`/strategic-placements/show?strategic_plan_id=${params.strategicPlansId}`).then((response) => {
        // setStrategicPlacementsData(response.data)
        console.log('testete', response.data)
        setLoadingData(false)
      })

     } catch (err) {
      console.log(err)
      setLoadingData(false)
     }
    }, [params.strategicPlansId, addedNewData])

  return (
    <>
      <ScaleFade initialScale={0.9} in>
        <ModalAddNewStrategicPlacements
          strategicPlansId={params.strategicPlansId}
          isOpen={openModalAddStrategyPlacements}
          onClose={setOpenModalAddStrategyPlacements}
          setAddedNewData={setAddedNewData}
        />
        <ModalAddNewStrategicObjective
          isOpen={openModalAddStrategy}
          onClose={setOpenModalAddStrategy}
          setAddedNewData={setAddedNewData}
          addedNewData={addedNewData}
        />
        <ModalAddNewIndicator
          strategicPlansId={params.strategicPlansId}
          isOpen={openModalAddIndicator}
          onClose={setOpenModalAddIndicator}
          setAddedNewData={setAddedNewData}
          addedNewData={addedNewData}
        />
        <ModalAddNewSubIndicator
          isOpen={openModalAddSubindicator}
          onClose={setOpenModalAddSubindicator}
          setAddedNewData={setAddedNewData}
          addedNewData={addedNewData}
        />
        <Center>
          <Flex
            w="90%"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
            mt="24px"
          >
            <Text />
            <Stack spacing={4} direction="row" align="center">
              <Button
                leftIcon={<FiPlus />}
                onClick={() =>
                  setOpenModalAddStrategyPlacements(
                    !openModalAddStrategyPlacements,
                  )}
              >
                Posicionamento estratégico
              </Button>
              <Button
                leftIcon={<FiPlus />}
                onClick={() => setOpenModalAddStrategy(!openModalAddIndicator)}
              >
                Objetivo estratégico
              </Button>
              <Button
                leftIcon={<FiPlus />}
                onClick={() => setOpenModalAddIndicator(!openModalAddIndicator)}
              >
                Indicador
              </Button>
              <Button
                leftIcon={<FiPlus />}
                onClick={() => setOpenModalAddSubindicator(!openModalAddSubindicator)}
              >
                Subindicador
              </Button>
            </Stack>
          </Flex>
        </Center>

        {loadingData && (
        <Center mt="12">
          <Spinner />
        </Center>
          )}
        {!loadingData && (
          <ScaleFade initialScale={0.9} in>
            {/* {strategicPlacementsData.map((strategicPlacements) => (
              <StrategicPlacement key={strategicPlacements.id} title={strategicPlacements.name}>
                {strategicPlacements.strategic_objectives.map((strategicObjectData) => (
                  <StrategicObjective key={strategicObjectData.id} title={strategicObjectData.name}>
                    {strategicObjectData.indicators.map((indicator) => (
                      <Indicators key={indicator.id} title={indicator.name} id={indicator.id} img={indicator.image_url}>
                        {indicator.sub_indicators.map((subIndicator) => (
                          <IndicatorsSub key={subIndicator.id} title={subIndicator.name} id={subIndicator.id} />

                      ))}
                      </Indicators>

                ))}
                  </StrategicObjective>
            ))}
              </StrategicPlacement>
        ))} */}
            <Center>
              <TableForAction />
            </Center>
          </ScaleFade>

          )}




      </ScaleFade>
    </>
  );
}

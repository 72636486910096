import { useEffect, useState, useContext } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { api } from '../../../../services/api';
import { Internationalization } from '../../../../utils/InternationalizationMultSelect';
import { SetToggleThemeContext } from '../../../../contexts/SetToggleThemeContext';
import { Container } from './styles';

interface UserLsit {
  id: string;
  name: string;
  image?: string;
  status: boolean;
}

interface Options {
  value: string;
  label: string;
}

interface Props {
  title: string;
  dataSelectesUsers: Options[];
  setSelectedDataUsers: (value: Options[]) => void;
}

export default function SelectUsers({
  title,
  dataSelectesUsers,
  setSelectedDataUsers,
}: Props) {
  const [dataUsers, setDataUsers] = useState<Options[]>([]);

  const { toggleTheme } = useContext(SetToggleThemeContext);
  useEffect(() => {
    try {
      api.get<UserLsit[]>('/accesses').then(response => {
        const formatedData = response.data.map(user => {
          return {
            label: user.name,
            value: user.id,
          };
        });

        setDataUsers(formatedData);
      });
    } catch (e) {
      console.log('error', e);
    }
  }, []);

  return (
    <Container theme={toggleTheme}>
      <p>{title}</p>
      <MultiSelect
        options={dataUsers}
        value={dataSelectesUsers}
        valueRenderer={selected => {
          return selected.length
            ? selected.map(({ label }) => `✔️ ${label}`)
            : '';
        }}
        onChange={setSelectedDataUsers}
        labelledBy="Selecione"
        overrideStrings={Internationalization}
        className="selectUsers"
      />
    </Container>
  );
}

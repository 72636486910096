export const dataLane = [
  {
    value: 'lane1',
    label: 'Pendente',
  },
  {
    value: 'lane2',
    label: 'Andamento',
  },
  {
    value: 'lane3',
    label: 'Concluída',
  },
  {
    value: 'lane4',
    label: 'Pausada',
  },
];

import { useEffect, useState, useContext, SetStateAction } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { api } from '../../../../services/api';
import { Internationalization } from '../../../../utils/InternationalizationMultSelect';
import { SetToggleThemeContext } from '../../../../contexts/SetToggleThemeContext';
import { Container } from './styles';

interface UserLsit {
  id: string;
  name: string;
  image?: string;
  status: boolean;
}

interface Options {
  value: string;
  label: string;
}

interface Props {
  title: string;
  dataSelectesUsers: Options[];
  setSelectedDataUsers: (value: Options[]) => void;
  participants: Participants[];
}

interface Participants {
  id: string;
  participant: {
    name: string;
    id: string;
  };
}

export default function SelectUsers({
  title,
  dataSelectesUsers,
  participants,
  setSelectedDataUsers,
}: Props) {
  const [dataUsers, setDataUsers] = useState<Options[]>([]);

  const { toggleTheme } = useContext(SetToggleThemeContext);
  useEffect(() => {
    try {
      api.get<UserLsit[]>('/accesses').then(response => {
        const data =
          participants.length > 0
            ? participants.map(p => p.participant.id)
            : [];

        const dataFiltred: SetStateAction<Options[]> = [];
        response.data.forEach(participant => {
          if (!data.includes(participant.id)) {
            dataFiltred.push({
              value: participant.id,
              label: participant.name,
            });
          }
        });

        setDataUsers(dataFiltred);
      });
    } catch (e) {
      console.log('error', e);
    }
  }, [participants]);

  return (
    <Container theme={toggleTheme}>
      <p>{title}</p>
      <MultiSelect
        options={dataUsers}
        value={dataSelectesUsers}
        valueRenderer={selected => {
          return selected.length
            ? selected.map(({ label }) => `✔️ ${label}`)
            : '';
        }}
        onChange={setSelectedDataUsers}
        labelledBy="Selecione"
        overrideStrings={Internationalization}
        className="selectUsers"
      />
    </Container>
  );
}

import { useEffect, useState } from 'react';
import {
  FiList,
  FiBarChart,
  FiLayers,
  FiMapPin,
  FiSliders,
  FiFilePlus,
  FiFileText,
  FiPieChart,
  FiTerminal,
  FiUserCheck,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { api } from '../../../services/api';
import { OptionList } from './styles';

interface Hierarchies {
  id: string;
  result: string;
  subordinate: string;
}

interface Props {
  pathname: string;
}

export function ManagementPPR({ pathname }: Props) {
  const { user } = useAuth();
  const [dataLider, setDataLider] = useState<Hierarchies[]>([]);

  useEffect(() => {
    api.get(`/hierarchies/show?leader=${user.nickname}`).then(response => {
      setDataLider(response.data);
    });
  }, [user.nickname]);

  return (
    <>
      <OptionList pathname={pathname} path="/menu" className="nav-item" visible>
        <Link to="/menu" className="nav-link">
          <FiList color="#3182ce" size={16} />

          <span className="link-text">Menu inicial</span>
        </Link>
      </OptionList>
      <OptionList
        pathname={pathname}
        path="/management-ppr/dashboard"
        className="nav-item"
        visible
      >
        <Link to="/management-ppr/dashboard" className="nav-link">
          <FiBarChart color="#3182ce" size={16} />

          <span className="link-text">Dashboard</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/management-ppr/goals-subgoals"
        className="nav-item"
        visible
      >
        <Link to="/management-ppr/goals-subgoals" className="nav-link">
          <FiLayers color="#3182ce" size={16} />

          <span className="link-text">Metas e Submetas</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/management-ppr/sector"
        className="nav-item"
        visible
      >
        <Link to="/management-ppr/sector" className="nav-link">
          <FiMapPin color="#3182ce" size={16} />

          <span className="link-text">Setor</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/management-ppr/analyticModule"
        className="nav-item"
        visible
      >
        <Link to="/management-ppr/analyticModule" className="nav-link">
          <FiSliders color="#3182ce" size={16} />
          <span className="link-text">Módulos de análise</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/management-ppr/import"
        className="nav-item"
        visible
      >
        <Link to="/management-ppr/import" className="nav-link">
          <FiFilePlus color="#3182ce" size={16} />
          <span className="link-text">Importações</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/error404-3"
        className="nav-item"
        visible
      >
        <Link to="/management-ppr/report" className="nav-link">
          <FiFileText color="#3182ce" size={16} />

          <span className="link-text">Relatório</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/error404-5"
        className="nav-item"
        visible={false}
      >
        <Link to="/error404-5" className="nav-link">
          <FiPieChart color="#3182ce" size={16} />

          <span className="link-text">Gráficos</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/management-ppr/schedule"
        className="nav-item"
        visible
      >
        <Link to="/management-ppr/schedule" className="nav-link">
          <FiTerminal color="#3182ce" size={16} />

          <span className="link-text">Agenda</span>
        </Link>
      </OptionList>
      <OptionList
        pathname={pathname}
        path="/management-ppr/listOf-evaluation"
        className="nav-item"
        visible={dataLider.length > 0}
      >
        <Link to="/management-ppr/listOf-evaluation" className="nav-link">
          <FiUserCheck color="#3182ce" size={16} />

          <span className="link-text">Avaliação de desempenho</span>
        </Link>
      </OptionList>
    </>
  );
}

import { useEffect, useState, useCallback } from 'react';
import {
  Center,
  ScaleFade,
  Table,
  Tr,
  Thead,
  Th,
  Tbody,
  Checkbox,
  Td,
  Box,
  TableContainer,
  Flex,
  Heading,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { apllyToast } from '../../../components/Global/Toast2.0';
import { HeaderUp } from '../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../components/_components_0.2/Sidebar_0.2';
import { api } from '../../../services/api';
import { Pagination } from '../../../components/_components_0.2/Pagination';

import { Container } from './styles';
import { ModalAddNewClimateResearch } from '../../../components/_components_0.2/Modal/ModalAddNewClimateResearch';
// import { ModalEditNewEnergy } from '../../../components/_components_0.2/Modal/ModalEditNewEnergy';

import { Alert } from '../../../components/_components_0.2/Alert';

// interface ClimateResearchDescriptions {
//   sequential: number;
//   question: string;
//   options: string[];
// }

interface DataClimateResearch {
  question: number;
  result: string;
  survey_id: string;

  gender?: string;
  cooperative_time?: string;
  education?: string;
  occupation_area?: string;
  hierarchical_level?: string;
  related_item?: [{ value: string; label: string }];
  consideration?: string;
}
interface ResponseClimateResearch {
  climatesSurveys: [
    {
      id: string;
      survey_id: string;
      question: number;
      result: string;
    },
  ];
  pagination: {
    page: number;
    take: number;
    total: number;
    totalPages: number;
  };
}

export default function EnergyTable() {
  const [dataClimate, setDataClimate] = useState<ResponseClimateResearch>();
  const [page, setPage] = useState(1);

  const [newRegister, setNewRegister] = useState<string>();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    try {
      api
        .get('/climates-surveys/filter', {
          params: {
            take: 7,
            page,
          },
        })
        .then(response => {
          console.log('eeee', response.data);
          setDataClimate(response.data);
        });
    } catch (err) {
      console.log(err);
      apllyToast('error', 'Problemas ao carregar informações');
    }
  }, [page, newRegister]);

  const handleAddNewClimateResearch = useCallback(
    (data: DataClimateResearch[]) => {
      try {
        console.log('wwwwwwwwwwwwwww', data);
        api.post('/climates-surveys', data).then(response => {
          console.log('ee', response.data.id);
          setNewRegister(response.data[0].id);

          apllyToast('success', 'Pesquisa salva com sucesso!');
        });
      } catch (err) {
        console.log(err);
        apllyToast('error', 'Problemas ao finalizar pesquisa');
      }
    },
    [],
  );

  // const handleEditNewEnergy = useCallback(
  //   async (data: Omit<DataClimateResearch[], ''>) => {
  //     try {
  //       api
  //         .put(`/energy-consumption?id=${selectedItems[0]}`, data)
  //         .then(response => {
  //           setNewRegister(response.data.id);
  //           setSelectedItems([]);
  //           apllyToast('success', 'Sucesso ao atualizar custo!');
  //         });
  //     } catch (err) {
  //       console.log(err);
  //       apllyToast('warning', 'Problemas ao atualiza custo');
  //     }
  //   },
  //   [selectedItems],
  // );

  const handleSelectItem = useCallback(
    (id: string) => {
      const alreadySelected = selectedItems.findIndex(item => item === id);

      if (alreadySelected >= 0) {
        const filteredItems = selectedItems.filter(item => item !== id);

        setSelectedItems(filteredItems);
      } else {
        setSelectedItems([id]);
      }
    },
    [selectedItems],
  );

  const handleDeleteData = useCallback(() => {
    try {
      if (selectedItems.length > 0) {
        api.delete(`energy-consumption/?id=${selectedItems}`).then(() => {
          setNewRegister(`${selectedItems}-'deleted'`);
          apllyToast('success', 'Dados excluidos');
          onClose();
        });
      }
    } catch (err) {
      console.log(err);
      onClose();
      apllyToast('error', 'Problemas ao excluir dados');
    }
  }, [onClose, selectedItems]);

  return (
    <Container>
      <HeaderUp path="bi" />
      <Sidebar path="bi" />
      <Alert
        isOpen={isOpen}
        onClose={onClose}
        handleFunction={handleDeleteData}
        title="Descartar dados?"
        textBody="Você esta certo(a) de que deseja descartar este dado?"
        ButonSave="Sim"
        ButonClose="Não"
      />
      <Center>
        <ScaleFade initialScale={0.9} in>
          {/* <Flex alignItens="center"> */}

          {/* </Flex> */}
          <TableContainer>
            <Box
              maxWidth="1200px"
              // maxHeight="450px"
              flex="1"
              borderRadius={8}
              bg="gray.800"
              p={['4', '8']}
              overflowX="auto"
            >
              <Flex mb="8" justify="space-between" align="center">
                <Heading size="md" fontWeight="normal">
                  Dados da pesquisa
                </Heading>

                <Flex>
                  {/* <Tooltip hasArrow label="Filtro">
              <Button
                size="sm"
                mr="2"
                colorScheme="blue"
                fontWeight="medium"
                onClick={onToggle}
              >
                <Icon as={RiFilter2Line} fontSize="20" />
              </Button>
            </Tooltip> */}
                  <Button
                    mr="5px"
                    size="sm"
                    colorScheme="red"
                    onClick={() => onOpen()}
                    disabled={!(selectedItems.length > 0)}
                  >
                    Excluir dados
                  </Button>
                  {/* <ModalEditNewEnergy
                    disabled={!(selectedItems.length > 0)}
                    handleEditNewEnergy={handleEditNewEnergy}
                    idEnergy={selectedItems[0]}
                  /> */}
                  <ModalAddNewClimateResearch
                    handleAddNewClimateResearch={handleAddNewClimateResearch}
                  />
                </Flex>
              </Flex>
              <Table variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    {/* <Th px={['2', '4', '6']} color="gray.300" width="8">
                      <Checkbox colorScheme="blue" />
                    </Th> */}
                    <Th />
                    <Th>Idendificação unica da pesquisa</Th>
                    <Th>Número do Questionamento</Th>
                    <Th>Resultado</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataClimate?.climatesSurveys.map(climate => (
                    <Tr key={climate.id}>
                      <Td px={['2', '4', '6']}>
                        <Checkbox
                          colorScheme="blue"
                          isChecked={selectedItems.includes(climate.id)}
                          onChange={() => handleSelectItem(climate.id)}
                        />
                      </Td>

                      <Td>{climate.survey_id}</Td>
                      <Td>{climate.question}</Td>
                      <Td>{climate.result}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </TableContainer>
          <Pagination
            totalCountOfRegisters={dataClimate?.pagination.total || 0}
            currentPage={page}
            onPageChange={setPage}
            registersPerPage={dataClimate?.pagination.take || 0}
          />
        </ScaleFade>
      </Center>
    </Container>
  );
}

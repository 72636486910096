/* eslint-disable prettier/prettier */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';

import { useLocation } from 'react-router-dom';
import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import EditableInput from '../../EditableInput';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';
import { PopoverAddNewParticipant } from './PopoverAddNewParticipant';
import { Involveds } from './Involveds';
import { Responsable } from './Responsable';
import { PopoverAddReplace } from './PopoverAddReplace';

interface Actions {
  id: string;
  sub_indicator_id: string;
  title: string;
  description: string;
  contents: string;
  label: string;
  start_date: string;
  status: boolean;
  created_at: Date;
  updated_at: Date;
  responsible: {
    id: string,
    name: string
    }
}
interface Props {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  refetch: () => void;
}
interface Options {
  value: string;
  label: string;
}
interface Participants {
  id: string;
  participant: {
    name: string;
    id: string;
  };
}
interface ResponsableInterface {
  responsible: {
    id: string,
    name: string
    }
}
interface ParmsRouter {
  action: Actions;
}

export default function ModalEditCardTrello({
  onClose,
  isOpen,
  refetch,
}: Props) {
  const { state } = useLocation<ParmsRouter>();
  const formRef = useRef<FormHandles>(null);
  const editor = useRef<SunEditorCore>();
  const [loading, setLoading] = useState(false);
  const [loadingParticipants, setLoadingParticipants] = useState(false);
  const [loadDeleteParticipants, setLoadDeleteParticipants] = useState(false);
  const [dataSelectesUsers, setSelectedDataUsers] = useState<Options[]>([]);
  const [updatedParticipants, setUpdatedParticipants] = useState('');
  const [participants, setParticipants] = useState<Participants[]>([]);
  const [show, setShow] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [openPopoverResponse, setOpenPopoverResponse] = useState(false);
  const [updatedResponsable, setUpdatedResponsable] = useState<ResponsableInterface>();

  const handleToggle = () => setShow(!show);

  const getSunEditorInstance = useCallback((sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  }, []);

  useEffect(() => {
    try {
      // if (updatedParticipants === '' && state !== undefined) {
        setLoadingParticipants(true)
        api
        .get(
          `/indicators-actions-of-participants/show?indicator_action_id=${state.action.id}`,
          )
          .then(response => {
            setParticipants(response.data);
            setLoadingParticipants(false)
          });
        // }

    } catch (err) {
      console.log('error', err);
      setLoadingParticipants(false)
    }
  }, [updatedParticipants, loadDeleteParticipants, state]);

  const handleSubmit = useCallback(
    async data => {
      try {
        if(state) {
        const { title, start_date, end_date } = data;
        const formatedData = {
          id:  state.action.id,
          title,
          start_date,
          end_date,
          description: editor.current?.getText(),
          contents: editor.current?.getContents(true),
        };
        setLoading(true);

          await api.put('/indicators-actions', formatedData).then(() => {
            apllyToast('success', 'Atualizado com sucesso!');
            onClose(false);
            setLoading(false);
          });
          refetch()
          }

      } catch (err) {
        console.log(err);
        apllyToast('error', 'Problemas ao atualizar ação!');
        setLoading(false);
      }
    },
    [state, refetch, onClose],
  );

  const handleUpdateParticipants = useCallback(() => {
    try {
      if (dataSelectesUsers.length > 0) {
          const formatData = {
            indicator_action_id:  state.action.id,
            participant_ids: dataSelectesUsers.map(responsable => {
              return responsable.value;
            }),
          };
          api
            .post('/indicators-actions-of-participants/create-all', formatData)
            .then(response => {
              setUpdatedParticipants(response.data);
              apllyToast('success', 'Participante adicionado com sucesso!');
              setOpenPopover(false);
              setSelectedDataUsers([]);
            });
      } else {
        apllyToast('warning', 'Selecione ao menos um participante!');
      }
    } catch (err) {
      console.log('error', err);
      apllyToast('error', 'Problemas ao adicionar participante!');
      setOpenPopover(false);
    }
  }, [state, dataSelectesUsers]);

  const handleDeleteParticipants = useCallback(
    async (participant_id: string) => {
      try {
        setLoadDeleteParticipants(true)

         await api
          .delete('/indicators-actions-of-participants', {
            params: {
              participant_id,
              indicator_action_id: state.action.id,
            },
          })
          .then(() => {
            setUpdatedParticipants(participant_id);
            setLoadDeleteParticipants(false)
          });


      } catch (err) {
        console.log('error', err);
        setLoadDeleteParticipants(false)
      }
    },
    [state],
  );

  const handleUpdateResponsable = useCallback(async (user_id) => {
    try {
      const dataFormated = {
        id:  state.action.id,
        responsible_id: user_id,
      }
       await api
        .put('/indicators-actions', dataFormated)
        .then((response) => {
          console.log('response.data', response.data);
          setUpdatedResponsable(response.data);
          setOpenPopoverResponse(false)
          apllyToast('success', 'Atualizado com sucesso!')
        });

    } catch (err) {
      console.log('error', err);
      setOpenPopoverResponse(false)
      apllyToast('error', 'Problemas ao atualizar responsávelsso!')
    }
  }, [state])

  const verifyQuantityParticipants = useMemo(() => {
    if (participants.length > 4) {
      return true;
    }
    return false;
  }, [participants.length]);

  const verifyExistiParticipants = useMemo(() => {
    if (participants.length > 0) {
      return true;
    }
    return false;
  }, [participants.length]);

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        onClose={() => onClose(!isOpen)}
        scrollBehavior="inside"
        sizeSpecifyc="3xl"
      >
        <ModalBody>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={state !== undefined ? state.action : undefined}
          >
            <ModalHeader mt="-6" ml="-5">
              <EditableInput name="title" />
            </ModalHeader>

            <Responsable
              title='Responsável'
              responsable={updatedResponsable?.responsible === undefined && state !== undefined ? state.action?.responsible : updatedResponsable?.responsible}

            >
              <PopoverAddReplace
                handleUpdateResponsable={handleUpdateResponsable}
                openPopover={openPopoverResponse}
                setOpenPopover={setOpenPopoverResponse}
              />
            </Responsable>

            <Involveds
              title='Envolvidos'
              handleDeleteParticipants={handleDeleteParticipants}
              handleToggle={handleToggle}
              loadDeleteParticipants={loadDeleteParticipants}
              loadingParticipants={loadingParticipants}
              participants={participants}
              show={show}
              verifyQuantityParticipants={verifyQuantityParticipants}
              verifyExistiParticipants={verifyExistiParticipants}
            >
              <PopoverAddNewParticipant
                setSelectedDataUsers={setSelectedDataUsers}
                dataSelectesUsers={dataSelectesUsers}
                handleUpdateParticipants={handleUpdateParticipants}
                participants={participants}
                setOpenPopover={setOpenPopover}
                openPopover={openPopover}
              />
            </Involveds>

            <Flex
              flexDirection="column"
              bg="gray.800"
              p="2"
              borderRadius="6px"
              mt="2"
            >
              <Flex flexDirection="row">
                <Box>
                  <Text>Inicio</Text>
                  <Input
                    // value={dataActionsOpened.start_date}
                    variant="unstyled"
                    size="md"
                    type="date"
                    name="start_date"
                  />
                </Box>
                <Box>
                  <Text>Fim</Text>
                  <Input
                    // value={dataActionsOpened.end_date}
                    variant="unstyled"
                    size="md"
                    type="date"
                    name="end_date"
                  />
                </Box>
              </Flex>
            </Flex>
            <Flex flexDirection="column" mt="4">
              <p>Descrição</p>
              <SunEditor
                setDefaultStyle="font-family:'Roboto', sans-serif; min-height: 220px"
                setContents={state ? state.action.contents : undefined}
                getSunEditorInstance={getSunEditorInstance}
                // setOptions={optRestrict}
                // // setContents={dataProcess?.contents}
                // hideToolbar={false}
              />
            </Flex>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => onClose(!isOpen)}>
                Fechar
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={loading}
                loadingText="Salvando"
              >
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-constant-condition */
import {
  Flex,
  Tag,
  Box,
  Button,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { BiBarChartSquare } from 'react-icons/bi';
import { FiEdit2, FiPower } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { apllyToast } from '../../../../../components/Global/Toast2.0';
import { ListPermissions } from '../../../../../components/_components_0.2/Admin/ListPermissions';
import { ModalEditReportBI } from '../../../../../components/_components_0.2/Modal/ModalEditReportBI';
import { api } from '../../../../../services/api';

interface DataPermission {
  power_bi_report_id: string;
  permission_identification: string;
}

interface Props {
  power_bi_report_id: string;
  label: string;
  status: boolean;
  permission: string[];
  handleDeletePermission: (namePermission: DataPermission) => void;
}

export function Reports({
  label,
  status,
  permission,
  handleDeletePermission,
  power_bi_report_id,
}: Props) {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const handleSubmitPermission = useCallback(
    (data: string) => {
      try {
        api
          .post('power-bi-reports-of-permissions', {
            power_bi_report_id,
            permission_id: data,
          })
          .then(() => {
            apllyToast('success', 'Permissions saved successfully');
          });
      } catch (err) {
        apllyToast('error', 'Error saving permissions');

        console.log('err', err);
      }
    },
    [power_bi_report_id],
  );

  return (
    <>
      <ModalEditReportBI
        handleSubmitPermission={handleSubmitPermission}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Flex
        flexDirection="row"
        alignItems="center"
        textAlign="left"
        justifyContent="space-between"
        padding="2.5"
      >
        <Flex flexDirection="row" alignItems="center">
          <Text fontWeight="medium" color="gray.650" mr="10px">
            Relatório:
          </Text>
          <Text>{label}</Text>

          <Flex ml="10">
            <Text fontWeight="medium" color="gray.650" mr="10px">
              Situação:
            </Text>
            {status ? (
              <Tag size="sm" colorScheme="green">
                Ativo
              </Tag>
            ) : (
              <Tag size="sm" colorScheme="red">
                Inativo
              </Tag>
            )}
          </Flex>
          <Text fontWeight="medium" color="gray.650" mr="10px">
            Permissão:
          </Text>
          {permission?.map(p => (
            <ListPermissions
              key={p}
              handleDeletePermission={() =>
                handleDeletePermission({
                  power_bi_report_id,
                  permission_identification: p,
                })
              }
              label={p}
              value={p}
            />
          ))}
        </Flex>
        <Box>
          <Tooltip bg="gray.650" label="Editar">
            <Button
              onClick={() => onOpen()}
              type="button"
              colorScheme="green"
              color="white"
              mr="2"
            >
              <FiEdit2 />
            </Button>
          </Tooltip>
          <Tooltip bg="gray.650" label="Abrir BI integrado">
            <Button
              onClick={() => console.log('users.id ? users.id : ')}
              type="button"
              colorScheme="yellow"
              color="white"
              mr="2"
            >
              <BiBarChartSquare />
            </Button>
          </Tooltip>
          <Tooltip bg="gray.650" label="Desativar">
            <Button
              as={Link}
              to="/administrator/set-data-user"
              onClick={() => console.log('pera')}
              type="button"
              colorScheme="red"
              color="white"
              mr="2"
            >
              <FiPower />
            </Button>
          </Tooltip>
        </Box>
      </Flex>
    </>
  );
}

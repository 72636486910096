import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

interface Props {
  theme: 'light' | 'dark';
}

export const Container = styled(Box)<Props>`
  color: '#181B23' !important;
  .dropdown-heading {
    border: 0.3px solid
      ${({ theme }: Props): string =>
        theme === 'light' ? '#adb5bd' : '#a0a4a8'};
    color: '#181B23';
    background: ${({ theme }: Props): string =>
      theme === 'light' ? '#EEEEF2' : '#353646'};
  }
  .selectUsers {
    color: '#181B23' !important;
  }
`;

import { Center } from '@chakra-ui/react';

import { TableForAction } from './TableForPlans';

export interface IinvestimentPlan {
  investmentsPlans: [
    {
      id: string;
      investment_name: string;
      branch_id: string;
      branch: {
        id: string;
        short_name: string;
      };
      sector_id: string;
      sector: {
        id: string;
        name: string;
      };
      category: string;
      type_investment: string;
      when: string;
      pic_year: string;
      responsable_id: string;
      responsible: {
        name: string;
        nickname: string;
      };
      priority: number;
      justification: string;
      description_project: string;
      investment_description: string;
      quantity: number;
      labor: string;
      return: string;
      value_total: number;
      situation: string;
      status: boolean;
      committee_opinion: string;
      final_opinion: string;
      created_at: string;
      updated_at: string;
      total_cost_month: number;
      investments_plans_of_files: [
        {
          id: string;
          file: string;
          file_url: string;
        },
      ];
    },
  ];
  pagination: {
    page: number;
    take: number;
    total: number;
    totalPages: number;
  };
}

export default function Plan() {
  return (
    <>
      <Center>
        <TableForAction />
      </Center>
    </>
  );
}

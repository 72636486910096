import { useCallback, useRef, useState } from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  Text,
  Switch,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useLoading, Oval } from '@agney/react-loading';
import getValidationErrors from '../../../../utils/getValidationErrors';

import { useAuth } from '../../../../hooks/auth';

import Input from '../../../Global/Input';
import { ModalComponent } from '..';

interface Props {
  bookmark: string;
  onClose: () => void;
  isOpen: boolean;
  embedId: string;
  handlesSetStateForBookmark: (dataBookmark: any) => void;
}

export function ModalAddNewBookmarkBI({
  bookmark,
  isOpen,
  onClose,
  embedId,
  handlesSetStateForBookmark,
}: Props) {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [publicData, setPublicData] = useState(false);

  const handleSubmit = useCallback(
    async (data: { name: string }) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name } = data;

        const formatData = {
          bookmark,
          name,
          access_id: user.id,
          embedId,
          public: publicData,
        };
        handlesSetStateForBookmark(formatData);
        onClose();

        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          setLoading(false);

          formRef.current?.setErrors(errors);

          return;
        }
        onClose();
      }
    },
    [
      bookmark,
      user.id,
      embedId,
      publicData,
      handlesSetStateForBookmark,
      onClose,
    ],
  );

  const { containerProps, indicatorEl } = useLoading({
    loading,
    indicator: <Oval />,
  });

  return (
    <>
      <ModalComponent
        title="Nova visualização"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Text>Como deseja chamar esta visualização?</Text>
            <Input
              type="text"
              name="name"
              placeholder="Ex: Fechamento de lote suicoper"
              mb="25px"
            />

            {/* <Text>Marcar como visualização publica?</Text> */}

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="public" mb="0">
                Tornar visualização pública?
              </FormLabel>
              <Switch id="public" onChange={() => setPublicData(!publicData)} />
            </FormControl>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                {loading ? (
                  <div {...containerProps} ref={componentRef}>
                    {indicatorEl}
                  </div>
                ) : (
                  'Salvar'
                )}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

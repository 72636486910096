import { useCallback, useRef } from 'react';
import { Button, ModalBody, ModalFooter, Flex } from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';
import getValidationErrors from '../../../../utils/getValidationErrors';

interface Props {
  strategicPlansId: string;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  setAddedNewData: (newData: any) => void;
}

export default function ModalAddNewStrategicPlacements({
  strategicPlansId,
  setAddedNewData,
  onClose,
  isOpen,
}: Props) {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, observation } = data;
        const formatData = {
          name,
          observation,
          strategic_plan_id: strategicPlansId,
        };

        api.post('/strategic-placements', formatData).then(response => {
          setAddedNewData(response.data);
          apllyToast(
            'success',
            'Sucesso ao adicionar novo Posicionamento estratégico!',
          );
          onClose(!isOpen);
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        console.log(err);
        apllyToast(
          'error',
          'Problemas ao adicionar novo Posicionamento estratégico',
        );
      }
    },
    [isOpen, onClose, setAddedNewData, strategicPlansId],
  );

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        onClose={() => onClose(!isOpen)}
        scrollBehavior="inside"
        // sizeSpecifyc="2xl"
        title="Adicionar novo posicionamento"
        sizeSpecifyc="2xl"
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Flex flexDirection="column" mt="4">
              <p>Nome</p>
              <Input name="name" />

              <p>Observação</p>
              <Input name="observation" />
            </Flex>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => onClose(!isOpen)}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

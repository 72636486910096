import { Button, Icon } from '@chakra-ui/react';
import { FiList, FiMaximize2, FiCamera, FiPlusCircle } from 'react-icons/fi';
import { OptionList } from './styles';
import { Tooltip } from './Tooltip';

interface Props {
  handleCapturebookmark: () => void;
  onOpen: () => void;
  handleFullScreen: () => void;
  handlePrint: () => void;
}

export function ListOptions({
  handleCapturebookmark,
  onOpen,
  handleFullScreen,
  handlePrint,
}: Props) {
  return (
    <>
      <Tooltip label="Minhas visualizações">
        <OptionList className="nav-item">
          <Button bg="transparent" onClick={() => onOpen()}>
            <Icon as={FiList} size={20} color="white" />
          </Button>
        </OptionList>
      </Tooltip>
      <Tooltip label="Capturar visualização">
        <OptionList className="nav-item">
          <Button bg="transparent" onClick={() => handleCapturebookmark()}>
            <Icon as={FiPlusCircle} size={20} color="white" />
          </Button>
        </OptionList>
      </Tooltip>
      <Tooltip label="Maximizar">
        <OptionList className="nav-item">
          <Button bg="transparent" onClick={() => handleFullScreen()}>
            <Icon as={FiMaximize2} size={20} color="white" />
          </Button>
        </OptionList>
      </Tooltip>
      <Tooltip label="Print">
        <OptionList className="nav-item">
          <Button bg="transparent" onClick={() => handlePrint()}>
            <Icon as={FiCamera} size={20} color="white" />
          </Button>
        </OptionList>
      </Tooltip>
    </>
  );
}

/* eslint-disable import/no-duplicates */
import { useCallback, useRef, useState, useContext } from 'react';
import { Image, ScaleFade, Flex, Box, Spinner, Button } from '@chakra-ui/react';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { Link } from 'react-router-dom';
import { Form } from '@unform/web';

import { FiSearch } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { SetToggleThemeContext } from '../../../contexts/SetToggleThemeContext';
import Input from '../../../components/Global/Input';

import { Container, NicList } from './styles';

import nic_logo from '../../../assets/nic_logo.svg';
import nic_logo_dark from '../../../assets/nic_logo_dark.svg';
import { api } from '../../../services/api';

import { useAuth } from '../../../hooks/auth';

interface Process {
  id: string;
  name: string;
  description: string;
  created_at: string;
}

export function PanelOfStandardsAndManuals() {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [listAllProcess, setListAllProcess] = useState<Process[]>([]);
  const { toggleTheme } = useContext(SetToggleThemeContext);

  const { user } = useAuth();

  const handleListAllProccess = useCallback(() => {
    try {
      setLoading(true);
      api
        .get('/processes-mappings/show-publish', {
          params: {
            sector_id: user.sector_id,
            access_id: user.id,
          },
        })
        .then(response => {
          setListAllProcess(response.data);
          setLoading(false);
        });
    } catch (e) {
      console.log('error', e);
      setLoading(false);
    }
  }, [user.id, user.sector_id]);

  const handleSubmiteSearch = useCallback(data => {
    try {
      setLoading(true);
      api
        .get('/processes-mappings/filter', {
          params: {
            name: data.search,
            take: 999,
            page: 1,
          },
        })
        .then(response => {
          setListAllProcess(response.data.processMapping);
          console.log('resss', response.data);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
      console.log('error', e);
    }
  }, []);

  // const handleTag = useCallback((data, tdata) => {
  //   console.log('tag', { data, tdata });
  // }, []);

  return (
    <>
      <Container>
        <NicList>
          <ScaleFade initialScale={0.9} in>
            <Flex flexDirection="column" alignItems="center">
              <Box>
                {toggleTheme === 'light' ? (
                  <Image src={nic_logo} alt="logo search" />
                ) : (
                  <Image src={nic_logo_dark} alt="logo search" />
                )}
              </Box>

              <Form ref={formRef} onSubmit={handleSubmiteSearch}>
                <Input
                  name="search"
                  type="text"
                  icon={FiSearch}
                  placeholder="Pesquisar"
                />
              </Form>

              <section>
                {/* <Tag
                  size="sm"
                  colorScheme="green"
                  position="absolute"
                  ml="-22px"
                >
                  new
                </Tag>
                <Button
                  colorScheme="blue"
                  m={2}
                  size="sm"
                  type="button"
                  onClick={() => handleTag('manual', 'tag')}
                >
                  Instrução de trabalho
                </Button> */}

                <Button
                  colorScheme="blue"
                  m={2}
                  size="sm"
                  type="button"
                  onClick={handleListAllProccess}
                >
                  Todos
                </Button>
              </section>
            </Flex>
          </ScaleFade>
          <ScaleFade initialScale={0.9} in>
            {loading ? <Spinner /> : ''}

            {listAllProcess.map(proces => (
              <Box
                bg="white"
                mb="15px"
                p="15px"
                borderRadius="6px"
                className="result"
                w="100%"
                key={proces.id}
              >
                <Link to={`/rules/resume-rules?${proces.id}`}>
                  <time>
                    {format(
                      new Date(
                        proces?.created_at ? proces?.created_at : new Date(),
                      ),
                      "'Criado em' dd 'de' MMMM 'de' yyy",
                      {
                        locale: ptBR,
                      },
                    )}
                  </time>
                  <strong>{proces.name}</strong>
                  <p>{proces.description}</p>
                </Link>
              </Box>
            ))}

            {/* <Flex
              alignItems="center"
              flexDirection="column"
              display={searchLoad ? 'flex' : 'none'}
            >
              <Image src={emoji} fontSize="20" />
              <p>Infelizmente nada foi encontrado.</p>
              <p>
                Mas não se preucupe estamos trabalhando para adicionar novas
                informações.
              </p>
            </Flex> */}
          </ScaleFade>
        </NicList>
      </Container>
    </>
  );
}

export const steps = [
  {
    title: 'Etapa 1',
    description: 'Local',
  },
  {
    title: 'Etapa 2',
    description: 'Descrição e Orçamento',
  },
  {
    title: 'Etapa 3',
    description: 'Investimento e retorno',
  },
];

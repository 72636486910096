/* eslint-disable no-constant-condition */
import { Box, Flex, Button, Tag, Tooltip, Text } from '@chakra-ui/react';
import { FiEdit2, FiPower } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface Props {
  authenticationMode: string;
  authorityUri: string;
  scope: string;
  apiUrl: string;
  clientId: string;
  workspaceId: string;
  reportId: string;
  pbiUsername: string;
  pbiPassword: string;
  clientSecret: string;
  tenantId: string;
}

export function ConfigPBI({
  authenticationMode,
  authorityUri,
  scope,
  apiUrl,
  clientId,
  workspaceId,
  reportId,
  pbiUsername,
  pbiPassword,
  tenantId,
  clientSecret,
}: Props) {
  return (
    <>
      <Box bg="gray.800" p="10px" borderRadius="6px" mb="10px">
        <Flex flexDirection="row" justifyContent="space-between">
          <Text as="h2">{pbiUsername}</Text>

          <Flex>
            <Tooltip bg="gray.650" label="Editar">
              <Button
                onClick={() => console.log('perai')}
                type="button"
                colorScheme="green"
                mr="2"
              >
                <FiEdit2 />
              </Button>
            </Tooltip>
            <Tooltip bg="gray.650" label="Desativar">
              <Button
                as={Link}
                to="/administrator/set-data-user"
                onClick={() => console.log('perai')}
                type="button"
                colorScheme="red"
                mr="2"
              >
                <FiPower />
              </Button>
            </Tooltip>
          </Flex>
        </Flex>

        <Flex
          flexDirection="row"
          alignItems="initial"
          textAlign="left"
          maxWidth="1000"
          overflowX="scroll"
        >
          <Flex flexDirection="column">
            <Flex flexDirection="row" alignItems="center">
              <Text fontWeight="medium" color="gray.650" mr="10px">
                Authentication_mode:
              </Text>
              <Text>{authenticationMode}</Text>
            </Flex>
            <Flex flexDirection="row" alignItems="center">
              <Text fontWeight="medium" color="gray.650" mr="10px">
                Scope
              </Text>
              <Text>{scope}</Text>
            </Flex>
            <Flex flexDirection="row" alignItems="center">
              <Text fontWeight="medium" color="gray.650" mr="10px">
                Authority_uri:
              </Text>
              <Text>{authorityUri}</Text>
            </Flex>
          </Flex>

          <Box p="0.8" bg="#eee" ml="5" mr="5" />

          <Flex flexDirection="column" alignItems="initial">
            <Flex>
              <Text fontWeight="medium" color="gray.650" mr="10px">
                Status:
              </Text>
              {true ? (
                <Tag size="sm" colorScheme="green">
                  Ativo
                </Tag>
              ) : (
                <Tag size="sm" colorScheme="red">
                  Inativo
                </Tag>
              )}
            </Flex>

            <Flex>
              <Text fontWeight="medium" color="gray.650" mr="10px">
                Api_url:
              </Text>
              <Text>{apiUrl}</Text>
            </Flex>

            <Flex>
              <Text fontWeight="medium" color="gray.650" mr="10px">
                Client_id:
              </Text>
              <Text>{clientId}</Text>
            </Flex>
          </Flex>

          <Box p="0.8" bg="#eee" ml="5" mr="5" />
          <Flex flexDirection="column" alignItems="initial">
            <Flex>
              <Text fontWeight="medium" color="gray.650" mr="10px">
                Workspace_id:
              </Text>
              <Text>{workspaceId}</Text>
            </Flex>

            <Flex>
              <Text fontWeight="medium" color="gray.650" mr="10px">
                Report_id:
              </Text>
              <Text>{reportId}</Text>
            </Flex>
            <Flex>
              <Text fontWeight="medium" color="gray.650" mr="10px">
                pbi_username:
              </Text>
              <Text>{pbiUsername}</Text>
            </Flex>
          </Flex>
          <Box p="0.8" bg="#eee" ml="5" mr="5" />
          <Flex flexDirection="column" alignItems="initial">
            <Flex>
              <Text fontWeight="medium" color="gray.650" mr="10px">
                pbi_password:
              </Text>
              <Text>{pbiPassword}</Text>
            </Flex>

            <Flex>
              <Text fontWeight="medium" color="gray.650" mr="10px">
                client_secret:
              </Text>
              <Text>{clientSecret}</Text>
            </Flex>
            <Flex>
              <Text fontWeight="medium" color="gray.650" mr="10px">
                tenant_id:
              </Text>
              <Text>{tenantId}</Text>
            </Flex>
          </Flex>
        </Flex>

        {/* </GraphicTitle>
              </CardGraphicText> */}
      </Box>
    </>
  );
}

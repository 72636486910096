import React, { useContext } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { light } from './styles/theme/light';
import { dark } from './styles/theme/dark';
import 'react-toastify/dist/ReactToastify.css';

import { SidebarDrawerProvider } from './contexts/SidebarDrawerContext';
import { SetToggleThemeContext } from './contexts/SetToggleThemeContext';
import { SetTitleBI } from './contexts/SetTitleBIContext';

import GlobalStyle from './styles/global';

import AuthContext from './hooks';

import Routes from './routes';

const queryClient = new QueryClient();

const App: React.FC = () => {
  const { toggleTheme } = useContext(SetToggleThemeContext);

  return (
    <BrowserRouter>
      <AuthContext>
        <QueryClientProvider client={queryClient}>
          <SidebarDrawerProvider>
            <ChakraProvider theme={toggleTheme === 'light' ? light : dark}>
              <GlobalStyle />
              <SetTitleBI>
                <Routes />
              </SetTitleBI>
              <ToastContainer />
            </ChakraProvider>
          </SidebarDrawerProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AuthContext>
    </BrowserRouter>
  );
};

export default App;

import { Text } from '@chakra-ui/react';
import React from 'react';

import { Container, FileInfo } from './styles';

interface FileProps {
  id: string;
  file: string;
  file_url: string;
}

interface FileListProps {
  files: FileProps[];
}

const FileList: React.FC<FileListProps> = ({ files }: FileListProps) => {
  return (
    <Container>
      {files.map(uploadedFile => (
        <li key={uploadedFile.id}>
          <FileInfo>
            <div>
              <a href={uploadedFile.file_url} target="_blank" rel="noreferrer">
                <Text _hover={{ color: 'gray' }}>{uploadedFile.file}</Text>
              </a>
            </div>
          </FileInfo>
        </li>
      ))}
    </Container>
  );
};

export default FileList;

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Flex, ScaleFade, Box, Text, Image, Button } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { HeaderUp } from '../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../components/_components_0.2/Sidebar_0.2';
import Select from '../../../components/Global/Select';
import { Card } from '../../../components/_components_0.2/Card';

import Plan from './InvestimentPlan';

import banner from '../../../assets/bannerpic.svg';
import { api } from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useCan } from '../../../hooks/useCan';

interface IOptions {
  value: string;
  label: string;
}

export default function Pic() {
  const formRef = useRef<FormHandles>(null);
  const [sectors, setSectors] = useState<IOptions[]>([]);
  const [sectorSelected, setSectorSelected] = useState('');

  const userCanSeeDataPic = useCan({
    permissions: ['data_pic'],
  });

  useEffect(() => {
    try {
      api.get('/sectors').then(response => {
        const sectorFormateded: React.SetStateAction<any[]> = [];

        response.data.forEach(function (sector: { id: string; name: string }) {
          const option = {
            value: sector.id,
            label: sector.name,
          };
          sectorFormateded.push(option);
        });

        setSectors(sectorFormateded);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleSubmit = useCallback(data => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        sector: Yup.string().required('setor é obrigatório'),
      });

      schema.validate(data, {
        abortEarly: false,
      });

      setSectorSelected(data.sector);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  }, []);

  return (
    <ScaleFade initialScale={0.9} in>
      <Flex direction="column" h="100vh">
        <HeaderUp path="bi" />
        <Flex my="6" maxWidth={1480} mx="auto" pb={4} px="6" textAlign="center">
          <Sidebar path="bi" />
          <Box>
            <Card minH="xs" p="2">
              <Image src={banner} borderRadius="lg" />
            </Card>

            <Card minH="1xs" mt="15px" bg="transparent">
              <Flex alignItems="center" flexDirection="column">
                <Box>
                  <Box>
                    <Text fontSize="2xl" mb="4" fontWeight="medium">
                      Plano de investimento Cooasgo 2024
                    </Text>

                    <Text>
                      Neste momento você ira preencher o formulário do PIC 2024.
                    </Text>
                  </Box>
                  <Box mt="25px" textAlign="start">
                    <Text fontWeight="black">Considerações importantes</Text>
                    <Text>* O planejamento será encerrado em 20/08/2024.</Text>
                    <Text>
                      * Após a data de encerramento não será possivel preencher
                      tal formulário.
                    </Text>

                    <a
                      href="https://github.com/BRL4528/tracker_detection/files/12677101/MODELO.PADRAO.PIC.2.1.docx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text _hover={{ color: 'gray' }} fontWeight="bold">
                        * Preencha o arquivo de escopo/especificações técnicas
                        para realizar o upload na plataforma (**CLICK AQUI**).
                      </Text>
                    </a>
                  </Box>
                </Box>
              </Flex>
            </Card>

            {userCanSeeDataPic && (
              <Box mt="25px" mb="35px" h="50px" w="500px" textAlign="start">
                <Text>Filtro</Text>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <Box display="flex" flexDirection="row">
                    <Box w="300px" mr="15x">
                      <Select name="sector" options={sectors} />
                    </Box>
                    <Button
                      type="button"
                      colorScheme="gray"
                      ml="5"
                      disabled={sectorSelected === ''}
                      onClick={() => setSectorSelected('')}
                    >
                      Limpar
                    </Button>
                    <Button type="submit" colorScheme="blue" ml="2">
                      Aplicar
                    </Button>
                  </Box>
                </Form>
              </Box>
            )}

            <Box mt="3rem" mb="8rem">
              <Plan />
            </Box>
          </Box>
        </Flex>
      </Flex>
    </ScaleFade>
  );
}

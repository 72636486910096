import { useCallback, useRef, useState } from 'react';
import {
  Button,
  useDisclosure,
  Tooltip,
  ModalBody,
  ModalFooter,
  RadioGroup,
  Radio,
  Text,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useLoading, Oval } from '@agney/react-loading';
import getValidationErrors from '../../../../utils/getValidationErrors';

import { useAuth } from '../../../../hooks/auth';

import Input from '../../../Global/Input';
import TextArea from '../../../Global/TextArea';
import { ModalComponent } from '..';

interface IProcess {
  name: string;
  description: string;
  category: string;
  sector_id: string;
  access_id: string;
}

interface IModalProps {
  handleAddNewProcess: (process: IProcess) => void;
  optVisibility: string;
  userCanSeeDataProcess: Boolean;
  sectorSelected: string;
}

export function ModalAddNewProcess({
  handleAddNewProcess,
  userCanSeeDataProcess,
  sectorSelected,
  optVisibility,
}: IModalProps) {
  const formRef = useRef<FormHandles>(null);
  const { onClose, isOpen, onOpen } = useDisclosure();
  const [value, setValue] = useState('verde');
  const { user } = useAuth();

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: IProcess) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          description: Yup.string().required('Descrição é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { description, name } = data;

        const formatData = {
          description,
          name,
          category: value,
          sector_id:
            userCanSeeDataProcess && sectorSelected !== ''
              ? sectorSelected
              : user.sector_id,
          access_id: user.id,
          visibility: optVisibility,
        };
        handleAddNewProcess(formatData);
        onClose();

        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          setLoading(false);

          formRef.current?.setErrors(errors);

          return;
        }
        onClose();
      }
    },
    [
      handleAddNewProcess,
      onClose,
      optVisibility,
      sectorSelected,
      user.id,
      user.sector_id,
      userCanSeeDataProcess,
      value,
    ],
  );

  const { containerProps, indicatorEl } = useLoading({
    loading,
    indicator: <Oval />,
  });

  return (
    <>
      <Tooltip label="Adicionar novo processo" bg="gray.400">
        <Button
          bg="blue"
          borderRadius="100%"
          padding="15px 15px 15px 15px"
          fontSize="25px"
          color="white"
          position="absolute"
          ml="-25px"
          onClick={() => onOpen()}
        >
          +
        </Button>
      </Tooltip>
      <ModalComponent
        title="Novo procedimento"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <p>Nome</p>
            <Input
              type="text"
              name="name"
              placeholder="Ex: Fechamento de lote suicoper"
              mb="25px"
            />
            <Text mb="10px">Categoria</Text>
            <RadioGroup onChange={setValue} value={value}>
              {/* necessario */}
              <Radio colorScheme="green" name="verde" value="green" mr="10px">
                Verde
              </Radio>
              {/* importante */}
              <Radio
                colorScheme="yellow"
                name="amarelo "
                value="yellow"
                mr="10px"
              >
                Amarelo
              </Radio>
              {/* urgente */}
              <Radio colorScheme="red" name="vermelho" value="red" mb="25px">
                Vermelho
              </Radio>
            </RadioGroup>
            <p>Descrição</p>
            <TextArea
              name="description"
              placeholder="Lembre-se, defina o Período, Envolvidos e o Como."
            />

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                {loading ? (
                  <div {...containerProps} ref={componentRef}>
                    {indicatorEl}
                  </div>
                ) : (
                  'Salvar'
                )}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

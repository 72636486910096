/* eslint-disable prettier/prettier */
import {
  Flex,
  Text,
  Tag,
  Avatar,
  TagLabel,
  Box,
  Button,
  Icon,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FiAlertCircle } from 'react-icons/fi';


interface Props {
  title: string;
  children: ReactNode;
  responsable?: {
    id: string;
    name: string;
  }
}

export function Responsable({
  title,
  responsable,
  children,
}: Props) {


  return (
    <Flex flexDirection="column">
      <Text mb="2">{title}</Text>
      <Box mb="2">

        <Tag
          size="md"
          variant="subtle"
          bgGradient="linear(to-r, teal.600, green.400)"
          borderRadius="full"
          m="1"
        >
          <Avatar
                    // src="https://bit.ly/sage-adebayo"
            size="xs"
            name={responsable?.name}
            ml={-1}
            mr={2}
          />
          <TagLabel
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            w="20"
          >
            {responsable?.name}
          </TagLabel>

          <Button
            variant="ghost"
            p="0"
            m="0"
            size="sm"
          >
            <Icon boxSize="3" as={FiAlertCircle} />
          </Button>

        </Tag>
      </Box>
      <Box maxW="106px" mt="0.2rem" mb="0.5rem">


        {children}
      </Box>
    </Flex>
  );
}

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  PopoverFooter,
} from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import SelectUsers from './SelectUsers';

interface Options {
  value: string;
  label: string;
}

interface Props {
  dataSelectesUsers: Options[];
  setSelectedDataUsers: (value: Options[]) => void;
  handleUpdateParticipants: () => void;
  participants: Participants[];
  setOpenPopover: (openPopover: boolean) => void;
  openPopover: boolean;
}

interface Participants {
  id: string;
  participant: {
    name: string;
    id: string;
  };
}

export function PopoverAddNewParticipant({
  handleUpdateParticipants,
  participants,
  dataSelectesUsers,
  setSelectedDataUsers,
  setOpenPopover,
  openPopover,
}: Props) {
  return (
    <Popover onClose={() => setOpenPopover(!openPopover)} isOpen={openPopover}>
      <PopoverTrigger>
        <Button
          leftIcon={<FiPlus />}
          onClick={() => setOpenPopover(!openPopover)}
          colorScheme="blue"
          size="sm"
        >
          Adicionar
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="gray.700">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Adicionar evolvidos</PopoverHeader>
        <PopoverBody>
          <SelectUsers
            title="Selecione um ou mais envolvidos"
            setSelectedDataUsers={setSelectedDataUsers}
            dataSelectesUsers={dataSelectesUsers}
            participants={participants}
          />
        </PopoverBody>
        <PopoverFooter>
          <Button
            colorScheme="blue"
            size="lg"
            onClick={handleUpdateParticipants}
          >
            Salvar
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  ModalBody,
  ModalFooter,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useLoading, Oval } from '@agney/react-loading';
import { MultiSelect } from 'react-multi-select-component';
import { apllyToast } from '../../../Global/Toast2.0';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Select from '../../../Global/SelectRelease';

import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import { api } from '../../../../services/api';
import { Internationalization } from '../../../../utils/InternationalizationMultSelect';
import { ListPermissions } from '../../Admin/ListPermissions';
import { ListRoles } from '../../Admin/ListRoles';
import { ConfigsAvanced } from './ConfigsAvanced';

interface IUserEdited {
  name: string;
  nickname: string;
  email: string;
  ramal: string;
  phone: string;
  image: string;
  senior_id: string;
  job_position: string;
  sector_id: string;
  access_of_sector?: {
    id: string;
    name: string;
  };
}

interface IProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}

interface IOptions {
  value: string;
  label: string;
  identification?: string;
  disabled?: boolean;
  existingData?: boolean;
}

interface IOptionsSectors {
  value: string;
  label: string;
}

export function ModalEditUser({ id, onClose, isOpen }: IProps) {
  const formRef = useRef<FormHandles>(null);
  const [flag_01, set_flag_01] = useState(false);
  const [initialData, setInitialData] = useState<IUserEdited>();
  const [dataRoles, setDataRoles] = useState<IOptions[]>([]);
  const [dataPermissions, setDataPermissions] = useState<IOptions[]>([]);

  const [dataSelectesRoles, setSelectedDataRoles] = useState<IOptions[]>([]);
  const [dataSelectesPermissions, setSelectedDataPermissions] = useState<
    IOptions[]
  >([]);

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const { containerProps, indicatorEl } = useLoading({
    loading,
    indicator: <Oval />,
  });

  const [sectorFormated, setSectorFormated] = useState<IOptionsSectors[]>([
    {
      label: '',
      value: '',
    },
  ]);

  const [sectorSelected, setSectorSelected] = useState({
    value: '',
    label: '',
  });

  useEffect(() => {
    api.get('/sectors').then(response => {
      const sectorFormateded: React.SetStateAction<any[]> = [];

      response.data.forEach(function (sector: { id: string; name: string }) {
        const option = {
          value: sector.id,
          label: sector.name,
        };
        sectorFormateded.push(option);
      });

      setSectorFormated(sectorFormateded);
    });
  }, []);

  useEffect(() => {
    api.get('/roles').then(resp => {
      const arrayRolesFormated: {
        value: string;
        label: string;
        identification: string;
      }[] = [];

      resp.data.forEach(
        (element: { id: string; name: string; identification: string }) => {
          const goal = {
            value: element.id,
            label: element.name,
            disabled: dataSelectesRoles.some(
              elem => elem.label === element.name,
            ),
            identification: element.identification,
          };

          arrayRolesFormated.push(goal);
        },
      );

      setDataRoles(arrayRolesFormated);
    });
  }, []);

  useEffect(() => {
    api.get('/permissions').then(resp => {
      const arrayPermissionsFormated: {
        value: string;
        label: string;
        identification: string;
      }[] = [];

      resp.data.forEach(
        (element: { id: string; name: string; identification: string }) => {
          const goal = {
            value: element.id,
            label: element.name,
            disabled: dataSelectesPermissions.some(
              elem => elem.label === element.name,
            ),
            identification: element.identification,
          };

          arrayPermissionsFormated.push(goal);
        },
      );

      setDataPermissions(arrayPermissionsFormated);
    });
  }, []);

  useEffect(() => {
    if (isOpen) {
      try {
        if (id !== '') {
          api.get(`/accesses/show?access_id=${id}`).then(response => {
            setInitialData(response.data.access);

            const arrayRolesFormated: { value: string; label: string }[] = [];
            // Preciso ver como resolver o problema das permissões, estou pensando em colocar aq as pemissões formatadas corretamente
            response.data.roles.forEach((element: string) => {
              const goal = {
                label: dataRoles.filter(
                  role => role.identification === element,
                )[0].label,
                value: dataRoles.filter(
                  role => role.identification === element,
                )[0].value,
                existingData: true,
              };
              arrayRolesFormated.push(goal);
            });

            const arrayPermissionsFormated: { value: string; label: string }[] =
              [];

            response.data.permissions.forEach((element: string) => {
              const goal = {
                label: dataPermissions.filter(
                  permission => permission.identification === element,
                )[0].label,
                value: dataPermissions.filter(
                  permission => permission.identification === element,
                )[0].value,
                existingData: true,
              };
              arrayPermissionsFormated.push(goal);
            });
            if (response.data.access.access_of_sector) {
              setSectorSelected({
                value: response.data.access.access_of_sector.id,
                label: response.data.access.access_of_sector.name,
              });
            }
            set_flag_01(response.data.access.flag_1);
            setSelectedDataPermissions(arrayPermissionsFormated);
            setSelectedDataRoles(arrayRolesFormated);
          });
        }
      } catch (err) {
        apllyToast('error', 'Problemas ao carregar dados do usuário');
        console.log(err);
      }
    }
  }, [dataPermissions, dataRoles, id, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setInitialData({
        name: '',
        nickname: '',
        email: '',
        phone: '',
        ramal: '',
        image: '',
        job_position: '',
        sector_id: '',
        senior_id: '',
      });
      setSectorSelected({
        label: '',
        value: '',
      });
    }
  }, [isOpen]);

  const handleSubmit = useCallback(
    async (data: IUserEdited) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string(),
          nickname: Yup.string(),
          email: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        // const { name, nickname, email, phone, ramal, image } = data;
        // const formatData = {
        //   name,
        //   nickname,
        //   email,
        //   phone,
        //   ramal,
        //   image,
        // };

        api.put('/accesses', { ...data, flag_1: flag_01 }).then(() => {
          apllyToast('success', 'Dados atualizados');
        });

        // const arrayRolesFormated: { role_ids: [string] }[] = [];

        const dataRolesFormated = {
          access_id: id,
          roles_ids: dataSelectesRoles
            .filter(role => !role.existingData)
            .map(el => {
              return el.value;
            }),
        };
        if (dataRolesFormated.roles_ids.length > 0) {
          api
            .post('/accesses-of-roles/create-all-roles', dataRolesFormated)
            .then(() => {
              apllyToast('success', 'Funções atualizadas com sucesso');
            });
        }

        const dataPermissionsFormated = {
          access_id: id,
          permissions_ids: dataSelectesPermissions
            .filter(permission => !permission.existingData)
            .map(el => {
              return el.value;
            }),
        };
        if (dataPermissionsFormated.permissions_ids.length > 0) {
          api
            .post(
              '/accesses-of-permissions/create-all-permissions',
              dataPermissionsFormated,
            )
            .then(() => {
              apllyToast('success', 'Permissões atualizadas com sucesso');
            });
        }

        onClose();

        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          setLoading(false);

          formRef.current?.setErrors(errors);
          onClose();

          return;
        }
        onClose();
      }
    },
    [dataSelectesPermissions, dataSelectesRoles, id, onClose, flag_01],
  );

  const handleDeleteRole = useCallback(
    (idRole: string) => {
      try {
        const dataRole = dataRoles.filter(role => role.value === idRole);

        // api.delete('/roles', {
        //   params: {
        //     idUser: id,
        //     idRoles: idRole,
        //   }
        // })
        api
          .delete(
            `/accesses-of-roles?access_id=${id}&role_id=${dataRole[0].value}`,
          )
          .then(() => {
            apllyToast('success', 'Função exluida com sucesso');
          });
        const alreadySelected = dataSelectesRoles.findIndex(
          item => item.value === idRole,
        );

        if (alreadySelected >= 0) {
          const filteredItems = dataSelectesRoles.filter(
            item => item.value !== idRole,
          );

          setSelectedDataRoles(filteredItems);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [dataRoles, dataSelectesRoles, id],
  );

  const handleDeletePermission = useCallback(
    (idPermission: string) => {
      try {
        const dataPermission = dataPermissions.filter(
          permission => permission.value === idPermission,
        );
        api
          .delete(
            `/accesses-of-permissions?access_id=${id}&permission_id=${dataPermission[0].value}`,
          )
          .then(() => {
            apllyToast('success', 'Função exluida com sucesso');
          });
        console.log('Envia para deletar');
        const alreadySelected = dataSelectesPermissions.findIndex(
          item => item.value === idPermission,
        );

        if (alreadySelected >= 0) {
          const filteredItems = dataSelectesPermissions.filter(
            item => item.value !== idPermission,
          );

          setSelectedDataPermissions(filteredItems);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [dataPermissions, dataSelectesPermissions, id],
  );

  const statusPermissions: boolean = useMemo(() => {
    return dataSelectesPermissions.length > 0;
  }, [dataSelectesPermissions.length]);

  const statusRoles: boolean = useMemo(() => {
    return dataSelectesRoles.length > 0;
  }, [dataSelectesRoles.length]);

  return (
    <>
      <ModalComponent
        title="Editar usuário"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
            <p>Nome completo</p>
            <Input name="name" placeholder="Ex: Cristiano Mattei" />

            <p>Nome de acesso</p>
            <Input
              name="nickname"
              type="text"
              placeholder="Exemplo: cristiano.mattei"
            />
            <Select
              name="sector_id"
              label="Setor"
              initialData={sectorSelected}
              options={sectorFormated}
            />

            <p>Cargo</p>
            <Input
              type="text"
              name="job_position"
              placeholder="Exemplo: Vendedor de Varejo l"
            />
            <p>E-mail do usuário</p>
            <Input
              type="email"
              name="email"
              placeholder="Exemplo: antonio.fagundes@.empresa.com.br"
            />
            <p>Telefone</p>
            <Input type="text" name="phone" placeholder="Exemplo: 6791905052" />
            <p>Ramal</p>
            <Input
              type="text"
              name="ramal"
              placeholder="Exemplo: 8008"
              mb="1rem"
            />
            <p>Cod. cadastro senior</p>
            <Input type="text" name="senior_id" placeholder="1258" mb="1rem" />
            <p>Imagem</p>
            <Input name="image" placeholder="https://nome_do_user" />

            <Accordion allowToggle>
              <AccordionItem>
                {statusRoles && (
                  <p>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        Funções atribuidas
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </p>
                )}
                <AccordionPanel pb={4}>
                  {statusRoles
                    ? dataSelectesRoles.map(role => {
                        return (
                          <ListRoles
                            value={role.value}
                            key={role.value}
                            label={role.label}
                            handleDeleteRole={handleDeleteRole}
                          />
                        );
                      })
                    : ''}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <Accordion allowToggle>
              <AccordionItem>
                {statusPermissions && (
                  <p>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        Permissões atribuidas
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </p>
                )}
                <AccordionPanel pb={4}>
                  {statusPermissions
                    ? dataSelectesPermissions.map(permission => {
                        return (
                          <ListPermissions
                            value={permission.value}
                            key={permission.value}
                            label={permission.label}
                            handleDeletePermission={handleDeletePermission}
                          />
                        );
                      })
                    : ''}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Box mt="1rem">
              <p>Atribuir novas funções</p>
              <MultiSelect
                options={dataRoles}
                value={dataSelectesRoles}
                valueRenderer={selected => {
                  return selected.length
                    ? selected.map(({ label }) => `✔️ ${label}`)
                    : '😶 Nehuma função selecionado';
                }}
                onChange={setSelectedDataRoles}
                labelledBy="Selecione"
                overrideStrings={Internationalization}
              />

              <p>Atribuir novas permissões</p>
              <MultiSelect
                options={dataPermissions}
                value={dataSelectesPermissions}
                valueRenderer={selected => {
                  return selected.length
                    ? selected.map(({ label }) => `✔️ ${label}`)
                    : '😶 Nehuma permissão selecionado';
                }}
                onChange={setSelectedDataPermissions}
                labelledBy="Selecione"
                overrideStrings={Internationalization}
              />
            </Box>

            <Box mt="1rem">
              <ConfigsAvanced set_flag_01={set_flag_01} flag_01={flag_01} />
            </Box>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                {loading ? (
                  <div {...containerProps} ref={componentRef}>
                    {indicatorEl}
                  </div>
                ) : (
                  'Salvar'
                )}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

import {
  Flex,
  Text,
  Button,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { HeaderUp } from '../../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../../components/_components_0.2/Sidebar_0.2';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../../components/Global/Toast2.0';

export function Automations() {
  const handleAutomationAction = useCallback(() => {
    try {
      api.post('/send-email-indicators-actions').then(() => {
        apllyToast('success', 'Sucesso ao executar automação');
      });
    } catch (err) {
      apllyToast('error', 'Sucesso ao executar automação');
      console.log('Error', err);
    }
  }, []);
  return (
    <>
      <HeaderUp path="adm" />

      <Flex w="100%" maxWidth={1480} mx="auto" pb={4} px="6">
        <Sidebar path="adm" />

        <Accordion allowToggle w="100%" mt="20">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Envio de email para ações pendentes
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>

            <AccordionPanel pb={4}>
              <Flex alignItems="center" flexDirection="column">
                <Text color="gray.500">
                  Executa uma verificação de todas as as Ações do planejamento
                  estratégico, verificando as ações que estão prestes a vencer,
                  listando essas ações ´encaminhado um email para os
                  responsáveis das ações, comunicando o vencimento da ação.
                </Text>
                <Button
                  onClick={() => handleAutomationAction()}
                  colorScheme="blue"
                  mt="10"
                >
                  Executar automação
                </Button>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  Section 2 title
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </>
  );
}

import { Box, Stack } from '@chakra-ui/react';
import { PlansTable } from './PlansTable';

export function TableForAction() {
  return (
    <>
      <Box
        bg="bg-surface"
        boxShadow={{ base: 'none', md: 'sm' }}
        borderRadius={{ base: 'none', md: 'lg' }}
        maxWidth="90%"
        pb="5"
        w="100%"
      >
        <Stack spacing="5">
          <PlansTable />
        </Stack>
      </Box>
    </>
  );
}

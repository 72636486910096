import parse from 'html-react-parser';
import {
  Box,
  Stack,
  StackDivider,
  HStack,
  Avatar,
  AvatarBadge,
  Text,
} from '@chakra-ui/react';
import { handleFormatDate } from '../../../../utils/formarDate';

interface CommitProps {
  name: string;
  content: string;
  created: string;
  nick: string;
}

export function Commits({ content, created, name, nick }: CommitProps) {
  // const contentFormated = useMemo(() => {

  //   const parser = new DOMParser();
  //   const html = parser.parseFromString(content, 'text/html');

  //   return html.body;
  // }, [content]);

  // console.log('contentFormated', contentFormated);
  return (
    <Box
      // textAlign="center"
      borderRadius="6px"
      bg="gray.100"
      mb="15px"
    >
      <Box bg="bg.surface" py="4">
        <Stack divider={<StackDivider />} spacing="4">
          <Stack fontSize="sm" px="4" spacing="4">
            <Stack direction="row" justify="space-between" spacing="4">
              <HStack spacing="3">
                <Avatar name={name} boxSize="10">
                  <AvatarBadge boxSize="4" bg="success" />
                </Avatar>
                <Box>
                  <Text fontWeight="medium" color="fg.emphasized">
                    {name}
                  </Text>
                  <Text color="fg.muted">{nick}</Text>
                </Box>
              </HStack>
              <Text color="fg.muted">{handleFormatDate(created)}</Text>
            </Stack>
            <Text
              color="fg.muted"
              sx={{
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': '2',
                // overflow: 'hidden',
                // display: '-webkit-box',
              }}
            >
              {parse(content)}
            </Text>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';

import { FiSettings } from 'react-icons/fi';

import logoImg from '../../../assets/logo.svg';

import { SetToggleThemeContext } from '../../../contexts/SetToggleThemeContext';
import { Container, OptionList } from './styles';
import './styles.css';

interface LayoutProps {
  pathname: string;
  children: React.ReactNode;
}

const Sidebar: React.FC<LayoutProps> = ({ pathname, children }) => {
  const { toggleTheme } = useContext(SetToggleThemeContext);

  return (
    <Container theme={toggleTheme}>
      <nav id="noPrint" className="navbar">
        <ul className="navbar-nav">
          <li className="logo">
            <a href="#" className="nav-header">
              <span className="link-text logo-text">
                <img className="img-logo" src={logoImg} alt="Samasc" />
              </span>
              <svg
                width="24"
                height="24"
                aria-hidden="true"
                focusable="false"
                data-prefix="fad"
                data-icon="angle-double-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
              >
                <g className="fa-group">
                  <path
                    fill="currentColor"
                    d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                    className="fa-secondary"
                  />
                  <path
                    fill="currentColor"
                    d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                    className="fa-primary"
                  />
                </g>
              </svg>
            </a>
          </li>

          {children}

          <OptionList
            pathname={pathname}
            path="/import"
            className="nav-item"
            visible
            theme={toggleTheme}
          >
            <button
              type="button"
              onClick={() => console.log('teste')}
              className="nav-link"
            >
              <FiSettings color="#3182ce" size={16} />

              <span className="link-text">Configurações</span>
            </button>
          </OptionList>
        </ul>
      </nav>
    </Container>
  );
};

export default Sidebar;

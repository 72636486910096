interface FileList {
  id: string;
  file: string;
  file_url: string;
}

interface Props {
  initialData: {
    id: string;
    investment_name: string;
    branch_id: string;
    sector_id: string;
    category: string;
    responsible_id: string;
    priority: string;
    investment_type: string;
    justification: string;
    investment_description: string;
    labor: string;
    description_project: string;
    value_total: string;
    investment_return: string;
    situation: string;
    when: string;
    investments_plans_of_files: FileList[];
    pic_related: boolean;
    pic_repeat: boolean;
    other_expenses: number;
    charges: number;
  };
  setInvestment_name: (name: string) => void;
  setBranch_id: (id: string) => void;
  setSector_id: (id: string) => void;
  setCategory: (id: string) => void;
  setResponsible_id: (id: string) => void;
  setinvestment_type: (id: string) => void;
  setPriority: (id: string) => void;
  setWhen: (date: string) => void;
  setJustification: (id: string) => void;
  setDescription_project: (id: string) => void;
  setInvestment_description: (id: string) => void;
  setlabor: (type: string) => void;
  setValue_total: (value: number) => void;
  setInvestment_return: (data: string) => void;
  setSituation: (data: string) => void;
  setFileList: (files: FileList[]) => void;
  setPicRelated: (data: boolean) => void;
  setPicRepeat: (data: boolean) => void;
  setOtherExpenses: (data: number) => void;
  setCharges: (data: number) => void;
}

export function initialDataSelect({
  initialData,
  setBranch_id,
  setCategory,
  setPriority,
  setResponsible_id,
  setSector_id,
  setinvestment_type,
  setInvestment_name,
  setWhen,
  setJustification,
  setDescription_project,
  setInvestment_description,
  setlabor,
  setValue_total,
  setInvestment_return,
  setSituation,
  setFileList,
  setPicRelated,
  setPicRepeat,
  setOtherExpenses,
  setCharges,
}: Props) {
  console.log('teste', initialData);
  if (initialData.branch_id !== null) {
    setBranch_id(initialData.branch_id);
  }
  if (initialData.sector_id !== null) {
    setSector_id(initialData.sector_id);
  }
  if (initialData.priority !== null) {
    setPriority(initialData.priority);
  }
  if (initialData.responsible_id !== null) {
    setResponsible_id(initialData.responsible_id);
  }
  if (initialData.category !== null) {
    setCategory(initialData.category);
  }
  if (initialData.investment_type !== null) {
    setinvestment_type(initialData.investment_type);
  }
  if (initialData.investment_name !== null) {
    setInvestment_name(initialData.investment_name);
  }

  if (initialData.justification !== null) {
    setJustification(initialData.justification);
  }
  if (initialData.description_project !== null) {
    setDescription_project(initialData.description_project);
  }
  if (initialData.investment_description !== null) {
    setInvestment_description(initialData.investment_description);
  }
  if (initialData.labor !== null) {
    setlabor(initialData.labor);
  }
  if (initialData.value_total !== null) {
    setValue_total(Number(initialData.value_total));
  }
  if (initialData.investment_return !== null) {
    setInvestment_return(initialData.investment_return);
  }
  if (initialData.situation !== null) {
    setSituation(initialData.situation);
  }
  if (initialData.when !== null) {
    setWhen(initialData.when);
  }
  if (initialData.investments_plans_of_files !== null) {
    setFileList(initialData.investments_plans_of_files);
  }
  if (initialData.pic_related !== null) {
    setPicRelated(initialData.pic_related);
  }
  if (initialData.pic_repeat !== null) {
    setPicRepeat(initialData.pic_repeat);
  }
  if (initialData.other_expenses !== null) {
    setOtherExpenses(initialData.other_expenses);
  }
  if (initialData.charges !== null) {
    setCharges(initialData.charges);
  }

  //   // setInvestmentSelected(formatedData);
  // }
}

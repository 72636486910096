import { useCallback, useRef, useState } from 'react';
import { Button, ModalBody, ModalFooter } from '@chakra-ui/react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useLoading, Oval } from '@agney/react-loading';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalAddNewConfigBI({ onClose, isOpen }: Props) {
  const formRef = useRef<FormHandles>(null);

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          pbiUsername: Yup.string().required('pbiUsername é obrigatória'),
          scope: Yup.string().required('scope é obrigatória'),
          authorityUri: Yup.string().required('authorityUri é obrigatório'),
          apiUrl: Yup.string().required('apiUrl é obrigatório'),
          clientId: Yup.string().required('clientId é obrigatória'),
          workspaceId: Yup.string().required('workspaceId é obrigatório'),
          reportId: Yup.string().required('reportId é obrigatório'),
          pbiPassword: Yup.string().required('pbiPassword é obrigatório'),
          clientSecret: Yup.string().required('clientSecret é obrigatório'),
          tenantId: Yup.string().required('tenantId é obrigatório'),
          authenticationMode: Yup.string().required(
            'authenticationMode é obrigatório',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api.post('/power-bi-configs', data).then(() => {
          apllyToast('success', 'Sucesso ao adicionar novas configurações');
        });
        onClose();
        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          apllyToast('error', 'Problemas ao adicionar novas configurações');
          setLoading(false);
          onClose();

          formRef.current?.setErrors(errors);
        }
      }
    },
    [onClose],
  );

  const { containerProps, indicatorEl } = useLoading({
    loading,
    indicator: <Oval />,
  });

  return (
    <>
      <ModalComponent
        title="Configurações de PBI"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <p>PBI_username</p>
            <Input type="text" name="pbiUsername" />
            <p>AuthenticationMode</p>
            <Input type="text" name="authenticationMode" />
            <p>AuthorityUri</p>
            <Input type="text" name="authorityUri" />
            <p>Scope</p>
            <Input type="text" name="scope" />
            <p>Api_url</p>
            <Input type="text" name="apiUrl" />
            <p>Client_id</p>
            <Input type="text" name="clientId" />
            <p>Workspace_id</p>
            <Input type="text" name="workspaceId" />
            <p>Report_id</p>
            <Input type="text" name="reportId" />
            <p>PBI_password</p>
            <Input type="text" name="pbiPassword" />
            <p>Client_secret</p>
            <Input type="text" name="clientSecret" />
            <p>Tenant_id</p>
            <Input type="text" name="tenantId" />
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                {loading ? (
                  <div {...containerProps} ref={componentRef}>
                    {indicatorEl}
                  </div>
                ) : (
                  'Salvar'
                )}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

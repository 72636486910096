import { useQuery } from 'react-query';
import { IinvestimentPlan } from '../pages/Dashboard/Pic/InvestimentPlan';
import { api } from '../services/api';

interface Options {
  value: string;
  label: string;
}

interface Props {
  page: number;
  setResponsableData: (data: Options[]) => void;
  investment_id: string;
  responsible_id: string;
  lane: string;
  deletedsInvestments: boolean;
  sector_filter_id: string;
  category_name: string;
  year: string;
  pic_repeat: boolean;
}

export function usePlans({
  page,
  responsible_id,
  lane,
  deletedsInvestments,
  sector_filter_id,
  investment_id,
  category_name,
  year,
  pic_repeat,
}: Props) {
  return useQuery(
    [
      'actions',
      page,
      responsible_id,
      lane,
      deletedsInvestments,
      sector_filter_id,
      investment_id,
      category_name,
      year,
      pic_repeat,
    ],
    async () => {
      const response = await api.get<IinvestimentPlan>(
        '/investments-plans/filter',
        {
          params: {
            take: 20,
            page,
            situation: lane,
            investment_id,
            status: deletedsInvestments,
            sector_id: sector_filter_id,
            category: category_name,
            year,
            pic_repeat,
          },
        },
      );
      return response.data;
    },
  );
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useRef, useCallback } from 'react';
import { useReport } from 'powerbi-report-component';

// import { layoutSettings } from '../../../../utils/stylesOfReportPowerBI';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';

import { Container, DivLeft } from './styles';
// import { apiPowerBI } from '../../../../services/api';

import {
  layoutSettings,
  // layoutSettingsLandscape,
  // layoutSettingsPortrait,
} from '../../../../utils/stylesOfReportPowerBI';

// interface ReportData {
//   reportId: string;
//   embedUrl: string;
// }

// interface PropsPowerBI {
//   accessToken: string;
//   expiry: string;
//   reportId: string;
//   embedUrl: string;
// }

export default function ReportConectBI() {
  const reportRef = useRef(null);
  const [report, setEmbed] = useReport();

  useEffect(() => {
    async function loadTokenBI(): Promise<void> {
      const initialReportProps = {
        type: 'report',
        embedType: 'report',
        tokenType: 'Aad',
        accessToken:
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSIsImtpZCI6IjJaUXBKM1VwYmpBWVhZR2FYRUpsOGxWMFRPSSJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvMjQxY2YxZDMtNTRlZC00OWFlLWFhMzctNjc3ZTE3NzEzYzY4LyIsImlhdCI6MTY2MjQwOTgwMiwibmJmIjoxNjYyNDA5ODAyLCJleHAiOjE2NjI0MTUwNTEsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVFFBeS84VEFBQUFXVHUxY3IzczBVMDUrdkpwRHgxa3VOTHV5V3NSOXVKYkZwcHhMdUhBTDR1K0kvRm96RlRibVgyZ3oxNEV1ZU0vIiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6Ijg3MWMwMTBmLTVlNjEtNGZiMS04M2FjLTk4NjEwYTdlOTExMCIsImFwcGlkYWNyIjoiMiIsImlwYWRkciI6IjE3Ny4yMDEuNjcuMTc4IiwibmFtZSI6InBiaSIsIm9pZCI6IjQ3YmFjYzRmLWJhYWEtNDhkYy1hZWRiLTU3NDBmZTFmODM0ZSIsInB1aWQiOiIxMDAzMjAwMjI5ODFFOEQ2IiwicmgiOiIwLkFWa0EwX0VjSk8xVXJrbXFOMmQtRjNFOGFBa0FBQUFBQUFBQXdBQUFBQUFBQUFDZEFEYy4iLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiJKSHp2VzF3NjQtWmo1UTNJY3Znck5YTUoyb3kwalIyaHZmd3lOVWpyVTdRIiwidGlkIjoiMjQxY2YxZDMtNTRlZC00OWFlLWFhMzctNjc3ZTE3NzEzYzY4IiwidW5pcXVlX25hbWUiOiJwYmlFbWJlZGVkQHBiaW1pZGFzdGVzdGhvdG1haWwub25taWNyb3NvZnQuY29tIiwidXBuIjoicGJpRW1iZWRlZEBwYmltaWRhc3Rlc3Rob3RtYWlsLm9ubWljcm9zb2Z0LmNvbSIsInV0aSI6IlVHWXMwQlVhWEVlUzJ3OXdFS0ZUQVEiLCJ2ZXIiOiIxLjAiLCJ3aWRzIjpbImE5ZWE4OTk2LTEyMmYtNGM3NC05NTIwLThlZGNkMTkyODI2YyIsImI3OWZiZjRkLTNlZjktNDY4OS04MTQzLTc2YjE5NGU4NTUwOSJdfQ.UlkuxnZXRFvv4OYOVkIAWEV4OEiNMyFFxeLqnpLtMmH6U48czpequdOEls_9xYwJOC5CmW01Vy0H7RagplyhZEllwWz5tr8kseFKdj5cP3OMPKqnF4YslOyNHqdMjCCBoqavvEZEyteMUxmXi7KvEV9a3Y7na2THMgl0bPvrtSfogV8D4i6KUytXO20GL7LwPfDV5bksNzWTTk45Kq95TU5QVMYqopjgX3GV1ht22-t4Gfpu9CFTALAEoXLpeQUhpj2AyYqsG9Q6x7lE8zVV73sk61wynuN5SYSizoWehj5m8Oyu_goNTpnWOyeUIFap0LTi-Y4iJ9IZJ3ZjePFfuA',
        embedUrl:
          'https://app.powerbi.com/reportEmbed?reportId=504a3f15-2ec9-45fa-951c-757d4febfb78&groupId=1a8b1882-6b2d-47fc-bf95-89b566cc6ae7&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19',
        embedId: '504a3f15-2ec9-45fa-951c-757d4febfb78',
        reportMode: 'View', // "Edit"
        permissions: 'All', // "All" (when using "Edit" mode)
        extraSettings: {
          filterPaneEnabled: true,
          navContentPaneEnabled: true,
        },
      };

      // @ts-ignore
      setEmbed(reportRef, initialReportProps);
    }
    loadTokenBI();
  }, [setEmbed]);

  const handleClick = () => {
    if (report) report.print();
  };

  const setFullscreen = useCallback(() => {
    if (report) report.fullscreen();
  }, [report]);

  return (
    <>
      <DivLeft>
        <span id="noPrint">
          <Menu
            menuButton={<MenuButton>Opções</MenuButton>}
            className="my-menu"
          >
            <MenuItem onClick={handleClick}>Imprimir</MenuItem>
            <MenuItem onClick={setFullscreen}>FullScreen</MenuItem>

            <MenuItem>Relatar erro</MenuItem>
          </Menu>
        </span>
      </DivLeft>
      <Container load={false}>
        <div className="report" style={layoutSettings()} ref={reportRef} />
      </Container>
    </>
  );
}

/* eslint-disable import/no-duplicates */
import { useEffect, useState } from 'react';
import SunEditor from 'suneditor-react/dist/SunEditor';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { api } from '../../../../services/api';
import { optRestrict } from '../../../../utils/optionsSunEditor';
import { Container, Nic } from './styles';

interface Process {
  name: string;
  contents: string;
  created_at: string;
}

export function SelectedProces() {
  const parsed = window.location.search;
  const [dataProcess, setDataProcess] = useState<Process>();

  useEffect(() => {
    try {
      api
        .get('/processes-mappings/show', {
          params: {
            id: parsed.substring(1),
          },
        })
        .then(response => {
          console.log('response: ', response.data);
          setDataProcess(response.data);
        });
    } catch (e) {
      console.log('Error', e);
    }
  }, [parsed]);

  return (
    <>
      <Container>
        <Nic theme="light">
          <>
            <h1>{dataProcess?.name}</h1>
            <time>
              Efetivado em{' '}
              {format(
                new Date(
                  dataProcess?.created_at
                    ? dataProcess?.created_at
                    : new Date(),
                ),
                "'Dia' dd 'de' MMMM 'de' yyy",
                {
                  locale: ptBR,
                },
              )}
            </time>

            <SunEditor
              setDefaultStyle="font-family:'Roboto', sans-serif"
              setOptions={optRestrict}
              setContents={dataProcess?.contents}
              disable
              hideToolbar
            />
          </>
        </Nic>
      </Container>
    </>
  );
}

import React, { FormEvent, useState } from 'react';
import { Center } from '@chakra-ui/react';
import axios from 'axios';

export function Test() {
  const [files, setFiles] = useState<FileList | null>(null);
  const [filesUrl, setUrlFiles] = useState<string>('');

  async function handleUploadFile(e: FormEvent) {
    try {
      e.preventDefault();

      if (!files || files.length === 0) {
        return;
      }

      const file = files[0];
      console.log('filesUrl', filesUrl);
      await axios
        .put(`${filesUrl}`, file, {
          headers: {
            'Content-Type': 'video/mp4',
          },
        })
        .then(response => {
          console.log('Resposta', response.data);
        });
    } catch (err) {
      console.log('Error');
    }
  }
  console.log('url', filesUrl);

  return (
    <>
      <Center mt="80px">
        <form onSubmit={handleUploadFile}>
          <input
            type="file"
            name="file"
            onChange={e => setFiles(e.target.files)}
          />

          <button type="submit">Upload</button>
        </form>

        <input name="url" onChange={e => setUrlFiles(e.target.value)} />
      </Center>
    </>
  );
}

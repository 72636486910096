/* eslint-disable @typescript-eslint/no-empty-function */
import { useRef } from 'react';
import {
  ScaleFade,
  Flex,
  Box,
  Text,
  Center,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FiSearch } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Global/Input';
import { HeaderUp } from '../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../components/_components_0.2/Sidebar_0.2';
import { ModalFilterSchedule } from '../../../components/_components_0.2/Modal/ModalFilterSchedule';

export function ScheduleList() {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const formRef = useRef<FormHandles>(null);
  return (
    <Flex direction="column" h="100vh">
      <HeaderUp path="adm" />
      <Flex my="6" maxWidth={1480} mx="auto" pb={4} px="6" textAlign="center">
        <Sidebar path="bi" />
        <ScaleFade initialScale={0.9} in>
          <Center>
            <Form ref={formRef} onSubmit={() => {}}>
              <Flex flexDirection="column" alignItems="center" w="30rem">
                <Box>
                  <Text>Agenda COOASGO</Text>
                </Box>

                <Input
                  as={Button}
                  name="search"
                  type="text"
                  icon={FiSearch}
                  placeholder="Pesquisar"
                  onClick={onOpen}
                  w="100%"
                  display="flex"
                />
              </Flex>
            </Form>
          </Center>
        </ScaleFade>
        <ModalFilterSchedule onClose={onClose} isOpen={isOpen} />
      </Flex>
    </Flex>
  );
}

// import { useCallback, useEffect, useState } from 'react';
// import { useAuth } from '../../hooks/auth';
import MenuListOS from '../../../../components/Global/MenuListOS';
// import { apllyToast } from '../../components/Global/Toast2.0';

// import { ModalUpdateDataUser } from '../../components/_components_0.2/Modal/ModalUpdateDataUser';
// import { api } from '../../services/api';

// interface DataUpdate {
//   sector_id: string;
// }

const Dashboard = () => {
  // const { user, updateUser } = useAuth();
  // const [updateSectorUser, setUpdateSectorUser] = useState(false);

  // useEffect(() => {
  //   if (user.sector_id === null) {
  //     setUpdateSectorUser(false);
  //   }
  // }, [user]);

  // const handleUpdateUser = useCallback(
  //   (data: DataUpdate) => {
  //     const { sector_id } = data;
  //     const formatData = {
  //       sector_id,
  //       nickname: user.nickname,
  //     };

  //     try {
  //       api.put('/accesses', formatData).then(response => {
  //         updateUser(response.data);
  //         apllyToast('success', 'Dados atualizados com sucesso');
  //       });
  //     } catch (err) {
  //       console.log(err);
  //       apllyToast(
  //         'error',
  //         'Problemas ao atualizar dados, consulte o desenvolvedor',
  //       );
  //     }
  //   },
  //   [updateUser, user.nickname],
  // );

  return (
    <>
      {/* <ModalUpdateDataUser
        updateSectorUser={updateSectorUser}
        handleUpdateUser={handleUpdateUser}
        setUpdateSectorUser={setUpdateSectorUser}
      /> */}
      <MenuListOS />
    </>
  );
};

export default Dashboard;

/* eslint-disable react/jsx-curly-newline */
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Flex,
  FormLabel,
  Collapse,
  Box,
  Switch,
  CheckboxGroup,
  Stack,
  Checkbox,
  Text,
  Tooltip,
  Tag,
  Button,
  SimpleGrid,
  useDisclosure,
  ButtonGroup,
  PopoverFooter,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
} from '@chakra-ui/react';

import { FiX, FiPlus } from 'react-icons/fi';
import SunEditor from 'suneditor-react';
import SunEditorCore from 'suneditor/src/lib/core';
import Input from '../../../Global/Input';
import { optGlobal } from '../../../../utils/optionsSunEditor';

import { Alert } from '../../Alert';

import 'suneditor/dist/css/suneditor.min.css';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';
import { RevisionsHistory } from './RevisionsHistoryItem';
import { useCan } from '../../../../hooks/useCan';

interface Props {
  process_name: string;
  process_id: string;
  setPublicFile: (publicFile: boolean) => void;
  setContentsData: (data: string) => void;
  setHandleChangeSunEditor: (handle: boolean) => void;
  setAlertChangeSunEditor: (handle: boolean) => void;
  setOptVisibility: (visibility: string) => void;
  setUpdateNewRevisionHistory: (revision_id: string) => void;
  optVisibility: string;
  updateNewRevisionHistory: string;
  publicFile: boolean;
  changeAlertSunEditor: boolean;
  sector_id: string;
  contents?: string;
}

interface Sectors {
  id: string;
  name: string;
}

interface Tags {
  id: string;
  tag: string;
  description: string;
}

export function PublishProcedure({
  process_name,
  process_id,
  setPublicFile,
  publicFile,
  sector_id,
  contents,
  setContentsData,
  setHandleChangeSunEditor,
  changeAlertSunEditor,
  setAlertChangeSunEditor,
  setOptVisibility,
  optVisibility,
  updateNewRevisionHistory,
  setUpdateNewRevisionHistory,
}: Props) {
  const editor = useRef<SunEditorCore>();

  const [sectorSelected, setSectorSelected] = useState<Sectors>();
  const [onChange, setOnChange] = useState('');
  const [dataTags, setDataTags] = useState<Tags[]>([]);
  const [updateNewTags, setUpdateNewTags] = useState('');
  const [loadingTag, setLoadingTag] = useState(false);
  // const [imgGalery, setImageGalery] = useState('');

  const { onOpen, onClose, isOpen } = useDisclosure();

  const userCanProcedureEditor = useCan({
    permissions: ['user_procedure_editor'],
  });

  // useEffect(() => {
  //   try {
  //     api
  //       .get(
  //         `https://samasc.cooasgo.com.br:3333/processes-mappings-files-manager/show?process_mapping_id=${process_id}`,
  //       )
  //       .then(response => {
  //         console.log('response', response.data);
  //         setImageGalery(response.data);
  //       });
  //   } catch (e) {
  //     console.log('error', e);
  //   }
  // }, [process_id]);

  useEffect(() => {
    try {
      if (sector_id) {
        api
          .get<Sectors>(`sectors/show?sector_id=${sector_id}`)
          .then(response => {
            setSectorSelected(response.data);
          });
      }
    } catch (e) {
      console.log('error', e);
    }
  }, [sector_id]);

  useEffect(() => {
    try {
      api
        .get('/tags/show', {
          params: {
            process_mapping_id: process_id,
          },
        })
        .then(response => {
          setDataTags(response.data);
          setLoadingTag(false);
        });
    } catch (e) {
      console.log('error', e);
    }
  }, [process_id, updateNewTags]);

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor;
  };

  const handleSave = useCallback(
    data => {
      try {
        setContentsData(data);
        setHandleChangeSunEditor(false);
      } catch (e) {
        console.log('error', e);
      }
    },
    [setContentsData, setHandleChangeSunEditor],
  );

  const handleChangeSunEditor = useCallback(() => {
    setHandleChangeSunEditor(true);
  }, [setHandleChangeSunEditor]);

  const handleData = useCallback(() => {
    editor.current?.onSave;
    setAlertChangeSunEditor(false);
  }, [setAlertChangeSunEditor]);

  const handleSubmitTag = useCallback(() => {
    try {
      setLoadingTag(true);
      if (onChange !== '') {
        if (dataTags.filter(data => data.tag === onChange).length === 0) {
          api
            .post('/tags', {
              tag: onChange,
              description: 'Tag padrão SAMASC',
              location: 'process_mapping',
              process_mapping_id: process_id,
            })
            .then(response => {
              setUpdateNewTags(response.data.id);
            });
        } else {
          apllyToast('warning', 'Tag ja existente!');
          setLoadingTag(false);
        }
      }
    } catch (e) {
      apllyToast('error', 'Problemas ao carregar tag.');
      console.log('error', e);
      setLoadingTag(false);
    }
  }, [dataTags, onChange, process_id]);

  const handleOnChange = useCallback(data => {
    setOnChange(data.target.value);
  }, []);
  const handleDelete = useCallback((id: string) => {
    try {
      api
        .delete('/tags', {
          params: {
            id,
          },
        })
        .then(response => {
          apllyToast('success', 'Tag excluida.');
          console.log('retorno', response.data);

          const formData = {
            ms: response.data,
            id,
          };
          setUpdateNewTags(JSON.stringify(formData));
        });
    } catch (e) {
      console.log('error', e);
      apllyToast('error', 'Problemas ao excluir tag.');
    }
  }, []);

  function onImageUploadBefore() {
    return (files: any, _info: any, uploadHandler: any) => {
      (async () => {
        console.log('teste');
        const formData = new FormData();
        formData.append('file', files[0]);

        const { data } = await api.post(
          `/processes-mappings-files-manager?process_mapping_id=${process_id}&tag=${process_name}&alt=teste`,
          formData,
        );
        console.log('data', data);
        const res = {
          result: [
            {
              url: data.src,
              name: `${process_name}-${files[0].name}`,
            },
          ],
        };

        uploadHandler(res);
      })();

      // called here for stop double image
      uploadHandler();
    };
  }

  return (
    <>
      <Alert
        isOpen={changeAlertSunEditor}
        onClose={() => setAlertChangeSunEditor(false)}
        handleFunction={handleData}
        title="Salve a publicação e adicione uma nova revisão!"
        textBody="Você esta preste a salvar o procedimento sem ter salvo a publicação e não ter adicionado nenhuma revisão, solucione estes problemas para seguir com o procedimento"
        ButonSave="Fechar"
        ButonClose=""
      />
      <Flex flexDirection="row" mb="25px">
        <FormLabel htmlFor="file" mb="0">
          Publicar procedimento?
        </FormLabel>
        <Switch
          id="file"
          isChecked={publicFile}
          onChange={() => setPublicFile(!publicFile)}
          isDisabled={process_id === ''}
        />
      </Flex>
      <Collapse in={publicFile} animateOpacity>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          bg="gray.750"
          p="5"
          borderRadius="6px"
          mb="10"
        >
          <Box display="flex" flexDirection="row" w="100%">
            {userCanProcedureEditor && (
              <RevisionsHistory
                process_mapping_id={process_id}
                setUpdateNewRevisionHistory={setUpdateNewRevisionHistory}
                updateNewRevisionHistory={updateNewRevisionHistory}
              />
            )}

            <Box m="auto">
              <p>
                Seu procedimento sera publicado no painel de normas e manuais
              </p>
              <SunEditor
                getSunEditorInstance={getSunEditorInstance}
                setDefaultStyle="font-family:'Roboto', sans-serif"
                setOptions={{
                  ...optGlobal,
                  // imageGalleryUrl: imgGalery,
                }}
                onSave={handleSave}
                setContents={contents}
                onKeyUp={handleChangeSunEditor}
                disable={!userCanProcedureEditor}
                hideToolbar={!userCanProcedureEditor}
                // onImageUpload={(a, b, c, d, e) => uploadRes(a, b, c, d, e)}
                onImageUploadBefore={onImageUploadBefore()}
              />
            </Box>
          </Box>

          <Box mt="10">
            <Flex flexDirection="row" mt="5">
              <Text mr="2">Setor:</Text>
              <Text fontWeight="bold">{sectorSelected?.name}</Text>
            </Flex>

            <Text mb="3">
              Selecione o modo de visualização deste procediemento.
            </Text>

            <CheckboxGroup
              colorScheme="blue"
              defaultValue={[optVisibility]}
              value={[optVisibility]}
            >
              <Stack spacing={[1, 5]} direction={['column', 'row']}>
                <Checkbox
                  value="visibleToMe"
                  onChange={() => setOptVisibility('visibleToMe')}
                  // isChecked={!optVisibility.includes('visibleToMe')}
                >
                  Visivel apenas para mim
                </Checkbox>
                <Checkbox
                  value="visibleToMySector"
                  onChange={() => setOptVisibility('visibleToMySector')}
                  // isChecked={!optVisibility.includes('visibleToMySector')}
                >
                  Visivel apenas o para meu setor
                </Checkbox>
                <Checkbox
                  value="visibleToAllSectors"
                  onChange={() => setOptVisibility('visibleToAllSectors')}
                  // isChecked={!optVisibility.includes('visibleToMySector')}
                >
                  Visivel para todos os setores
                </Checkbox>
              </Stack>
            </CheckboxGroup>
          </Box>
          <Flex mt="3" alignItems="center" flexDirection="row">
            <Text mr="2">Tag:</Text>
            {dataTags.map(tagList => (
              <Tooltip
                key={tagList.id}
                label={tagList.description}
                bg="gray.650"
              >
                <Tag colorScheme="orange" size="md" mr="1">
                  <SimpleGrid columns={{ base: 1 }} gap={{ base: '1' }}>
                    <Flex p="1" alignItems="center">
                      <Text>{tagList.tag}</Text>
                      <Button
                        colorScheme="orange"
                        size="xs"
                        onClick={() => handleDelete(tagList.id)}
                        ml="2"
                      >
                        <FiX />
                      </Button>
                    </Flex>
                  </SimpleGrid>
                </Tag>
              </Tooltip>
            ))}
            <Tooltip bg="gray.650" label="Adicionar nova tag">
              <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                closeOnBlur={false}
              >
                <PopoverTrigger>
                  <Button>
                    <FiPlus />
                  </Button>
                </PopoverTrigger>
                <PopoverContent p={5} bg="gray.900">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    <Text>Nova Tag</Text>
                    <Input name="tag" onChange={handleOnChange} />
                    <PopoverFooter display="flex" justifyContent="flex-end">
                      <ButtonGroup size="sm">
                        <Button onClick={onClose}>Cancelar</Button>
                        <Button
                          colorScheme="blue"
                          onClick={handleSubmitTag}
                          isLoading={loadingTag}
                          isDisabled={loadingTag}
                        >
                          Adicionar
                        </Button>
                      </ButtonGroup>
                    </PopoverFooter>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Tooltip>
          </Flex>
        </Box>
      </Collapse>
    </>
  );
}

// import { Tooltip } from '@chakra-ui/react';
import { NotificationAppointments } from '../Notification';

export function NotificationNav() {
  return (
    // <Tooltip hasArrow bg="gray.650" label="Notificações">
    <NotificationAppointments />
    // </Tooltip>
  );
}

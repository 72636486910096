import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Box,
  Text,
  Tag,
  Avatar,
} from '@chakra-ui/react';

interface Props {
  title: string;
  body: string;
  userName: string;
}

export function RevisionsHistoryItens({ body, title, userName }: Props) {
  return (
    <Accordion allowToggle>
      <AccordionItem border="none">
        <Box marginBottom="8px">
          <Box alignItems="center" display="flex">
            <Box
              backgroundColor="gray.500"
              borderRadius="9999px"
              h="1.5rem"
              w="1.5rem"
            />

            <Box flex="1" margin="0.5rem">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text>{title}</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
            </Box>
          </Box>

          <Box marginLeft="2rem">
            <AccordionPanel pb={4}>
              <Tag colorScheme="gray">
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Avatar size="xs" name={userName} src="" mr="1" />
                  <Text fontSize="xs">{userName}</Text>
                </Box>
              </Tag>
              {body}
            </AccordionPanel>
          </Box>
        </Box>
      </AccordionItem>
    </Accordion>
  );
}

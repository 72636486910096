/* eslint-disable prettier/prettier */
import {
  Flex,
  Text,
  Collapse,
  Spinner,
  Tag,
  Avatar,
  TagLabel,
  Button,
  Icon,
  Box,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { FiTrash2 } from 'react-icons/fi';

interface Props {
  title: string;
  show: boolean;
  loadingParticipants: boolean;
  loadDeleteParticipants: boolean;
  verifyQuantityParticipants: boolean;
  verifyExistiParticipants: boolean;
  participants: Participants[];
  handleDeleteParticipants: (id: string) => void;
  handleToggle: () => void;
  children: ReactElement;
}
interface Participants {
  id: string;
  participant: {
    name: string;
    id: string;
  };
}

export function Involveds({
  title,
  show,
  loadingParticipants,
  participants,
  handleDeleteParticipants,
  loadDeleteParticipants,
  verifyQuantityParticipants,
  verifyExistiParticipants,
  handleToggle,
  children,
}: Props) {

  return (
    <Flex flexDirection="column">
      <Text mb="2">{title}</Text>
      <Box mb="2">
        <Collapse startingHeight={!verifyExistiParticipants ? 0 : 40} in={show}>
          {loadingParticipants ? (
            <Spinner />
          ) : (
            <>
              {participants.length > 0 && (
                <>
                  {console.log('participants', participants)}
                  {participants.map(participant => (
                    <Tag
                      size="md"
                      variant="subtle"
                      bgGradient="linear(to-r, teal.600, green.400)"
                      borderRadius="full"
                      key={participant.id}
                      m="1"
                    >
                      <Avatar
                  // src="https://bit.ly/sage-adebayo"
                        size="xs"
                        name={participant.participant.name}
                        ml={-1}
                        mr={2}
                      />
                      <TagLabel
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        w="20"
                      >
                        {participant.participant.name}
                      </TagLabel>
                      <Button
                        onClick={() =>
                    handleDeleteParticipants(participant.participant.id)}
                        variant="ghost"
                        p="0"
                        m="0"
                        size="sm"
                        disabled={loadDeleteParticipants}
                      >
                        <Icon boxSize="3" as={FiTrash2} />
                      </Button>
                    </Tag>
            ))}
                </>
            )}

            </>
          )}
        </Collapse>
        <Flex alignItems="center" flexDirection="row" mt="0.5rem">
          {verifyQuantityParticipants && (
            <Button size="sm" onClick={handleToggle} mr="0.5rem">
              Mostrar {show ? 'Menos' : 'Mais'}
            </Button>
          )}
          {children}
        </Flex>
      </Box>
    </Flex>
  );
}

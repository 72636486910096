import React from 'react';

// import Header from '../../../components/Header';
// import Sidebard from '../../../components/Sidebar';

import { Container } from './styles';

const SelectorFolders: React.FC = () => {
  return (
    <Container>
      {/* <Header>Seletor de tarefas</Header>
      <Sidebard /> */}
      <h1>E-mail</h1>
    </Container>
  );
};

export default SelectorFolders;

/* eslint-disable import/extensions */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';

import { Page, Text, View, Document } from '@react-pdf/renderer';
import { api } from '../../../services/api';
import { Logo2, Radio } from './Logo';

export function BodyFile({ user }) {
  const [dataEvaluation, setDataEvaluation] = useState([]);
  const [userViewPerformanceEvaluation, setUserViewPerformanceEvaluation] =
    useState(false);

  useEffect(() => {
    try {
      api
        .get(
          `/hierarchies/filter?take=999&page=1&leader=${user.nickname}&year=2023`,
        )
        .then(response => {
          if (response.data.hierarchies.length > 0) {
            setUserViewPerformanceEvaluation(true);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  }, [user]);

  useEffect(() => {
    api.get('/factors').then(response => {
      const dataFiltred = response.data.filter(data => {
        if (userViewPerformanceEvaluation) {
          return data.name;
        }
        return data.name !== 'Liderança';
      });
      setDataEvaluation(dataFiltred);
    });
  }, [userViewPerformanceEvaluation]);

  return (
    <>
      <Document>
        <Page
          size="A4"
          style={{
            flexDirection: 'column',
            padding: '15px',
          }}
        >
          <Logo2 />
          <Text
            style={{
              fontSize: 14,
              textAlign: 'center',
              marginTop: '7px',
            }}
          >
            Avaliação de Desempenho 2023
          </Text>
          <Text
            style={{
              fontSize: 12,
              textAlign: 'center',
              marginTop: '7px',
            }}
          >
            {user.name}
          </Text>
          {dataEvaluation?.map(data => (
            <>
              <View>
                <Text
                  style={{
                    fontSize: 12,
                    marginTop: '15px',
                  }}
                >
                  {data?.name}
                </Text>
                <Text
                  style={{
                    color: '#6c757d',
                    fontSize: 10,
                    marginTop: '6px',
                  }}
                >
                  {data.description}
                </Text>
              </View>
              <View
                style={
                  {
                    // marginTop: 5,
                  }
                }
              >
                {data.performances?.map(dataPerformance => (
                  <View
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Radio />
                      <Text
                        style={{
                          fontSize: 9,
                          margin: 3,
                        }}
                      >
                        {dataPerformance.name}
                      </Text>
                    </View>
                  </View>
                ))}
                <Text
                  style={{
                    fontSize: 10,
                    margin: 5,
                    marginBottom: 20,
                  }}
                >
                  Observação:
                </Text>
              </View>
            </>
          ))}
        </Page>
      </Document>
    </>
  );
}

/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import {
  Menu,
  MenuButton as MenuButtonChakra,
  Button,
  MenuList,
} from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';

interface Props {
  children: React.ReactNode;
  label: string;
}

export function MenuButton({ children, label }: Props) {
  // const userCanSeeProcessManager = useCan({
  //   roles: ['master_user', 'process_manager'],
  // });

  return (
    <>
      <Menu>
        <MenuButtonChakra
          id={label}
          as={Button}
          transition="all 0.2s"
          borderRadius="md"
          borderWidth="1px"
          bg="gray.900"
          borderColor="gray.800"
          _hover={{ bg: 'gray.650' }}
          _expanded={{ bg: 'blue.400' }}
          _focus={{ boxShadow: 'outline' }}
          rightIcon={<BiChevronDown />}
          fontWeight="500"
          textAlign="initial"
          w="100%"
          m="0"
        >
          {label}
        </MenuButtonChakra>
        <MenuList bg="gray.800">{children}</MenuList>
        {/* </NavLink> */}
        {/* {userCanSeeProcessManager && (
        <>
          <Menu>
            <MenuButton
              as={Button}
              transition="all 0.2s"
              borderRadius="md"
              borderWidth="1px"
              bg="gray.900"
              borderColor="gray.800"
              _hover={{ bg: 'gray.650' }}
              _expanded={{ bg: 'blue.400' }}
              _focus={{ boxShadow: 'outline' }}
              rightIcon={<BiChevronDown />}
              fontWeight="500"
              textAlign="initial"
            >
            Map. de Processos
            </MenuButton>
            <MenuList bg="gray.800">
            <MenuItem
                _hover={{ bg: 'gray.650' }}
                as={Link}
                to="/process-management"
              >
                Gerenciar mapeamento
                </MenuItem>
              {/* <MenuItem>Create a Copy</MenuItem>
              <MenuItem>Mark as Draft</MenuItem>
          <MenuItem>Delete</MenuItem>
          <MenuItem>Attend a Workshop</MenuItem> */}
        {/* </MenuList>
          </Menu>
        </>
      )} */}
      </Menu>
    </>
  );
}

import styled from 'styled-components';

interface Props {
  theme: string;
}

export const Container = styled.div`
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 2rem;

  video {
    border-radius: 6px;
  }
`;

export const Nic = styled.article<Props>`
  max-width: 920px;
  margin: 6rem auto 0;
  h1 {
    font-size: 2.5rem;
    font-weight: 900;
    ${({ theme }: Props): string =>
      theme === 'light'
        ? 'color: var(--text-quarterly);'
        : 'color: var(--text-secondary)'};
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 900;
    ${({ theme }: Props): string =>
      theme === 'light'
        ? 'color: var(--text-quarterly);'
        : 'color: var(--text-secondary)'};
  }
  time {
    display: block;
    font-size: 1rem;
    color: var(--dark-tertiary);
    margin-top: 1.5rem;
  }

  .se-wrapper-inner {
    background: #f8f8fb;
    z-index: 0;
  }
  /* textarea {
    z-index: 0;
  } */
  /*
  figure {
    img {
      transition: transform 0.5s;

      margin: 0 auto;
    }
    img:hover {
      z-index: 999999;
      transform: scale(1.5);

      zoom: 150%;
    }
  } */
  .nicContent {
    line-height: 2rem;
    /* color: var(--text-title); */
    font-size: 1.125rem;
    ${({ theme }: Props): string => {
      return theme === 'light'
        ? 'color: var(--text-title);'
        : 'color: var(--text-primary)';
    }};
  }
  p,
  ul {
    margin: 1.5rem 0;
  }
  ul {
    padding-left: 1.5rem;
    li {
      margin: 0.5rem 0;
    }
  }
  /* img {
    width: 800px;
  } */

  /* .block-img {
    .hover {
      position: absolute;
      height: 1000px;
      width: 1000px;
    }
  } */

  p {
    img {
      border-radius: 6px;
      width: 800px;
      .hover {
        height: 1000px;
        width: 1000px;
      }
    }
  }
`;

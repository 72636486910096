import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, ModalBody, ModalFooter, Flex } from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Input from '../../../Global/Input';
import { ModalComponent } from '..';
import TextArea from '../../../Global/TextArea';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';
import Select from '../../../Global/SelectRelease';
import getValidationErrors from '../../../../utils/getValidationErrors';

interface Props {
  isOpen: boolean;
  addedNewData: string;
  onClose: (isOpen: boolean) => void;
  setAddedNewData: (newData: any) => void;
}

interface PlanementsFormated {
  value: string;
  label: string;
}
interface Planements {
  id: string;
  name: string;
}

export default function ModalAddNewStrategicObjective({
  setAddedNewData,
  addedNewData,
  onClose,
  isOpen,
}: Props) {
  const formRef = useRef<FormHandles>(null);
  const [dataPLacements, setDataPlacements] = useState<PlanementsFormated[]>(
    [],
  );

  useEffect(() => {
    try {
      api.get<Planements[]>('/strategic-placements').then(response => {
        if (response.data) {
          const placementsFormated = response.data.map(placement => {
            return {
              label: placement.name,
              value: placement.id,
            };
          });
          setDataPlacements(placementsFormated);
        }
      });
    } catch (e) {
      console.log('error', e);
    }
  }, [addedNewData]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api.post('/strategic-objectives', data).then(response => {
          setAddedNewData(response.data);
          apllyToast(
            'success',
            'Sucesso ao adicionar novo Objetivo estratégico!',
          );
          onClose(!isOpen);
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        console.log(err);
        apllyToast('error', 'Problemas ao adicionar novo Objetivo estratégico');
      }
    },
    [isOpen, onClose, setAddedNewData],
  );

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        onClose={() => onClose(!isOpen)}
        scrollBehavior="inside"
        // sizeSpecifyc="2xl"
        title="Adicionar novo objetivo estratégico!"
        sizeSpecifyc="2xl"
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Flex flexDirection="column" mt="4">
              <p>Nome</p>
              <Input name="name" />

              <Select
                label="Posicionamento estratégico"
                options={dataPLacements}
                name="strategic_placement_id"
              />

              <p>Fonte de Dados e Fórmula</p>
              <TextArea name="observation" />
            </Flex>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => onClose(!isOpen)}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                Salvar
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

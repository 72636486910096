import { useAuth } from './auth';

interface UseCanParams {
  permissions?: string[];
  roles?: string[];
}

export function useCan({ permissions, roles }: UseCanParams) {
  const { user } = useAuth();

  if (user === undefined) {
    return false;
  }

  if (permissions) {
    if (permissions[0] === 'access_full') {
      return true;
    }

    if (permissions?.length > 0) {
      const hasAllPermissions = permissions?.every(permission => {
        return user.permissions.includes(permission);
      });

      if (!hasAllPermissions) {
        return false;
      }
    }
  }

  if (roles) {
    if (roles?.length > 0) {
      const hasAllroles = roles?.some(role => {
        return user.roles.includes(role);
      });

      if (!hasAllroles) {
        return false;
      }
    }
  }

  return true;
}

import { useContext } from 'react';
import { BiGasPump } from 'react-icons/bi';
import { FiList, FiCalendar, FiCompass } from 'react-icons/fi';
import { GiAutoRepair } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { SetToggleThemeContext } from '../../../contexts/SetToggleThemeContext';
import { OptionList } from './styles';

interface Props {
  pathname: string;
}

export function ManagementMiles({ pathname }: Props) {
  const { toggleTheme } = useContext(SetToggleThemeContext);

  return (
    <>
      <OptionList
        theme={toggleTheme}
        pathname={pathname}
        path="/menu"
        className="nav-item"
        visible
      >
        <Link to="/menu" className="nav-link">
          <FiList color="#3182ce" size={16} />

          <span className="link-text">Menu inicial</span>
        </Link>
      </OptionList>
      <OptionList
        pathname={pathname}
        path="/your-miles/schedule"
        className="nav-item"
        visible
      >
        <Link to="/your-miles/schedule" className="nav-link">
          <FiCalendar color="#3182ce" size={16} />

          <span className="link-text">Agenda</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/your-miles/miles"
        className="nav-item"
        visible
      >
        <Link to="/your-miles/miles" className="nav-link">
          <FiCompass color="#3182ce" size={16} />

          <span className="link-text">Quilometragem</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/your-miles/supply"
        className="nav-item"
        visible
      >
        <Link to="/your-miles/supply" className="nav-link">
          <BiGasPump color="#3182ce" size={16} />

          <span className="link-text">Abastecimento</span>
        </Link>
      </OptionList>

      <OptionList
        pathname={pathname}
        path="/your-miles/maintenance"
        className="nav-item"
        visible
      >
        <Link to="/your-miles/maintenance" className="nav-link">
          <GiAutoRepair color="#3182ce" size={16} />

          <span className="link-text">Manutenção</span>
        </Link>
      </OptionList>
    </>
  );
}

import React from 'react';
import { useCan } from '../../../hooks/useCan';

interface Props {
  children: React.ReactNode;
  permissions?: string[];
  roles?: string[];
}

export function Can({ children, permissions, roles }: Props) {
  const useCanSeeComponent = useCan({ permissions, roles });
  if (!useCanSeeComponent) {
    return null;
  }
  return <>{children}</>;
}

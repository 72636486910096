/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef, useState } from 'react';
import {
  ModalBody,
  Box,
  Avatar,
  AvatarBadge,
  Center,
  HStack,
  Stack,
  Flex,
  Text,
  Image,
  Tag,
  Spinner,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiSearch, FiPrinter, FiX } from 'react-icons/fi';

import Input from '../../../Global/Input';
import { ModalComponent } from './config';

import { api } from '../../../../services/api';

import logoatual from '../../../../assets/logoatual.png';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface AccessesData {
  id: string;
  name: string;
  ramal: string;
  email: string;
  phone: string;
  image: string;
  status: boolean;
  access_of_sector: {
    name: string;
    codccu: string;
    cod_sector: number;
    cod_section: string;
  };
}

interface Sectors {
  name: string;
  id: string;
  status: boolean;
}

export function ModalFilterSchedule({ isOpen, onClose }: Props) {
  const formRef = useRef<FormHandles>(null);
  const [dataSchedule, setDataSchedule] = useState<AccessesData[]>([]);
  // const [dataFiltred, setDataFiltred] = useState<AccessesData[]>([]);
  const [sectors, setSectors] = useState<Sectors[]>([]);
  const [loading, setLoading] = useState(false);
  const [sectorSelected, setSectorSelected] = useState('');

  useEffect(() => {
    setSectorSelected('');
  }, [isOpen]);

  useEffect(() => {
    try {
      setLoading(true);
      if (isOpen) {
        api.get('/accesses?status=true').then(response => {
          setDataSchedule(response.data);
          setLoading(false);
        });
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }, [isOpen]);

  function handleSearchUser(e: { target: { value: string } }) {
    setLoading(true);
    const data = dataSchedule.filter(
      user =>
        user.name.substring(0, e.target.value.length).toLowerCase() ===
        e.target.value.toLowerCase(),
    );
    setLoading(false);
    if (e.target.value.length === 0) {
      try {
        setLoading(true);
        if (isOpen) {
          api.get('/accesses?status=true').then(response => {
            setDataSchedule(response.data);
            setLoading(false);
          });
        }
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      }
    }
    setDataSchedule(data);
  }

  useEffect(() => {
    try {
      api.get<Sectors[]>('/sectors').then((res) => {

        setSectors(res.data.filter(dataSector => dataSector.status))

      });
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  function handleSubmiteSector(sector: string) {
    try {
      setLoading(true);
      if (isOpen) {
        if (sectorSelected === sector) {
          api.get('/accesses?status=true').then(response => {
            setDataSchedule(response.data);
            setSectorSelected('');
            setLoading(false);
          });

        } else {
          api.get(`/accesses/show?sector_id=${sector}`).then(response => {
            setDataSchedule(response.data);
            setSectorSelected(sector);
            setLoading(false);
          });
        }
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }

  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={onClose}>
        <ModalBody>
          <Flex flexDirection="column" alignItems="end">
            <Form ref={formRef} onSubmit={() => {}}>
              <Box display="flex" alignItems="center" flexDirection="row">
                <Input
                  icon={FiSearch}
                  variant="unstyled"
                  type="text"
                  name="date"
                  placeholder="Ex: Fagner"
                  mt="6px"
                  mb="12px"
                  onChange={handleSearchUser}
                />
                <Tooltip bg="gray.650" label="Fechar">
                  <Box
                    as="a"
                    target="_blank"
                    cursor="pointer"
                    onClick={onClose}
                  >
                    <Icon
                      _hover={{ color: 'red' }}
                      as={FiX}
                      color="gray.300"
                      boxSize="5"
                      transition="all 0.3s"
                    />
                  </Box>
                </Tooltip>
              </Box>

              <Box>
                {sectors.map(sector => (
                      <Tag
                        key={sector.id}
                        colorScheme={
                          sectorSelected === sector.id ? 'yellow' : 'blue'
                        }
                        shadow={
                          sectorSelected === sector.id
                            ? '0px 5px 8px rgba(0, 0, 0, 0.15)'
                            : 'none'
                        }
                        size="sm"
                        m="0.5"
                        onClick={() => handleSubmiteSector(sector.id)}
                        cursor="pointer"
                      >
                        {sector.name}
                      </Tag>
                ))}
              </Box>
            </Form>

            <Tooltip bg="gray.650" label="Imprimir agenda - 2023">
              <Box
                as="a"
                target="_blank"
                href="https://api-samasc.s3.sa-east-1.amazonaws.com/processes/file/ade6fd7926926fb53fbd@Agenda%202023.pdf"
              >
                <Icon
                  _hover={{ color: 'gray' }}
                  as={FiPrinter}
                  color="gray.300"
                  boxSize="5"
                  transition="all 0.3s"
                />
              </Box>
            </Tooltip>
          </Flex>

          <Center maxW="sm" mx="auto" py={{ base: '4', md: '8' }}>
            <Box bg="bg-surface" py="4">
              <Stack>
                {loading ? (
                  <Center>
                    <Spinner />
                  </Center>
                ) : (
                  dataSchedule.map(member => (
                    <Stack
                      key={member.id}
                      fontSize="sm"
                      p="4"
                      spacing="4"
                      bg="rgba(214,216,217,1)"
                      borderRadius="6px"
                      minW="444px"
                    >
                      <Stack
                        direction="row"
                        justify="space-between"
                        spacing="4"
                      >
                        <HStack spacing="3">
                          <Avatar
                            src={member.image}
                            name={member.name}
                            boxSize="10"
                          >
                            <AvatarBadge
                              boxSize="4"
                              bg={member.status ? 'green.500' : 'tomato'}
                            />
                          </Avatar>
                          <Box>
                            <Text fontWeight="medium" color="emphasized">
                              {member.name}
                            </Text>
                            {/* <Text color="muted">{member.email}</Text> */}
                          </Box>
                        </HStack>
                        <Text color="muted">{member.email}</Text>
                      </Stack>
                      <Box display="flex" flexDirection="row">
                        <Box>
                          <Flex flexDirection="row" w="18rem">
                            <Text fontWeight="bold" pr="2">
                              Ramal:{' '}
                            </Text>{' '}
                            {member?.ramal ? member?.ramal : '0000'}
                          </Flex>

                          {member?.phone !== null ? (
                            <Flex flexDirection="row" w="18rem">
                              <Text fontWeight="bold" pr="2">
                                Celular:{' '}
                              </Text>{' '}
                              {member?.phone ? member?.phone : '0000'}
                            </Flex>
                          ) : (
                            ''
                          )}

                          <Flex flexDirection="row">
                            <Text fontWeight="bold" pr="2">
                              Setor:
                            </Text>{' '}
                            {member?.access_of_sector
                              ? member?.access_of_sector.name
                              : '0000'}
                          </Flex>

                          <Flex flexDirection="row">
                            <Text fontWeight="bold" pr="2">
                              Cód. Setor:
                            </Text>{' '}
                            {member?.access_of_sector
                              ? member?.access_of_sector.cod_sector
                              : '0000'}
                          </Flex>
                          <Flex flexDirection="row">
                            <Text fontWeight="bold" pr="2">
                              Cód. Seção:
                            </Text>{' '}
                            {member?.access_of_sector
                              ? member?.access_of_sector.cod_section
                              : '0000'}
                          </Flex>
                          <Flex flexDirection="row">
                            <Text fontWeight="bold" pr="2">
                              Cód. centro de custo:
                            </Text>{' '}
                            {member?.access_of_sector
                              ? member?.access_of_sector.codccu
                              : '0000'}
                          </Flex>
                        </Box>
                        <Center>
                          <Box w="100%" display="flex" alignItems="center">
                            <Image
                              // w="9rem"
                              m="0"
                              w="120px"
                              display="flex"
                              alignItems="center"
                              objectFit="cover"
                              src={logoatual}
                              alt="logo"
                            />
                          </Box>
                        </Center>
                      </Box>
                    </Stack>
                  ))
                )}
              </Stack>
            </Box>
          </Center>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

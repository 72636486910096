import React, { useCallback } from 'react';

import { Flex, Input, Icon, Box } from '@chakra-ui/react';
import { RiSearchLine } from 'react-icons/ri';

interface Props {
  handleSearchUser: (e: string) => void;
}

export function SearchBox({ handleSearchUser }: Props) {
  const handleChangeSearchUser = useCallback(
    event => {
      handleSearchUser(event.target.value);
    },
    [handleSearchUser],
  );

  return (
    <Box>
      <Flex
        as="label"
        flex="1"
        py="2"
        px="8"
        ml="6"
        p="4"
        // maxWidth={400}
        alignSelf="center"
        color="gray.200"
        position="relative"
        bg="gray.800"
        borderRadius="full"
      >
        <Input
          color="gray.50"
          variant="unstyled"
          px="4"
          // m="4"
          placeholder="Buscar usuário"
          _placeholder={{ color: 'gray.400' }}
          onChange={handleChangeSearchUser}
        />
        <Icon as={RiSearchLine} fontSize="20" />
      </Flex>
    </Box>
  );
}

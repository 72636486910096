import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';

export function Card(props: BoxProps) {
  return (
    <Box
      minH="36"
      bg="white"
      p="15px"
      m="5px"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      borderRadius="lg"
      {...props}
    />
  );
}

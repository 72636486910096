/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Text,
  Button as ButtonChakra,
  Tooltip,
  useDisclosure,
  ScaleFade,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Flex,
} from '@chakra-ui/react';

import { FiUserPlus, FiEdit2 } from 'react-icons/fi';
// import { SearchBox } from '../../../../components/_components_0.2/Search/SearchBox';
import { api } from '../../../../services/api';
import { ModalAddNewRoles } from '../../../../components/_components_0.2/Modal/ModalAddNewRoles';
import { ModalAddNewPermissions } from '../../../../components/_components_0.2/Modal/ModalAddNewPermissions';

import { apllyToast } from '../../../../components/Global/Toast2.0';

import { CardButton, Container, CardeHeader } from './styles';
import { HeaderUp } from '../../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../../components/_components_0.2/Sidebar_0.2';

interface IRolesType {
  id?: string;
  name: string;
  description: string;
  identification: string;
}

export function PermissionsAndRolesManager() {
  // const [idRoles, setIdRoles] = useState('');

  const [submitRoles, setSubmitRoles] = useState();
  const [submitPermissions, setSubmitPermission] = useState();

  const [dataRoles, setDataRoles] = useState<IRolesType[]>([]);
  const [dataPermissions, setDataPermissions] = useState<IRolesType[]>([]);
  const {
    onOpen: onOpenRoles,
    isOpen: isOpenRoles,
    onClose: onCloseRoles,
  } = useDisclosure();
  const { onOpen, isOpen, onClose } = useDisclosure();

  useEffect(() => {
    api.get('/roles').then(response => {
      setDataRoles(response.data);
    });
  }, [submitRoles]);

  useEffect(() => {
    api.get('/permissions').then(response => {
      setDataPermissions(response.data);
    });
  }, [submitPermissions]);

  const handleSubmitRoles = useCallback(data => {
    api.post('/roles', data).then(response => {
      setSubmitRoles(response.data);
      apllyToast('success', 'Sucesso ao adicionar nova função');
    });
  }, []);

  const handleSubmitPermissions = useCallback(data => {
    api.post('/permissions', data).then(response => {
      setSubmitPermission(response.data);
      apllyToast('success', 'Sucesso ao adicionar nova permissão');
    });
  }, []);

  const handleOpenModal = useCallback(
    (open: string) => {
      if (open === 'roles') {
        onOpenRoles();
        return;
      }
      onOpen();
    },
    [onOpen, onOpenRoles],
  );

  return (
    <>
      <ModalAddNewRoles
        handleSubmitRoles={handleSubmitRoles}
        isOpen={isOpenRoles}
        onClose={onCloseRoles}
      />
      <ModalAddNewPermissions
        handleSubmitPermissions={handleSubmitPermissions}
        isOpen={isOpen}
        onClose={onClose}
      />

      <HeaderUp path="adm" />

      <Flex w="100%" maxWidth={1480} mx="auto" pb={4} px="6">
        <Sidebar path="adm" />

        <ScaleFade initialScale={0.9} in>
          <Container>
            <CardeHeader>
              <div>
                <h2>Funções e Permissões</h2>
                <strong>Gererencie os acessos da aplicação</strong>
              </div>

              <CardButton>
                <Box alignItems="center" display="flex">
                  {/* <SearchBox /> */}
                </Box>
                <ButtonChakra
                  leftIcon={<FiUserPlus />}
                  onClick={() => handleOpenModal('roles')}
                  type="button"
                  colorScheme="blue"
                  color="white"
                  mr="2"
                >
                  Adicionar nova função
                </ButtonChakra>
                <ButtonChakra
                  leftIcon={<FiUserPlus />}
                  onClick={() => handleOpenModal('permission')}
                  type="button"
                  colorScheme="blue"
                  color="white"
                >
                  Adicionar nova permissão
                </ButtonChakra>
              </CardButton>
            </CardeHeader>

            <Tabs variant="enclosed">
              <TabList>
                <Tab>Funções</Tab>
                <Tab>Permissões</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {dataRoles.map(roles => (
                    <Box
                      key={roles.id}
                      bg="gray.800"
                      p="10px"
                      borderRadius="6px"
                      mb="10px"
                    >
                      {/* <CardGraphicText>
                  <GraphicTitle> */}
                      <Flex flexDirection="row" justifyContent="space-between">
                        <Text as="h2">{roles.name}</Text>

                        <Flex>
                          <Tooltip bg="gray.650" label="Editar">
                            <ButtonChakra
                              onClick={() =>
                                handleOpenModal(roles.id ? roles.id : '')
                              }
                              type="button"
                              colorScheme="green"
                              color="white"
                              mr="2"
                            >
                              <FiEdit2 />
                            </ButtonChakra>
                          </Tooltip>
                        </Flex>
                      </Flex>

                      <Flex
                        flexDirection="row"
                        // justifyContent="space-between"
                        alignItems="initial"
                        textAlign="left"
                        maxWidth="700"
                      >
                        <Flex flexDirection="column" minWidth="320">
                          <Flex flexDirection="row" alignItems="center">
                            <Text
                              fontWeight="medium"
                              color="gray.650"
                              mr="10px"
                            >
                              Função:
                            </Text>
                            <Text>{roles.name}</Text>
                          </Flex>
                          <Flex flexDirection="row" alignItems="center">
                            <Text
                              fontWeight="medium"
                              color="gray.650"
                              mr="10px"
                            >
                              identificação:
                            </Text>
                            <Text>{roles.identification}</Text>
                          </Flex>
                        </Flex>

                        <Box p="0.8" bg="#eee" ml="5" mr="5" />

                        <Flex flexDirection="column" alignItems="initial">
                          <Flex>
                            <Text
                              fontWeight="medium"
                              color="gray.650"
                              mr="10px"
                            >
                              Descrição:
                            </Text>
                            <Text> {roles.description}</Text>
                          </Flex>
                        </Flex>
                      </Flex>

                      {/* </GraphicTitle>
                </CardGraphicText> */}
                    </Box>
                  ))}
                </TabPanel>
                <TabPanel>
                  {dataPermissions.map(roles => (
                    <Box
                      key={roles.id}
                      bg="gray.800"
                      p="10px"
                      borderRadius="6px"
                      mb="10px"
                    >
                      {/* <CardGraphicText>
                  <GraphicTitle> */}
                      <Flex flexDirection="row" justifyContent="space-between">
                        <Text as="h2">{roles.name}</Text>

                        <Flex>
                          <Tooltip bg="gray.650" label="Editar">
                            <ButtonChakra
                              onClick={() =>
                                handleOpenModal(roles.id ? roles.id : '')
                              }
                              type="button"
                              colorScheme="green"
                              color="white"
                              mr="2"
                            >
                              <FiEdit2 />
                            </ButtonChakra>
                          </Tooltip>
                        </Flex>
                      </Flex>

                      <Flex
                        flexDirection="row"
                        // justifyContent="space-between"
                        alignItems="initial"
                        textAlign="left"
                        maxWidth="700"
                      >
                        <Flex flexDirection="column" minWidth="320">
                          <Flex flexDirection="row" alignItems="center">
                            <Text
                              fontWeight="medium"
                              color="gray.650"
                              mr="10px"
                            >
                              Função:
                            </Text>
                            <Text>{roles.name}</Text>
                          </Flex>
                          <Flex flexDirection="row" alignItems="center">
                            <Text
                              fontWeight="medium"
                              color="gray.650"
                              mr="10px"
                            >
                              identificação:
                            </Text>
                            <Text>{roles.identification}</Text>
                          </Flex>
                        </Flex>

                        <Box p="0.8" bg="#eee" ml="5" mr="5" />

                        <Flex flexDirection="column" alignItems="initial">
                          <Flex>
                            <Text
                              fontWeight="medium"
                              color="gray.650"
                              mr="10px"
                            >
                              Descrição:
                            </Text>
                            <Text> {roles.description}</Text>
                          </Flex>
                        </Flex>
                      </Flex>

                      {/* </GraphicTitle>
                </CardGraphicText> */}
                    </Box>
                  ))}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Container>
        </ScaleFade>
      </Flex>
    </>
  );
}

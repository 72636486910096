import { ScaleFade, Flex } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { ReportBIManagement } from '../../../../components/Admin/Reports/ReportBIManagement';
import { HeaderUp } from '../../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../../components/_components_0.2/Sidebar_0.2';

interface ParamsRouter {
  workspaceId: string;
  embededUrl: string;
  embededId: string;
  configId: string;
}

export default function PBIRenderization() {
  const { state } = useLocation<ParamsRouter>();

  return (
    <>
      <ScaleFade initialScale={0.9} in>
        <Flex direction="column" h="100vh">
          <HeaderUp path="bi" />
          <Sidebar path="bi" />
          <Flex
            my="6"
            maxWidth={1480}
            mx="auto"
            pb={4}
            px="6"
            textAlign="center"
          >
            <ReportBIManagement
              reportLoading={state.embededId}
              embedId={state.embededId}
              embedUrl={state.embededUrl}
              workspaceId={state.workspaceId}
              configId={state.configId}
            />
          </Flex>
        </Flex>
      </ScaleFade>
    </>
  );
}

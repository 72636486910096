/* eslint-disable no-constant-condition */
/* eslint-disable react/style-prop-object */
import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import {
  Box,
  Center,
  SimpleGrid,
  useBreakpointValue,
  Flex,
} from '@chakra-ui/react';

import { ContainerCard, Content } from './styles';

import ti from '../../../assets/ti.svg';
// import fiscal from '../../../assets/fiscal.svg';
import { SetToggleThemeContext } from '../../../contexts/SetToggleThemeContext';

const MenuList: React.FC = () => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  // const { user } = useAuth();
  const { toggleTheme } = useContext(SetToggleThemeContext);

  // const userCanSeeFileManager = useCan({
  //   permissions: ['file_manager'],
  // });

  return (
    <Flex alignItems="center" justifyContent="center" mt="12rem">
      <ContainerCard theme={toggleTheme}>
        <Center>
          <SimpleGrid
            flex="1"
            // columns={[2, null, 4]}
            // columns={{ sm: 2, md: 2 }}
            // spacing={5}
            // minChildWidth={['370px', '400', '420px']}
            alignItems="center"
            mt={isWideVersion ? '0' : '10rem'}
          >
            <Box m="5px">
              <Link to="/service-orders-ti/user">
                <Content>
                  <img src={ti} alt="imagem de ordem de serviço ti" />
                  <strong>Ordens de serviços TI</strong>
                </Content>
              </Link>
            </Box>

            {/* <Box m="5px">
              <Link to="/service-orders-fiscal/user">
                <Content>
                  <img
                    className="svg-fiscal"
                    src={fiscal}
                    alt="imagem de ordem de serviço fiscal"
                  />
                  <strong>Ordens de serviços Fiscal</strong>
                </Content>
              </Link>
            </Box> */}
          </SimpleGrid>
        </Center>
      </ContainerCard>
    </Flex>
  );
};

export default MenuList;

/* eslint-disable react/no-unescaped-entities */
import { useRef, useCallback, useEffect, useState } from 'react';
import { Collapse, Box, Text, Checkbox } from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Select from '../../../../../components/Global/SelectRelease';
import { api } from '../../../../../services/api';

interface Options {
  value: string;
  label: string;
}

interface Indicators {
  id: string;
  investment_name: string;
}

interface Props {
  isOpenFilter: boolean;
  setInvestment_id: (id: string) => void;
  setResponsible_id: (id: string) => void;
  set_sector_filter_id: (id: string) => void;
  investment_id: string;
  responsableData: Options[];
  setIndicatorData: (data: Options[]) => void;
  setDeletedsInvestiments: (check: boolean) => void;
  deletedsInvestments: boolean;
  indicatorData: Options[];
  responsible_id: string;
  setLane: (lane: string) => void;
  lane: string;
  sector_filter_id: string;
  category_name: string;
  setCategory_name: (id: string) => void;
  setYear: (year: string) => void;
  year: string;
  setPic_repeat: (repeat: boolean) => void;
  pic_repeat: boolean;
}

const dataOpt = [
  {
    value: 'Maquinas e equipamentos',
    label: 'Maquinas e equipamentos',
  },
  {
    value: 'Maquinas e equipamentos industriais',
    label: 'Maquinas e equipamentos industriais',
  },
  {
    value: 'Equip. de informatica',
    label: 'Equip. de informatica',
  },
  {
    value: 'Equip. de laboratório',
    label: 'Equip. de laboratório',
  },
  {
    value: 'Equip. de Proteção e segurança',
    label: 'Equip. de Proteção e segurança',
  },
  {
    value: 'Edif. e construções',
    label: 'Edif. e construções',
  },
  {
    value: 'Maquinas e equipamentos de informatica',
    label: 'Maquinas e equipamentos de informatica',
  },
  {
    value: 'Moveis e utensilio',
    label: 'Moveis e utensilio',
  },
  {
    value: 'Instalações',
    label: 'Instalações',
  },
  {
    value: 'Veiculos',
    label: 'Veiculos',
  },
  {
    value: 'Software',
    label: 'Software',
  },
  {
    value: 'Aumento de mão de obra',
    label: 'Aumento de mão de obra',
  },
];

const dataPrioryt = [
  {
    value: 'Aguardando análise',
    label: 'Aguardando análise',
  },
  {
    value: 'Atualizado pelo Analista',
    label: 'Atualizado pelo Analista',
  },
  {
    value: 'PIC inconsistente',
    label: 'PIC inconsistente',
  },
  {
    value: 'PIC Rejeitado',
    label: 'PIC Rejeitado',
  },
  {
    value: 'PIC incompleto',
    label: 'PIC incompleto',
  },
  {
    value: 'PIC Finalizado',
    label: 'PIC Finalizado',
  },
  {
    value: 'PIC Arquivado',
    label: 'PIC Arquivado',
  },
  {
    value: 'PIC não finalizado',
    label: 'PIC não finalizado',
  },
  {
    value: 'Preenchimento concluído',
    label: 'Preenchimento concluído',
  },
];

const yearData = [
  {
    value: '2024',
    label: '2024',
  },
  {
    value: '2023',
    label: '2023',
  },
];

export function FilterActions({
  isOpenFilter,
  indicatorData,
  setIndicatorData,
  investment_id,
  setInvestment_id,
  responsible_id,
  responsableData,
  set_sector_filter_id,
  setResponsible_id,
  setLane,
  lane,
  sector_filter_id,
  setDeletedsInvestiments,
  deletedsInvestments,
  category_name,
  setCategory_name,
  setYear,
  year,
  setPic_repeat,
  pic_repeat,
}: Props) {
  const formRef = useRef<FormHandles>(null);
  const [sectorData, setSectorData] = useState<Options[]>([
    {
      label: '',
      value: '',
    },
  ]);

  const handleReason = useCallback(
    (e: string) => {
      setInvestment_id(e);
    },
    [setInvestment_id],
  );
  const handleReasonResponsable = useCallback(
    (e: string) => {
      setResponsible_id(e);
    },
    [setResponsible_id],
  );
  const handleReasonLane = useCallback(
    (e: string) => {
      setLane(e);
    },
    [setLane],
  );
  const handleReasonCategory = useCallback(
    (e: string) => {
      setCategory_name(e);
    },
    [setCategory_name],
  );

  useEffect(() => {
    try {
      api.get<Indicators[]>('investments-plans').then(response => {
        const indicatorsFormated = response.data.map(idct => {
          return {
            label: idct.investment_name,
            value: idct.id,
          };
        });

        setIndicatorData(indicatorsFormated);
      });
    } catch (err) {
      console.log(err);
    }
  }, [setIndicatorData]);

  useEffect(() => {
    try {
      api.get('/sectors').then(response => {
        const sectorFormated = response.data.map(
          (sector: { name: string; id: string }) => {
            return {
              label: sector.name,
              value: sector.id,
            };
          },
        );

        setSectorData(sectorFormated);
      });
    } catch (err) {
      console.log(err);
    }
  }, [setSectorData]);

  return (
    <Collapse in={isOpenFilter} animateOpacity>
      <Box p="30px" mt="4" mb="4" bg="gray.900" rounded="md" shadow="md">
        <Text color="gray.500" mb="5">
          Filtro
        </Text>
        <Form ref={formRef} onSubmit={() => console.log('onSunmite')}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="left"
          >
            <Select
              name="lane"
              label="Status"
              value={lane}
              onChange={e => {
                handleReasonLane(e.target.value);
              }}
              options={dataPrioryt}
            />
            <Select
              name="investment_id"
              label="Investimento"
              value={investment_id}
              onChange={e => {
                handleReason(e.target.value);
              }}
              options={indicatorData}
            />
            <Select
              name="sector"
              label="Setor"
              value={sector_filter_id}
              onChange={e => {
                set_sector_filter_id(e.target.value);
              }}
              options={sectorData}
            />
            <Select
              name="responsable"
              label="Responsável"
              value={responsible_id}
              onChange={e => {
                handleReasonResponsable(e.target.value);
              }}
              options={responsableData}
            />
            <Select
              name="category"
              label="Conta Horizontal"
              value={category_name}
              onChange={e => {
                handleReasonCategory(e.target.value);
              }}
              options={dataOpt}
            />
            <Select
              name="year"
              label="Ano"
              value={year}
              onChange={e => {
                setYear(e.target.value);
              }}
              options={yearData}
            />

            <Box display="flex" alignItems="center" flexDir="row">
              <Checkbox
                mt="3"
                isChecked={!deletedsInvestments}
                onChange={() => setDeletedsInvestiments(!deletedsInvestments)}
                p="3"
              >
                Listar PIC arquivado.
              </Checkbox>
              <Checkbox
                mt="3"
                isChecked={pic_repeat}
                onChange={() => setPic_repeat(!pic_repeat)}
                p="3"
              >
                Listar Re'PIC.
              </Checkbox>
            </Box>
          </Box>
        </Form>
      </Box>
    </Collapse>
  );
}

import { ModalBody } from '@chakra-ui/react';

import { ModalComponent } from '..';

import { Signature } from './Signature';

interface Props {
  name: string;
  permissionNameEdit: boolean | undefined;
  filName: string;
  ramal: number;
  phone: string;
  dataComplementary: string;
  isOpen: boolean;
  handleCloseImageEmail: () => void;
}

export function ModalVisualizeEmailSignature({
  name,
  filName,
  phone,
  ramal,
  isOpen,
  dataComplementary,
  permissionNameEdit,
  handleCloseImageEmail,
}: Props) {
  // const [loading, setLoading] = useState(false);

  return (
    <>
      <ModalComponent
        title="Assinatura de email"
        isOpen={isOpen}
        onClose={() => handleCloseImageEmail()}
      >
        <ModalBody>
          <Signature
            filName={filName}
            name={name}
            ramal={ramal}
            phone={phone}
            handleCloseImageEmail={handleCloseImageEmail}
            dataComplementary={dataComplementary}
            permissionNameEdit={permissionNameEdit}
          />
        </ModalBody>
      </ModalComponent>
    </>
  );
}

import { Box, Stack } from '@chakra-ui/react';
import { MemberTable } from './ActionsTable';

export function TableForAction() {
  // const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Box
        bg="bg-surface"
        boxShadow={{ base: 'none', md: 'sm' }}
        borderRadius={{ base: 'none', md: 'lg' }}
        maxWidth="90%"
        pb="5"
        w="100%"
      >
        <Stack spacing="5">
          <MemberTable />
        </Stack>
      </Box>
    </>
  );
}

/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/media-has-caption */
import { useCallback, useRef, useState, useEffect, ChangeEvent } from 'react';

import {
  Button,
  ModalBody,
  ModalFooter,
  Text,
  RadioGroup,
  Radio,
  Box,
  FormLabel,
  Switch,
  Flex,
  Tooltip,
  Spinner,
  Kbd,
  Collapse,
  ScaleFade,
} from '@chakra-ui/react';
import UseAnimations from 'react-useanimations';
import download from 'react-useanimations/lib/download';
import { useReactMediaRecorder } from 'react-media-recorder';
import axios from 'axios';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useLoading, Oval } from '@agney/react-loading';
import { FiTrash2, FiFileText } from 'react-icons/fi';
// import { RiAttachmentLine } from 'react-icons/ri';

import Input from '../../../Global/Input';
import TextArea from '../../../Global/TextArea';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { ModalComponent } from '..';
import { api } from '../../../../services/api';
import { apllyToast } from '../../../Global/Toast2.0';
import { PublishProcedure } from './PublishProcedure';
import Upload from '../../../Global/Upload';

interface IProcess {
  id: string;
  name: string;
  description: string;
  category: string;
  sector_id?: string;
  contents?: string;
  publish?: boolean;
  visibility?: string;
}

interface IFileProcess {
  id: string;
  file_url: string;
  file: string;
}

interface IModalProps {
  handleEditProcess: (data: Omit<IProcess, ''>) => void;
  onClose: () => void;
  setOptVisibility: (visibility: string) => void;
  optVisibility: string;
  isOpen: boolean;
  process_id: string;
}

export function ModalEditProcess({
  handleEditProcess,
  onClose,
  isOpen,
  process_id,
  setOptVisibility,
  optVisibility,
}: IModalProps) {
  const formRef = useRef<FormHandles>(null);

  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ screen: true });
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [sistem, setSistem] = useState(false);
  const [file, setFile] = useState(false);
  const [publicFile, setPublicFile] = useState(false);
  const [update, setUpdate] = useState(false);

  const [value, setValue] = useState('');

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState<IProcess>();
  const [valueFiles, setValueFiles] = useState<IFileProcess[]>([]);

  // const [loadingFile, setLoadingFile] = useState(false);

  const [loadingDelete, setloadingDelete] = useState(false);

  const [updatevideo, setUpdateVideo] = useState();

  const [contentsData, setContentsData] = useState('');

  const [changeSunEditor, setHandleChangeSunEditor] = useState(false);
  const [changeAlertSunEditor, setAlertChangeSunEditor] = useState(false);
  const [updateNewRevisionHistory, setUpdateNewRevisionHistory] = useState('');

  useEffect(() => {
    try {
      if (process_id) {
        if (isOpen) {
          api
            .get<IProcess>(`/processes-mappings/show?id=${process_id}`)
            .then(response => {
              setInitialData(response.data);
              setPublicFile(!!response.data?.publish);
              setValue(response.data.category);
              if (response.data.visibility) {
                setOptVisibility(response.data.visibility);
              }

              // const token = localStorage.getItem('@Samasc:token');

              // const editorExtensionId = 'llgdoannfdjfelpomnmdpjfgjkfkhdpc';
              // chrome.runtime.sendMessage(editorExtensionId, {
              //   process_id,
              //   token,
              //   title: response.data.name,
              // });
            });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [process_id, isOpen, setOptVisibility]);

  useEffect(() => {
    try {
      if (process_id) {
        api
          .get(
            `/processes-mappings-of-files/show?process_mapping_id=${process_id}`,
          )
          .then(response => {
            setValueFiles(response.data);
          });
      }
    } catch (err) {
      console.log(err);
    }
  }, [process_id, updatevideo, update]);

  const handleSubmit = useCallback(
    async (data: IProcess) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        if (changeSunEditor) {
          setAlertChangeSunEditor(true);
          setLoading(false);
          return;
        }

        if (contentsData !== '' && updateNewRevisionHistory === '') {
          setAlertChangeSunEditor(true);
          setLoading(false);
          return;
        }

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          description: Yup.string().required('Descrição é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { description, name } = data;

        const formatData = {
          description,
          name,
          category: value,
          id: process_id,
          contents: contentsData,
          publish: publicFile,
          visibility: optVisibility,
        };

        handleEditProcess(formatData);
        onClose();

        setLoading(false);
        setContentsData('');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          setLoading(false);

          formRef.current?.setErrors(errors);

          return;
        }
        onClose();
      }
    },
    [
      changeSunEditor,
      contentsData,
      handleEditProcess,
      onClose,
      optVisibility,
      process_id,
      publicFile,
      value,
      updateNewRevisionHistory,
    ],
  );

  const { containerProps, indicatorEl } = useLoading({
    loading,
    indicator: <Oval />,
  });

  const handleSubmitVideo = useCallback(async () => {
    try {
      setLoadingVideo(true);
      if (mediaBlobUrl) {
        await axios.get(mediaBlobUrl).then(async responseData => {
          if (responseData.status === 200) {
            const response = await fetch(mediaBlobUrl).then(r => r.blob());

            // const filename = new Date().toISOString();

            const myFile = new File([response], `${process_id}.webm`, {
              type: 'video/webm',
            });

            const fd = new FormData();

            fd.append('file', myFile);

            await api
              .post(
                `/processes-mappings-of-files?process_mapping_id=${process_id}`,
                fd,
              )
              .then(res => {
                setUpdateVideo(res.data);
              });
            apllyToast('success', 'Sucesso ao adicionar captura de tela');
            clearBlobUrl();
          }
        });
      }
      setLoadingVideo(false);
    } catch (err) {
      console.log(err);
      apllyToast('error', 'Problemas ao adicionar captura de tela');
    }
  }, [clearBlobUrl, mediaBlobUrl, process_id]);

  useEffect(() => {
    if (!isOpen) {
      setInitialData({
        id: '',
        name: '',
        description: '',
        category: '',
      });

      setSistem(false);
    }
  }, [isOpen]);

  const handleAddNewFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      try {
        // setLoadingFile(true);
        if (e.target.files) {
          const fd = new FormData();
          fd.append('file', e.target.files[0]);
          await api
            .post(
              `/processes-mappings-of-files?process_mapping_id=${process_id}`,
              fd,
            )
            .then(response => {
              setUpdateVideo(response.data);
            });
          apllyToast('success', 'Sucesso ao adicionar arquivo');

          // setLoadingFile(false);
        }
      } catch (err) {
        console.log(err);
        // setLoadingFile(false);
      }
    },
    [process_id],
  );

  const handleDeleteFile = useCallback(async id => {
    try {
      if (id) {
        setloadingDelete(true);
        await api
          .delete(`/processes-mappings-of-files?id=${id}`)
          .then(response => {
            setUpdateVideo(response.data);

            apllyToast('success', 'Sucesso ao excluir arquivo');
            setloadingDelete(false);
          });
      }
    } catch (err) {
      console.log(err);
      apllyToast('error', 'Problemas ao excluir arquivo');
    }
  }, []);

  const handleOnClose = useCallback(() => {
    onClose();
    setPublicFile(false);
    setContentsData('');
    setOptVisibility('visibleToMe');
    setUpdateNewRevisionHistory('');
  }, [onClose, setOptVisibility]);

  const handleUpdate = () => {
    setUpdate(!update);
  };

  return (
    <>
      <ScaleFade initialScale={0.9} in>
        <ModalComponent
          title="Atualizar procedimento"
          isOpen={isOpen}
          onClose={handleOnClose}
          scrollBehavior="inside"
          fullscreen={publicFile}
        >
          <ModalBody>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={initialData}
            >
              <p>Nome</p>
              <Input
                type="text"
                name="name"
                placeholder="Ex: Fechamento de lote suicoper"
                mb="25px"
              />
              <Text mb="10px">Categoria</Text>
              <RadioGroup onChange={setValue} value={value}>
                {/* necessario */}
                <Radio colorScheme="green" name="verde" value="green" mr="10px">
                  Verde
                </Radio>
                {/* importante */}
                <Radio
                  colorScheme="yellow"
                  name="amarelo "
                  value="yellow"
                  mr="10px"
                >
                  Amarelo
                </Radio>
                {/* urgente */}
                <Radio colorScheme="red" name="vermelho" value="red" mb="25px">
                  Vermelho
                </Radio>
              </RadioGroup>

              <Flex flexDirection="row" mb="25px">
                <FormLabel htmlFor="email-alerts" mb="0">
                  Gravar tela?
                </FormLabel>
                <Switch
                  id="email-alerts"
                  onChange={() => setSistem(!sistem)}
                  isDisabled={process_id === ''}
                />
              </Flex>

              {/* {sistem && ( */}
              <Collapse in={sistem} animateOpacity>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  bg="gray.750"
                  p="5"
                  borderRadius="6px"
                  mb="10"
                >
                  <Tooltip label="Atualizar dados" color="gray.50">
                    <Button
                      color="transparent"
                      onClick={() => handleUpdate()}
                      size="xs"
                      bg="gray.750"
                      p="0"
                      right="-200"
                      top="-2"
                    >
                      <UseAnimations animation={download} size={25} />
                    </Button>
                  </Tooltip>
                  <Box mb="25px">
                    <Button
                      size="sm"
                      type="button"
                      onClick={startRecording}
                      isDisabled={status === 'recording' || loadingVideo}
                      colorScheme="green"
                    >
                      {status === 'stopped' ? (
                        <Text>Sobrepor gravação</Text>
                      ) : (
                        <Text>Capturar tela modo simples</Text>
                      )}
                    </Button>
                    <Button
                      type="button"
                      onClick={stopRecording}
                      colorScheme="red"
                      display={
                        status === 'idle' || status === 'stopped' ? 'none' : ''
                      }
                      ml="15px"
                    >
                      Parar gravação
                    </Button>
                  </Box>
                  {mediaBlobUrl && (
                    <>
                      <video
                        src={mediaBlobUrl}
                        controls
                        autoPlay={false}
                        loop
                      />

                      <Box>
                        <Button
                          onClick={() => clearBlobUrl()}
                          colorScheme="red"
                          mr="15px"
                          isDisabled={loadingVideo}
                        >
                          Cancelar gravação
                        </Button>

                        <Button
                          type="button"
                          onClick={() => handleSubmitVideo()}
                          colorScheme="blue"
                          mt="25px"
                          mb="25px"
                          isDisabled={status === 'idle'}
                          isLoading={loadingVideo}
                        >
                          Salvar gravação
                        </Button>
                      </Box>
                    </>
                  )}

                  {status === 'stopped' ? (
                    ''
                  ) : (
                    <Box
                      mb="2"
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Text color="gray">
                        Para acessar a captura avançada, execute:
                      </Text>
                      <span>
                        <Kbd>Ctrl</Kbd> + <Kbd>shift</Kbd> + <Kbd>y</Kbd>
                      </span>
                    </Box>
                  )}
                </Box>
              </Collapse>
              {/* )} */}

              <Flex flexDirection="row" mb="25px">
                <FormLabel htmlFor="file" mb="0">
                  Adicionar arquivo?
                </FormLabel>
                <Switch
                  id="file"
                  onChange={() => setFile(!file)}
                  isDisabled={process_id === ''}
                />
              </Flex>
              <Collapse in={file} animateOpacity>
                <Upload onUpload={handleAddNewFile} />
                {/* <Box
                  as="label"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  px="3"
                  py="1.5"
                  borderRadius="6"
                  bg="green.500"
                  cursor="pointer"
                  color="white"
                  fontWeight="bold"
                  htmlFor={process_id}
                  mb="25px"
                >
                  {loadingFile ? (
                    <Spinner size="sm" />
                  ) : (
                    <>
                      <Text>Adicionar arquivo</Text>
                      <Icon as={RiAttachmentLine} fontSize="20" color="white" />
                    </>
                  )}

                  <Box
                    as="input"
                    type="file"
                    id={process_id}
                    display="none"
                    onChange={handleAddNewFile}
                  />
                </Box> */}
              </Collapse>

              <PublishProcedure
                process_name={initialData?.name || 'process'}
                process_id={process_id}
                setPublicFile={setPublicFile}
                publicFile={publicFile}
                sector_id={initialData?.sector_id ? initialData.sector_id : ''}
                contents={initialData?.contents}
                setContentsData={setContentsData}
                changeAlertSunEditor={changeAlertSunEditor}
                setAlertChangeSunEditor={setAlertChangeSunEditor}
                setHandleChangeSunEditor={setHandleChangeSunEditor}
                setOptVisibility={setOptVisibility}
                optVisibility={optVisibility}
                setUpdateNewRevisionHistory={setUpdateNewRevisionHistory}
                updateNewRevisionHistory={updateNewRevisionHistory}
              />

              {valueFiles.length !== 0 ? (
                <>
                  <p>Anexos adicionados</p>

                  <Box mb="25px">
                    {valueFiles?.map(data => (
                      <>
                        {data.file.substr(-4) === 'webm' ? (
                          <>
                            <Flex
                              key={data.id}
                              alignItems="row"
                              w="100%"
                              mt="15px"
                              mb="15px"
                            >
                              <Box
                                as="video"
                                maxWidth="229px"
                                borderRadius="6px"
                                src={data.file_url}
                                controls
                                autoPlay={false}
                                loop
                              />
                              <Box
                                borderRadius="6px"
                                w="100%"
                                bg="red.100"
                                ml="4px"
                                display="flex"
                                alignItems="center"
                                flexDirection="row"
                                flexWrap="wrap"
                                justifyContent="center"
                              >
                                <Tooltip label="Excluir" bg="gray">
                                  <Button
                                    bg="transparent"
                                    color="white"
                                    _hover={{ color: 'red' }}
                                    onClick={() => handleDeleteFile(data.id)}
                                  >
                                    {loadingDelete ? (
                                      <Spinner size="sm" />
                                    ) : (
                                      <>
                                        <FiTrash2 size={30} />
                                      </>
                                    )}
                                  </Button>
                                </Tooltip>
                              </Box>
                            </Flex>
                          </>
                        ) : (
                          <Flex
                            key={data.id}
                            alignItems="row"
                            w="100%"
                            mt="15px"
                            mb="15px"
                          >
                            <Box
                              as="a"
                              target="_blank"
                              href={data?.file_url}
                              bg="gray"
                              minWidth="229px"
                              h="128px"
                              borderRadius="6px"
                              display="flex"
                              alignItems="center"
                              flexDirection="row"
                              flexWrap="wrap"
                              justifyContent="center"
                            >
                              <FiFileText size={30} />
                              <Text>{data?.id}</Text>
                            </Box>

                            <Box
                              borderRadius="6px"
                              w="100%"
                              bg="red.100"
                              ml="4px"
                              display="flex"
                              alignItems="center"
                              flexDirection="row"
                              flexWrap="wrap"
                              justifyContent="center"
                            >
                              <Tooltip label="Excluir" bg="gray">
                                <Button
                                  bg="transparent"
                                  color="white"
                                  _hover={{ color: 'red' }}
                                  onClick={() => handleDeleteFile(data.id)}
                                >
                                  {loadingDelete ? (
                                    <Spinner size="sm" />
                                  ) : (
                                    <>
                                      <FiTrash2 size={30} />
                                    </>
                                  )}
                                </Button>
                              </Tooltip>
                            </Box>
                          </Flex>
                        )}
                      </>
                    ))}
                  </Box>
                </>
              ) : (
                ''
              )}

              <p>Descrição</p>
              <TextArea
                name="description"
                placeholder="Ex: Processo referente ao fechamento dos lotes entregues para a aurora."
              />

              <ModalFooter>
                <Button variant="ghost" mr={3} onClick={handleOnClose}>
                  Fechar
                </Button>
                <Button colorScheme="blue" type="submit">
                  {loading ? (
                    <div {...containerProps} ref={componentRef}>
                      {indicatorEl}
                    </div>
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </ModalComponent>
      </ScaleFade>
    </>
  );
}

import { useCallback, useRef, useState, useEffect } from 'react';
import {
  Text,
  Button,
  Icon,
  useBreakpointValue,
  useDisclosure,
  Tooltip,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { RiAddLine } from 'react-icons/ri';
import { useLoading, Oval } from '@agney/react-loading';

import { MultiSelect } from 'react-multi-select-component';

import Select from '../../../Global/Select';
import TextArea from '../../../Global/TextArea';
import { ModalComponent } from '..';

import dataClimateResearch from '../../../../utils/climateSurveyQuestions.json';
import { apllyToast } from '../../../Global/Toast2.0';

interface ClimateResearchDescriptions {
  sequential: number;
  question: string;
  options: {
    label: string;
    value: string;
  };
}

interface DataClimateResearch {
  survey_id: string;
  result: string;
  question: number;
}
[];
interface IModalProps {
  handleAddNewClimateResearch: (data: DataClimateResearch[]) => void;
}

const Internationalization = {
  allItemsAreSelected: 'Todos os itens selecionados.',
  clearSearch: 'Limpar pesquisa',
  noOptions: 'Sem opções',
  search: 'Pesquisar',
  selectAll: 'Selecionar todos',
  selectSomeItems: 'Selecione...',
};

interface Option {
  value: string;
  label: string;
}

export function ModalAddNewClimateResearch({
  handleAddNewClimateResearch,
}: IModalProps) {
  const formRef = useRef<FormHandles>(null);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const componentRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const [uuidSurvey, setUuidSurvey] = useState('');
  const [dataSelected, setDataSelected] = useState<Option[]>([]);

  useEffect(() => {
    if (isOpen) {
      setUuidSurvey(uuidv4());
    }
  }, [isOpen]);

  function handleVerifyResult(result: string) {
    switch (result) {
      case '1': {
        return result;
      }
      case '2': {
        return result;
      }
      case '3': {
        return result;
      }
      case '4': {
        return result;
      }
      case '5': {
        return result;
      }
      default: {
        return '0';
      }
    }
  }

  const handleSubmit = useCallback(
    async (data: ClimateResearchDescriptions) => {
      try {
        setLoading(true);
        const asArray = Object.entries(data);
        const arrayFiltred = asArray.filter(as => as[1] === '');
        if (arrayFiltred.length > 0) {
          apllyToast('error', 'Não é permitido campo em branco!');
          setLoading(false);
          return;
        }

        const formated22 = asArray.map(arrayData => {
          return {
            question: Number(arrayData[0]),
            result: handleVerifyResult(arrayData[1]),
            survey_id: uuidSurvey,
            gender: asArray[0][1],
            cooperative_time: asArray[1][1],
            education: asArray[2][1],
            occupation_area: asArray[3][1],
            hierarchical_level: asArray[4][1],
            related_item: dataSelected,
            consideration: asArray[71][1],
          };
        });

        // const formated = asArray.map(arrayData => {
        //   return {
        //     question: Number(arrayData[0]),
        //     result: arrayData[1],
        //     survey_id: uuidSurvey,
        //   };
        // });
        handleAddNewClimateResearch(formated22);

        setDataSelected([]);

        onClose();

        setLoading(false);
      } catch (err) {
        console.log('error', err);
        onClose();
        apllyToast('error', 'Problemas ao finalizar pesquisa');
      }
    },
    [dataSelected, handleAddNewClimateResearch, onClose, uuidSurvey],
  );

  const { containerProps, indicatorEl } = useLoading({
    loading,
    indicator: <Oval />,
  });

  const handleOpenModal = useCallback(() => {
    onOpen();
  }, [onOpen]);

  return (
    <>
      <Tooltip bg="gray.650" hasArrow label="Nova pesquisa">
        <Button
          as="a"
          size="sm"
          colorScheme="blue"
          fontWeight="medium"
          onClick={handleOpenModal}
          cursor="pointer"
        >
          <Icon as={RiAddLine} fontSize="20" />
          {isWideVersion && <Text>Adicionar nova pesquisa</Text>}
        </Button>
      </Tooltip>
      <ModalComponent
        title={`Pesquisa- ${uuidSurvey}`}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalBody>
          <Form ref={formRef} onSubmit={handleSubmit}>
            {dataClimateResearch.map(search => (
              <>
                <Text mt="3">{search.question}</Text>
                {search.options.length === 15 && (
                  <MultiSelect
                    options={search.options}
                    value={dataSelected}
                    onChange={setDataSelected}
                    labelledBy="Selecione"
                    overrideStrings={Internationalization}
                  />
                )}
                {search.options.length === 0 && (
                  <TextArea name={`${search.sequential}`} />
                )}

                {search.options.length < 15 && search.options.length > 0 && (
                  <Select
                    // defaultValue={search.options[0]}
                    type="text"
                    name={`${search.sequential}`}
                    options={search.options}
                  />
                )}
              </>
            ))}

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Fechar
              </Button>
              <Button colorScheme="blue" type="submit">
                {loading ? (
                  <div {...containerProps} ref={componentRef}>
                    {indicatorEl}
                  </div>
                ) : (
                  'Salvar'
                )}
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </ModalComponent>
    </>
  );
}

/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Button as ButtonChakra,
  Tooltip,
  Tag,
  useDisclosure,
  ScaleFade,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import { FiUserPlus, FiKey, FiEdit2, FiPower } from 'react-icons/fi';
import { SearchBox } from '../../../../components/_components_0.2/Search/SearchBox';
import { api } from '../../../../services/api';
import ModalCreateUser from '../../../../components/_components_0.2/Modal/ModalCreateUser';
import { ModalEditUser } from '../../../../components/_components_0.2/Modal/ModalEditiUser';

import { apllyToast } from '../../../../components/Global/Toast2.0';

import { CardButton, CardeHeader } from './styles';
import { HeaderUp } from '../../../../components/_components_0.2/Header_0.2';
import { Sidebar } from '../../../../components/_components_0.2/Sidebar_0.2';

interface IUser {
  id?: string;
  name: string;
  nickname: string;
  password: string;
  tag: string;
  email: string;
  dashboard?: string;
  goals_and_sub_goals?: string;
  sector?: string;
  employers?: string;
  module_analyze?: string;
  imports?: string;
  report?: string;
  service_send_email?: string;
  schedule?: string;
  status: string;
  sector_id: string;
  senior_id: string;
}

interface IOptions {
  value: string;
  label: string;
}

interface IHandleUser {
  userData: IUser;
  infoSectors: IOptions[];
  infoModules: IOptions[];
}

const UserManagement: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [idUser, setIdUser] = useState('');
  const [dataAccess, setDataAccess] = useState<IUser[]>([]);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState('');

  const toggleModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);

  const [updateData, setupdateData] = useState('');
  const [dataFiltred, setDataFiltred] = useState<IUser[]>([]);

  useEffect(() => {
    api.get('/accesses').then(response => {
      setDataAccess(response.data);
    });
  }, [updateData]);

  const handleUser = useCallback((userInfo: Omit<IHandleUser, ''>) => {
    try {
      const { userData, infoModules, infoSectors } = userInfo;

      api
        .post('/accesses', {
          name: userData.name,
          email: userData.email,
          nickname: userData.nickname,
          password: userData.password,
          sector_id: userData.sector_id,
          tag: userData.tag,
          dashboard: Boolean(infoModules.find(el => el.value === 'dashboard')),
          goals_and_sub_goals: Boolean(
            infoModules.find(el => el.value === 'goals_and_sub_goals'),
          ),
          sector: Boolean(infoModules.find(el => el.value === 'sector')),
          employers: Boolean(infoModules.find(el => el.value === 'employers')),
          module_analyze: Boolean(
            infoModules.find(el => el.value === 'module_analyze'),
          ),
          imports: Boolean(infoModules.find(el => el.value === 'imports')),
          report: Boolean(infoModules.find(el => el.value === 'report')),
          service_send_email: Boolean(
            infoModules.find(el => el.value === 'service_send_email'),
          ),
          schedule: Boolean(infoModules.find(el => el.value === 'schedule')),
        })
        .then(res => {
          api
            .post('/accesses-of-sectors/create-all', {
              sectors: infoSectors,
              access_id: res.data.id,
            })
            .then(response => {
              console.log('resposta vinculo setor e usuario', response.data);
            });

          apllyToast('success', 'Sucesso ao criar novo usuario');
        });
    } catch (err) {
      console.log(err);
      apllyToast('error', 'Problemas ao criar novo usuario');
    }
  }, []);

  const handleOpenModal = useCallback(
    (id: string) => {
      onOpen();
      setIdUser(id);
    },
    [onOpen],
  );

  const handleSetStatus = useCallback((nick: string, status: boolean) => {
    try {
      setLoading(nick);
      api
        .put('/accesses', {
          nickname: nick,
          status,
        })
        .then(response => {
          setupdateData(response.data);
          apllyToast('success', 'Sucesso ao atualizar status');
          setLoading('');
        });
    } catch (err) {
      console.log(err);
      apllyToast('error', 'Problemas ao atualizar status');
      setLoading('');
    }
  }, []);

  const handleSearchUser = useCallback(
    e => {
      const data = dataAccess.filter(
        user =>
          user.name.substring(0, e.length).toLowerCase() === e.toLowerCase(),
      );

      setDataFiltred(data);
    },
    [dataAccess],
  );

  return (
    <>
      <ModalEditUser id={idUser} isOpen={isOpen} onClose={onClose} />
      <HeaderUp path="adm" />

      <Flex w="100%" maxWidth={1480} mx="auto" pb={4} px="6">
        <Sidebar path="adm" />
        {/* <Container> */}
        <Box mt="4rem" display="flex" flexDirection="column" w="100%">
          <ScaleFade initialScale={0.9} in>
            <CardeHeader>
              <div>
                <h2>Acessos</h2>
                <strong>Gererencie os acessos da aplicação</strong>
              </div>

              <CardButton>
                <Box alignItems="center" display="flex">
                  <SearchBox handleSearchUser={handleSearchUser} />
                </Box>
                <ButtonChakra
                  leftIcon={<FiUserPlus />}
                  onClick={toggleModal}
                  type="button"
                  colorScheme="blue"
                  color="white"
                >
                  Adicionar usuário
                </ButtonChakra>
              </CardButton>
            </CardeHeader>

            {dataFiltred.length !== 0
              ? dataFiltred.map(users => (
                  <Box
                    key={users.id}
                    bg="gray.800"
                    p="10px"
                    borderRadius="6px"
                    mb="10px"
                  >
                    {/* <CardGraphicText>
              <GraphicTitle> */}
                    <Flex flexDirection="row" justifyContent="space-between">
                      <Text as="h2">{users.name}</Text>

                      <Flex>
                        <Tooltip bg="gray.650" label="Editar">
                          <ButtonChakra
                            onClick={
                              () => handleOpenModal(users.id ? users.id : '')
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                            type="button"
                            colorScheme="green"
                            color="white"
                            mr="2"
                          >
                            <FiEdit2 />
                          </ButtonChakra>
                        </Tooltip>
                        <Tooltip
                          bg="gray.650"
                          label={
                            users.status ? 'Revogar acesso' : 'Habilitar acesso'
                          }
                        >
                          <ButtonChakra
                            onClick={() =>
                              handleSetStatus(users.nickname, !users.status)
                            }
                            type="button"
                            colorScheme={users.status ? 'red' : 'green'}
                            isLoading={loading === users.nickname}
                            color="white"
                            mr="2"
                          >
                            <FiPower />
                          </ButtonChakra>
                        </Tooltip>

                        <Tooltip bg="gray.650" label="Alterar senha">
                          <ButtonChakra
                            as={Link}
                            to="/administrator/set-data-user"
                            onClick={toggleModal}
                            type="button"
                            colorScheme="blue"
                            color="white"
                          >
                            <FiKey />
                          </ButtonChakra>
                        </Tooltip>
                      </Flex>
                    </Flex>

                    <Flex
                      flexDirection="row"
                      // justifyContent="space-between"
                      alignItems="initial"
                      textAlign="left"
                      maxWidth="700"
                    >
                      <Flex flexDirection="column" minWidth="320">
                        <Flex flexDirection="row" alignItems="center">
                          <Text fontWeight="medium" color="gray.650" mr="10px">
                            Usuário:
                          </Text>
                          <Text>{users.nickname}</Text>
                        </Flex>
                        <Flex flexDirection="row" alignItems="center">
                          <Text fontWeight="medium" color="gray.650" mr="10px">
                            E-mail:
                          </Text>
                          <Text>{users.email}</Text>
                        </Flex>
                      </Flex>

                      <Box p="0.8" bg="#eee" ml="5" mr="5" />

                      <Flex flexDirection="column" alignItems="initial">
                        <Flex>
                          <Text fontWeight="medium" color="gray.650" mr="10px">
                            Situação:
                          </Text>
                          {users.status ? (
                            <Tag size="sm" colorScheme="green">
                              Ativo
                            </Tag>
                          ) : (
                            <Tag size="sm" colorScheme="red">
                              Inativo
                            </Tag>
                          )}
                        </Flex>

                        <Flex>
                          <Text fontWeight="medium" color="gray.650" mr="10px">
                            senior_id:
                          </Text>
                          <Text>{users.senior_id}</Text>
                        </Flex>
                      </Flex>
                    </Flex>

                    {/* </GraphicTitle>
            </CardGraphicText> */}
                  </Box>
                ))
              : dataAccess.map(users => (
                  <Box
                    key={users.id}
                    bg="gray.800"
                    p="10px"
                    borderRadius="6px"
                    mb="10px"
                  >
                    {/* <CardGraphicText>
                <GraphicTitle> */}
                    <Flex flexDirection="row" justifyContent="space-between">
                      <Text as="h2">{users.name}</Text>

                      <Flex>
                        <Tooltip bg="gray.650" label="Editar">
                          <ButtonChakra
                            onClick={
                              () => handleOpenModal(users.id ? users.id : '')
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                            type="button"
                            colorScheme="green"
                            color="white"
                            mr="2"
                          >
                            <FiEdit2 />
                          </ButtonChakra>
                        </Tooltip>
                        <Tooltip
                          bg="gray.650"
                          label={
                            users.status ? 'Revogar acesso' : 'Habilitar acesso'
                          }
                        >
                          <ButtonChakra
                            onClick={() =>
                              handleSetStatus(users.nickname, !users.status)
                            }
                            type="button"
                            colorScheme={users.status ? 'red' : 'green'}
                            isLoading={loading === users.nickname}
                            color="white"
                            mr="2"
                          >
                            <FiPower />
                          </ButtonChakra>
                        </Tooltip>

                        <Tooltip bg="gray.650" label="Alterar senha">
                          <ButtonChakra
                            as={Link}
                            to="/administrator/set-data-user"
                            onClick={toggleModal}
                            type="button"
                            colorScheme="blue"
                            color="white"
                          >
                            <FiKey />
                          </ButtonChakra>
                        </Tooltip>
                      </Flex>
                    </Flex>

                    <Flex
                      flexDirection="row"
                      // justifyContent="space-between"
                      alignItems="initial"
                      textAlign="left"
                      maxWidth="700"
                    >
                      <Flex flexDirection="column" minWidth="320">
                        <Flex flexDirection="row" alignItems="center">
                          <Text fontWeight="medium" color="gray.650" mr="10px">
                            Usuário:
                          </Text>
                          <Text>{users.nickname}</Text>
                        </Flex>
                        <Flex flexDirection="row" alignItems="center">
                          <Text fontWeight="medium" color="gray.650" mr="10px">
                            E-mail:
                          </Text>
                          <Text>{users.email}</Text>
                        </Flex>
                      </Flex>

                      <Box p="0.8" bg="#eee" ml="5" mr="5" />

                      <Flex flexDirection="column" alignItems="initial">
                        <Flex>
                          <Text fontWeight="medium" color="gray.650" mr="10px">
                            Situação:
                          </Text>
                          {users.status ? (
                            <Tag size="sm" colorScheme="green">
                              Ativo
                            </Tag>
                          ) : (
                            <Tag size="sm" colorScheme="red">
                              Inativo
                            </Tag>
                          )}
                        </Flex>

                        <Flex>
                          <Text fontWeight="medium" color="gray.650" mr="10px">
                            Cód. cadastro sênior:
                          </Text>
                          <Text>{users.senior_id ? users.senior_id : ''}</Text>
                        </Flex>
                      </Flex>
                    </Flex>

                    {/* </GraphicTitle>
              </CardGraphicText> */}
                  </Box>
                ))}
            {/* </Container> */}
          </ScaleFade>
        </Box>
      </Flex>
      <ModalCreateUser
        isOpen={modalOpen}
        setIsOpen={toggleModal}
        handleUser={handleUser}
        // dataEditUser={dataEditUser}
      />
    </>
  );
};

export default UserManagement;

import { Button, Box } from '@chakra-ui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SetTitleContext } from '../../../contexts/SetTitleBIContext';

interface IDataPages {
  displayName: string;
  isActive: boolean;
  name: string;
  visibility: number;
}
[];

interface PropsPagination {
  dataPagesBi: IDataPages[];
  // handleTitleReport: (titleReport: string) => void;
  report: any;
}

export function PaginationBI({
  dataPagesBi,
  // handleTitleReport,
  report,
}: PropsPagination) {
  const { handleSetTitle } = useContext(SetTitleContext);
  const [viewsPagesReportBie, setViewPagesReportBIe] = useState<IDataPages[]>(
    [],
  );

  const handleSetPages = useCallback(
    async (page: string) => {
      report.setPage(page);

      const pages = await report.getPages();

      // await pages
      //   .filter((reportPage: { name: string }) => {
      //     return reportPage.name === page;
      //   })[0]
      //   .setActive();
      // // setUpdate(true);
      setViewPagesReportBIe(pages);

      const activePage = pages.filter((pagee: { isActive: any }) => {
        return pagee.isActive;
      })[0];

      handleSetTitle(activePage.displayName);
    },
    [handleSetTitle, report],
  );

  useEffect(() => {
    setViewPagesReportBIe(dataPagesBi);
  }, [dataPagesBi]);

  return (
    <Box position="relative">
      {viewsPagesReportBie?.map(pages => (
        <Button
          display={pages.visibility !== 0 ? 'none' : 'inline-block'}
          borderRadius="none"
          border="1px"
          colorScheme={pages.isActive ? 'facebook' : 'blue'}
          size="sm"
          fontWeight="light"
          key={pages.displayName}
          onClick={() => handleSetPages(pages.name)}
          disabled={pages.isActive}
        >
          {pages.displayName}
        </Button>
      ))}
    </Box>
  );
}

import React from 'react';
import { Stack } from '@chakra-ui/react';
import {
  RiMenu2Fill,
  RiDashboardLine,
  RiCalendarCheckLine,
} from 'react-icons/ri';

import { NavLink } from '../NavLink';
import { NavSection } from '../NavSection';
import { useCan } from '../../../../hooks/useCan';

export function SidebarNavOS() {
  const userCanSeeOrderServiceData = useCan({
    permissions: ['user_orderServices_data'],
  });

  return (
    <Stack spacing="12" align="flex-start">
      <NavSection title="GERAL">
        <NavLink to="/menu" icon={RiMenu2Fill}>
          Menu
        </NavLink>

        <NavLink to="/service-orders-ti/user" icon={RiDashboardLine}>
          Ordens de Serviços
        </NavLink>

        {userCanSeeOrderServiceData && (
          <NavLink to="/service-orders-ti/painel" icon={RiCalendarCheckLine}>
            Painel de serviços
          </NavLink>
        )}
      </NavSection>
    </Stack>
  );
}

/* eslint-disable react/require-default-props */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { useMemo } from 'react';

interface IpropsModal {
  onClose: any;
  isOpen: boolean;
  children: any;
  scrollBehavior?: 'inside' | 'outside';
  fullscreen?: boolean;
  closeOnOverlayClick?: boolean;
}

export function ModalComponent({
  children,
  isOpen,
  onClose,
  scrollBehavior,
  fullscreen,
  closeOnOverlayClick,
}: IpropsModal) {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const verifySizeWindowModal = useMemo(() => {
    if (fullscreen || !isWideVersion) {
      return 'full';
    }
    return 'lg';
  }, [fullscreen, isWideVersion]);

  return (
    <Box>
      <Modal
        blockScrollOnMount
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        size={verifySizeWindowModal}
        scrollBehavior={scrollBehavior || 'outside'}
        closeOnOverlayClick={closeOnOverlayClick}
      >
        <ModalOverlay />
        <ModalContent bg="gray.700">{children}</ModalContent>
      </Modal>
    </Box>
  );
}
